import React, {useEffect} from 'react';

import firebase from './Firebase';

import { IonApp, IonProgressBar, IonRouterOutlet } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/overrides.css';

import InitRoutes from './InitRoutes';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router';
import Confirmation from './pages/auth/Confirmation';
import { AuthStore, useAuthStore } from './store/AuthStore';
import Identity from './Identity';
import Home from './pages/auth/Home';
import SignupRegular from './pages/auth/SignupRegular';
import SignupMerchant from './pages/auth/SignupMerchant';
import Signin from './pages/auth/Signin';
import InviteWrapper from './pages/auth/InviteWrapper';
import NoMatch from './pages/auth/NoMatch';

import BusinessProfileActivity from './pages/common/BusinessProfileActivity';
import BusinessProfilePay from './pages/common/BusinessProfilePay';
import BusinessProfileDonate from './pages/common/BusinessProfileDonate';
import SignupBusiness from './pages/auth/SignupBusiness';


const App: React.FC = () => {
  const { user, loading } = useAuthStore((store) => store);

  useEffect(() => {
    async function authSubscription(resUser: any) {
      if(!!resUser && !resUser.isAnonymous) {
        const result:any = await firebase.auth().currentUser.getIdTokenResult(true);
        const newAuthStore = {
          user: {
            ...resUser,
            ...result
          },
          userType: '',
          loading: false
        };
        if(newAuthStore.user.claims.regular || newAuthStore.user.signInProvider === 'phone') {
          newAuthStore.userType = 'regular';
        }
        if(newAuthStore.user.claims.merchant) {
          newAuthStore.userType = 'merchant';
        }
      
        const userDataDoc:any = await firebase.firestore().collection('users').doc(newAuthStore.user.uid).get();
        newAuthStore.user = { ...newAuthStore.user, ...userDataDoc.data(), id: userDataDoc.id };
        
        AuthStore.set(newAuthStore);
      } else {
        AuthStore.set({
          loading: false
        });
      }
    }

    const subAuth = firebase.auth().onAuthStateChanged(authSubscription);

    return subAuth;
  }, []);

  if(loading) {
    return (
      <IonApp>
        <IonProgressBar type="indeterminate" />
      </IonApp>
    )
  }

  if(!!user) {
    return (
      <IonApp>
        <Identity>
          <InitRoutes />
        </Identity>
      </IonApp>
    )
  }

  return (
    <IonApp>
      <Identity>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/profile/:id" exact component={BusinessProfileActivity} />
            {/* <Route path="/profile/:id" exact component={BusinessProfileShop} /> */}
            <Route path="/profile/:id/pay" component={BusinessProfilePay} />
            <Route path="/profile/:id/donate" component={BusinessProfileDonate} />
            <Route path="/confirmation">
              <Confirmation />
            </Route>
            <Route exact path="/signupBusiness">
              <SignupBusiness />
            </Route>
            <Route exact path="/signup">
              <SignupMerchant />
            </Route>
            <Route path="/r" exact>
              <SignupRegular />
            </Route>
            <Route path="/r/:inviteId" exact>
              <InviteWrapper>
                <SignupRegular noRedirectSuccess={true} />
              </InviteWrapper>
            </Route>
            <Route path="/m">
              <SignupMerchant />
            </Route>
            <Route path="/login" exact>
              <Signin />
            </Route>
            <Route path="/login/:inviteId">
              <InviteWrapper>
                <Signin noRedirectSuccess={true} />
              </InviteWrapper>
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="*" component={NoMatch} />
          </IonRouterOutlet>
        </IonReactRouter>
      </Identity>
    </IonApp>
  );
};

export default App;
