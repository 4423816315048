import React, { useState } from 'react'
import { IonList, IonItem, IonLabel, IonPage, IonContent, IonToggle, IonInput, IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { useAuthStore } from '../../../store/AuthStore';
import MerchantHeader from '../../../components/MerchantHeader';
import UploadMenu from '../../../components/UploadMenu';
import isUndefined from 'lodash/isUndefined';
import SettingsModal from '../../../components/SettingsModal';
import BusinessAccountInfo from '../../../components/BusinessAccountInfo';
import NumberFormat from 'react-number-format';


interface Props {
  history: any;
}

const Settings: React.FC<Props> = () => {
  const { signOut, merchant } = useAuthStore((store) => store);
  const [toast, setToast] = useState({
    isOpen: false,
    color: '',
    message: ''

  })
  // const [loading, setLoading] = useState(false);
  // async function getSquareItems() {
  //   setLoading(true);
  //   const getItems = firebase.functions().httpsCallable('getItems');
  //   await getItems();
  //   setLoading(false);
  // }


  function toggleDonateOnly(value: any) {
    if (merchant && merchant.merchant_id && !isUndefined(value)) {
      firebase.firestore().collection('merchants').doc(merchant.merchant_id).update({
        only_donate: value
      })
    }
  }

  // function togglePassOnCreditCardFees() {
  //   if (merchant && merchant.merchant_id) {
  //     const currentValue = merchant.pass_credit_card_fees;
  //     firebase.firestore().collection('merchants').doc(merchant.merchant_id).update({
  //       pass_credit_card_fees: !currentValue
  //     })
  //   }
  // }

  function updateTaxPercent(value: Number) {
    if (merchant && merchant.merchant_id && !isUndefined(value)) {
      firebase.firestore().collection('merchants').doc(merchant.merchant_id).update({
        tax_rate: Number(value)
      })
    }
  }

  async function changeEmail(value: any) {
    try {
      await firebase.auth().currentUser.updateEmail(value);
      setToast({
        isOpen: true,
        message: 'Action completed',
        color: 'primary'
      })
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setToast({
          isOpen: true,
          message: 'Sign out and back in to complete this action. It requires a recent sign in.',
          color: 'danger'
        })
        return;
      }
      setToast({
        isOpen: true,
        message: error.message,
        color: 'danger'
      })
    }

    firebase.auth().currentUser.reload();
  }

  return (
    <IonPage>
      <IonToast
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({
          isOpen: false,
          message: '',
          color: ''
        })}
        message={toast.message}
        color={toast.color}
        duration={4000}
      />
      <MerchantHeader title="Settings" />
      <IonContent>

        <div style={{ maxWidth: 600, padding: '1rem 1rem 3rem', margin: 'auto' }}>
          <IonCard className="ion-no-margin" color="secondary">
            <IonCardHeader>
              <IonCardSubtitle>Business Phone Number:</IonCardSubtitle>
              <IonCardTitle><NumberFormat displayType="text" value={merchant.merchant_phone} format="+# (###) ###-####" mask="_" /></IonCardTitle>
            </IonCardHeader>
            <IonList className="ion-no-padding" lines="full">
              <SettingsModal
                itemColor="dark"
                itemName={`Email: ${firebase.auth().currentUser.email}`}
                label={`Email:`}
                initialValue={firebase.auth().currentUser.email}
                onDone={changeEmail}
                inputType="email"
              />
              {!isUndefined(merchant.only_donate) && <IonItem color="dark">
                <IonLabel>Only accept donations:</IonLabel>
                <IonToggle
                  checked={merchant.only_donate}
                  onIonChange={(e: any) => toggleDonateOnly(e.detail.checked)}
                />
              </IonItem>}
              {!isUndefined(merchant.tax_rate) && <IonItem color="dark">
                <IonLabel>Tax Rate (%)</IonLabel>
                <IonInput
                  className="ion-text-end"
                  type="number"
                  onIonChange={(e: any) => updateTaxPercent(e.target.value)}
                  value={merchant.tax_rate}
                  slot="end"
                />
                <IonLabel className="ion-no-margin" slot="end">%</IonLabel>
              </IonItem>}
              {!isUndefined(merchant.menu_url) && <UploadMenu merchant={merchant} />}
              <IonItem color="dark" detail button onClick={() => {
                signOut();
              }}>
                <IonLabel>
                  Signout
                </IonLabel>
              </IonItem>
            </IonList>
          </IonCard>
          <BusinessAccountInfo />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Settings;
