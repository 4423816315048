import React, {useState} from 'react'
import { IonPage, IonModal, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput } from '@ionic/react'
import MerchantHeader from '../../components/MerchantHeader'
import firebase from 'firebase/app';
import 'firebase/firestore';
import CatalogList from '../../components/CatalogList';
import { useAuthStore } from '../../store/AuthStore';
import { trashBin } from 'ionicons/icons';

interface Props {
  
}

const EditCatalogItems = (props: Props) => {
  const {merchant} = useAuthStore((store) => store);
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<any>({});

  function catalogItemSelected(item: any) {
    console.log(item);
  }

  function onItemDelete() {
    if(item && item.id) {
      firebase.firestore().collection('square-items').doc(merchant.merchant_id).collection('items').doc(item.id)
    }
  }
  
  return (
    <IonPage>
      <MerchantHeader title="Edit Catalog Items" />
      <CatalogList hideTitle={true} merchantId={merchant.merchant_id} onAction={catalogItemSelected} />
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButton
              slot="start"
              fill="clear"
              color="secondary"
              onClick={onItemDelete}
            >
              <IonIcon icon={trashBin} slot="start" />
              Delete
            </IonButton>
            <IonTitle>Edit Catalog Item</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput value={item.name} onIonChange={(e:any) => setItem((prevState: any) => ({...prevState, name: e.detail.value}))} />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput value={item.price} onIonChange={(e:any) => setItem((prevState: any) => ({...prevState, price: e.detail.value}))} />
            </IonItem>
          </IonList>
        </IonContent>

      </IonModal>
    </IonPage>
  )
}

export default EditCatalogItems
