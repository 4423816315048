import React from 'react';
import { IonPage, isPlatform } from '@ionic/react';
import ChatList from '../../../components/ChatList';
import { Redirect } from 'react-router';

interface Props {

}


const ChatIndexPage: React.FC<Props> = () => {
  if (isPlatform('mobile') && !isPlatform('tablet')) {
    return (
      <IonPage>
        <ChatList />
      </IonPage>
    )
  }

  return (
    <Redirect to={`/posts`} />
  )
}

export default ChatIndexPage;
