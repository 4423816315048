import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonTextarea, IonButton, IonIcon, IonAlert, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonNote } from '@ionic/react'
import MerchantHeader from '../../components/MerchantHeader'
import { send, checkmark } from 'ionicons/icons'
import { useAuthStore } from '../../store/AuthStore'
import firebase from 'firebase/app'
import 'firebase/firestore';
import { FormatData } from '../../components/FormatData'

interface Props {

}

const CreateCampaign: React.FC<Props> = () => {
  const { merchant } = useAuthStore((store) => store);
  const [campaignList, setCampaignList] = useState([]);
  const [error] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    function handleCampaignList(query: any) {
      const items:any = [];
      query.forEach((doc:any) => {
        items.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setCampaignList(items);
    }

    if(merchant && merchant.merchant_id) {
      const sub = firebase.firestore()
        .collection('campaigns')
        .where('merchant_id', '==', merchant.merchant_id)
        .orderBy('created_at', 'desc')
        .onSnapshot(handleCampaignList);

      
      
      return sub;
    }
  }, [merchant])

  async function onCreate() {
    setMessage('');
    console.log(message);
    return firebase.firestore().collection('campaigns').add({
      merchant_id: merchant.id,
      message: message,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      send_at: firebase.firestore.FieldValue.serverTimestamp()
    }).then(() => {
      setSuccess(true);
    })
  }

  return (
    <IonPage>
      <IonAlert
        isOpen={confirm}
        onDidDismiss={() => setConfirm(false)}
        header={'Confirm'}
        cssClass="secondary"
        subHeader={'Are you sure you want to send this message? This message will go out to all your customers.'}
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: onCreate
          }
        ]}
      />
      <MerchantHeader title="Create Campaign" />
      <IonContent className="ion-padding">
        <div style={{ maxWidth: 500 }}>
          {(() => {

            if(success) {
              return (
                <IonCard color="secondary">
                  <IonCardHeader>
                    <IonCardTitle>Success <IonIcon icon={checkmark} /></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>Message was sent.</p>
                  </IonCardContent>
                  <IonButton expand="block" onClick={() => setSuccess(false)}>Go Back</IonButton>
                </IonCard>
              )
            }

            return (
              <form onSubmit={(e: any) => {
                e.preventDefault();
                setConfirm(true);
              }}>
                <IonList lines="none">
                  <IonItem color="tertiary">
                    <IonLabel position="floating">Message</IonLabel>
                    <IonTextarea maxlength={160} value={message} onIonChange={(e: any) => setMessage(e.target.value)} required rows={5} cols={10} />
                  </IonItem>
                </IonList>
                <br />
                <IonNote>Limit to 160 characters because otherwise carriers mark it as spam.</IonNote>
                <br />
                <br />
                {error && <div className="ion-margin-vertical">
                  <IonLabel color="danger">Oops! Something doesn't look right.</IonLabel>
                </div>}
                <IonButton type="submit" expand="block" color="primary">
                  Send
                  <IonIcon icon={send} slot="end" />
                </IonButton>
              </form>
            )
          })()}

          <IonList>
            {campaignList.map((item: any) => {
              return (
                <IonItem key={item.id}>
                  <IonLabel>
                    <h2>{item.message}</h2>
                    <h3><FormatData type="date" value={item.created_at} /></h3>
                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default CreateCampaign
