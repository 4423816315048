import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as serviceWorker from './serviceWorker';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();

// if ("serviceWorker" in navigator && process.env.NODE_ENV === 'production') {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }