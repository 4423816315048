import React, { useEffect } from 'react'
import { IonLabel, IonItem, IonProgressBar, IonAvatar } from '@ionic/react'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useAuthStore } from '../../store/AuthStore';
import FuzzySearch from 'fuzzy-search';
import { FormatData } from '../FormatData';
import md5 from 'md5';

interface Props {
  searchValue: String;
  updateSearchValue: any;
}

const CustomerSearchList = (props: Props) => {
  const { searchValue, updateSearchValue } = props;
  let history = useHistory();
  const {merchant} = useAuthStore((store) => store);
  const [chats, setChats] = useState([]);
  const [searchIndex, setSearchIndex] = useState<any>();

  useEffect(() => {
    function handleChange(query: any) {
      const items: any = [];
      query.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      })
      setChats(items);
    }

    if(searchValue.length > 0 && merchant.merchant_id) {
      const sub = firebase.firestore().collection('chats').where('merchant_id', '==', merchant.merchant_id).onSnapshot(handleChange);

      return sub;
    }
  }, [searchValue, merchant.merchant_id])

  useEffect(() => {
    const searcher:any = new FuzzySearch(chats, ['user_display_name', 'user_phone', 'words', 'phone']);
    setSearchIndex(searcher);
  }, [chats]);

  async function startChat(item: any) {
    const chatId = item.id;
    await firebase.firestore()
      .collection('chats')
      .doc(chatId).set({
        tab_id: item.id,
        user_display_name: item.user_display_name,
        user_id: item.user_id,
        merchant_name: item.merchant_name,
        merchant_id: item.merchant_id,
        user_phone: item.user_phone,
        merchant_phone: item.merchant_phone ? item.merchant_phone : merchant.merchant_phone,
        status: 'open',
        updated_at: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true });
    history.push(`/chats/${chatId}`);
    updateSearchValue('');
  }

  if(!searchIndex) {
    return (
      <IonProgressBar type="indeterminate" />
    )
  }

  return (
    <React.Fragment>
      {(() => {
        if(searchIndex.search(searchValue).length < 1) {
          return (
            <IonItem lines="none">
              <IonLabel>
                Start typing to see results...
              </IonLabel>
            </IonItem>
          )
        }
      })()}
      {searchIndex.search(searchValue).map((item: any, key: number) => (
        <IonItem button key={key} onClick={() => startChat(item)}>
          <IonAvatar slot="start">
            <img alt={item.user_display_name} src={`https://www.gravatar.com/avatar/${md5(item.user_phone)}?d=identicon`} />
          </IonAvatar>
          <IonLabel>
            <h2>
              {(() => {
                if (item.user_display_name) {
                  return <FormatData type="text" value={item.user_display_name} />
                }

                return <FormatData type="phone" value={item.user_phone} />;
              })()}
            </h2>
            <p>
              {item.user_phone && <React.Fragment>Phone: <FormatData type="phone" value={item.user_phone} /> </React.Fragment>}
              {(item.user_phone && item.balance) && <span> | </span>}
              {item.balance && <React.Fragment>Balance: <FormatData type="dollar" value={item.balance} /> </React.Fragment>}
              {!item.balance && <React.Fragment>Balance: <FormatData type="dollar" value={`0`} /> </React.Fragment>}
            </p>
          </IonLabel>
        </IonItem>
      ))}
    </React.Fragment>
  )
}

export default CustomerSearchList
