import React, { useState, useEffect }  from 'react'
import { IonGrid, IonRow, IonCol, useIonViewWillEnter, useIonViewWillLeave, IonList, IonListHeader, IonItem, IonLabel, IonCard, IonNote, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonProgressBar, IonPage, IonContent } from '@ionic/react'
import firebase from 'firebase/app';
import 'firebase/firestore';

import { FormatData } from '../../components/FormatData';
import find from 'lodash/find';
import ListView from '../../components/ListView';
import MerchantHeader from '../../components/MerchantHeader';
import { useParams } from 'react-router';
import { useAuthStore } from '../../store/AuthStore';


interface Props {
  
}

const Orders: React.FC<Props> = () => {
  const {merchant} = useAuthStore((store) => store);
  let { orderId } = useParams();
  const [listener, setListener] = useState(undefined);
  const [ordersList, setOrdersList] = useState([]);
  const [orderBy] = useState('all');
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useIonViewWillEnter(() => {
    function handleOrders(query: any) {
      const items:any = [];

      setLoading(false);

      query.forEach((doc: any) => {
        items.push({
          ...doc.data(),
          id: doc.id,
          date: doc.data().created_at,
          primary: doc.data().user_display_name,
          secondary: doc.data().total,
          link: `/orders/${doc.id}`,
        })
      })
      setOrdersList(items);
      
    }
    const merchantId = merchant.merchant_id;
    let sub = () => {};
    if(orderBy === 'all') {
      sub = firebase.firestore().collection('orders').where('merchant_id', '==', merchantId).orderBy('created_at', 'desc').onSnapshot(handleOrders);
    } else {
      sub = firebase.firestore().collection('orders').where('merchant_id', '==', merchantId).where('fulfillment_status', '==', orderBy).orderBy('created_at', 'desc').onSnapshot(handleOrders);
    }
    setListener(() => sub);
  }, [orderBy]);

  useEffect(() => {
    function handleOrders(query: any) {
      const items:any = [];

      setLoading(false);

      query.forEach((doc: any) => {
        items.push({
          ...doc.data(),
          id: doc.id,
          date: doc.data().created_at,
          primary: doc.data().user_display_name,
          secondary: doc.data().total,
          link: `/orders/${doc.id}`,
        })
      })
      setOrdersList(items);
      
    }

    const merchantId = merchant.merchant_id;
    let sub = () => {};
    console.log(orderBy);
    if(orderBy === 'all') {
      sub = firebase.firestore().collection('orders').where('merchant_id', '==', merchantId).orderBy('created_at', 'desc').onSnapshot(handleOrders);
    } else {
      sub = firebase.firestore().collection('orders').where('merchant_id', '==', merchantId).where('fulfillment_status', '==', orderBy).orderBy('created_at', 'desc').onSnapshot(handleOrders);
    }
    setListener((prevListener: any) => {
      if(prevListener) {
        prevListener();
      }
      return sub;
    });
  }, [orderBy, merchant.merchant_id])

  useEffect(() => {
    if(orderId && ordersList.length > 0) {
      setSelectedOrder(find(ordersList, function(o) {return o.id === orderId;}));
    }
  }, [orderId, ordersList])

  useIonViewWillLeave(() => {
    listener && listener();
  }, [listener]);

  return (
    <IonPage>
      <MerchantHeader title="Orders" />
      <IonContent>
        {loading && <IonProgressBar type="indeterminate" />}
        {!loading && <div style={{maxWidth: 768, margin: '0 auto'}}>
          <IonGrid>
            <IonRow>
              <IonCol size="5">
                <ListView
                  data={ordersList}
                  selectedId={orderId}
                />
              </IonCol>
              {(!orderId || !selectedOrder) && <IonCol size="7">
                <div className="ion-text-center">
                  <p>No order selected.</p>  
                </div>
              </IonCol>}
              {(orderId && selectedOrder) && <IonCol size="7">
                <div className="ion-margin-start ion-margin-end">
                  <IonCard>
                    <IonCardHeader className="ion-no-padding ion-padding-top ion-padding-horizontal">
                      <IonCardSubtitle>
                        Customer: <FormatData type="text" value={selectedOrder.user_display_name} />
                      </IonCardSubtitle>
                      <IonCardTitle>
                        Total: <FormatData type="dollar" value={selectedOrder.total} />
                      </IonCardTitle>
                    </IonCardHeader>
                    <br />
                    <IonCardContent className="ion-no-padding ion-padding-bottom ion-padding-horizontal">
                      <IonList>
                        <IonListHeader className="ion-no-padding">
                          Items:
                        </IonListHeader>
                        {selectedOrder.lineItems.map((item:any) => (
                          <IonItem key={item.id} className="ion-no-padding">
                            <IonLabel>
                              <FormatData type="text" value={item.name} /> x <FormatData type="text" value={item.quantity} />
                            </IonLabel>
                            <IonNote slot="end" color="dark">
                              <FormatData type="dollar" value={item.price} />
                            </IonNote>
                          </IonItem>
                        ))}
                        <IonItem lines="none" className="ion-no-padding">
                          <IonNote>
                            <FormatData type="text" value={selectedOrder.status} /> @ <FormatData type="date" value={selectedOrder.created_at} />
                          </IonNote>
                        </IonItem>
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>}
            </IonRow>
          </IonGrid>
        </div>}
      </IonContent>      
    </IonPage>
  )
}

export default Orders
