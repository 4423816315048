import React, {useEffect, useState } from 'react';
import { StreamApp, Streami18n } from 'react-activity-feed';
import { useAuthStore } from '../store/AuthStore';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { IonProgressBar } from '@ionic/react';
import moment from 'moment';
// import Moment from 'moment';

const i18n = new Streami18n({
  DateTimeParser: moment,
  disableDateTimeTranslations: false
 })

function StreamWrapper(props) {
  const {userType, merchant} = useAuthStore((store) => store);
  const [token, setToken] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getStreamToken(id, isMerchant) {
        const tokenDoc = await firebase.firestore().collection('stream').doc(id).get();
        let newToken;
        if(tokenDoc.exists) {
          newToken = tokenDoc.data().token
        } else {
          const createStreamToken = firebase.functions().httpsCallable('createStreamToken');
          const result = await createStreamToken({
            merchant_id: (isMerchant) ? id : false
          });
          newToken = result.data;
        }
        return newToken;
    }

    async function init() {
      if(userType !== 'merchant') {
        const user = firebase.auth().currentUser;

        const value = await getStreamToken(user.uid, false);
        setToken(value);
      }
      
      if(merchant && merchant.merchant_id) {
        const value = await getStreamToken(merchant.merchant_id, true);
        setToken(value);
      }

      setLoading(false);
    }    

    init();
  }, [userType, merchant]);

  if(loading) {
    return <IonProgressBar type="indeterminate" />
  }

  if(!token) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }

  return (
    <StreamApp
      apiKey={process.env.REACT_APP_STREAM_APPKEY}
      appId={process.env.REACT_APP_STREAM_APPID}
      token={token} 
      i18nInstance={i18n}
    >
      {props.children}
    </StreamApp>
  )
}

export default StreamWrapper

