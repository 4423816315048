import React, { useState, useEffect } from 'react';
import { IonList, IonSelectOption, IonSelect, IonLabel, IonItem, IonGrid, IonRow, IonCol, IonInput } from '@ionic/react';
import SquarePayment from '../../../components/payments/SquarePayment';
import { FormatData } from '../../../components/FormatData';
import round from 'lodash/round';

import firebase from 'firebase/app';
import 'firebase/firestore';
// import 'firebase/functions';

interface Props {
  tab: any;
  updateScreen: any;
}

const amountParams = [
  {
    value: '1000',
    text: '$10.00'
  },
  {
    value: '2500',
    text: '$25.00'
  },
  {
    value: '5000',
    text: '$50.00'
  },
  {
    value: '10000',
    text: '$100.00'
  },
  {
    value: '20000',
    text: '$200.00'
  },
  {
    value: 'custom',
    text: 'Custom'
  }
];

const tipParams = [
  {
    value: 0,
    text: 'None'
  },
  {
    value: 15,
    text: '15%'
  },
  {
    value: 20,
    text: '20%'
  },
  {
    value: 25,
    text: '25%'
  },
  {
    value: 30,
    text: '30%'
  }
]

export const AddTransfer: React.FC<Props> = (props) => {
  const { tab } = props;
  const [paymentProcess] = useState('square');
  const [paymentAmount, setPaymentAmount] = useState<any>(2500);
  const [tipPercent, setTipPercent] = useState(20);
  const [tipAmount, setTipAmount] = useState(0);
  const [opelFee, setOpelFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [numberOfDrinks, setNumberOfDrinks] = useState('');
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<any>({});
  const [paymentType, setPaymentType] = useState('');
  const [selectedAmount, setSelectedAmount] = useState<any>('2500');
  const [customAmount, setCustomAmount] = useState({
    formatted: '',
    unformatted: 0
  });

  useEffect(() => {
    async function init() {
      const settingsDoc = await firebase.firestore().collection('merchants').doc(tab.merchant_id).get();
      setSettings({
        pay_what_you_want: settingsDoc.data().pay_what_you_want,
        tax_rate: settingsDoc.data().tax_rate,
        id: settingsDoc.id,
        tipping_off: settingsDoc.data().tipping_off,
        only_donate: settingsDoc.data().only_donate
      });
      setLoading(true);

      if(settingsDoc.data().tipping_off) {
        setTipPercent(0);
        setTipAmount(0);
      }
      if(settingsDoc.data().only_donate) {
        setPaymentType('donate');
        setTipPercent(0);
      } else {
        setPaymentType('transfer');
      }
    }

    if(tab && tab.merchant_id && paymentType !== 'donate') {
      init();
    }
    if(tab && tab.merchant_id && paymentType === 'donate') {
      setSettings((prevState: any) => ({
        pay_what_you_want: false,
        tax_rate: 0,
        id: tab.merchant_id,
        tipping_off: true,
        only_donate: prevState.only_donate
      }));
      setTipAmount(0);
      setTax(0);
    }
  }, [tab, paymentType]);

  useEffect(() => {
    setLoading(true);
    const beforeFeeTotal = Number(paymentAmount) + Number(tipAmount);
    if (beforeFeeTotal !== 0) {
      setOpelFee((beforeFeeTotal * 3) / 100);
    } else {
      setOpelFee(0)
    }
  }, [paymentAmount, tipAmount]);

  useEffect(() => {
    if(settings.tax_rate) {
      const taxableTotal = (paymentAmount + opelFee);
      const newTax = ((taxableTotal * settings.tax_rate) / 100);
      setTax(newTax);
    }
  }, [paymentAmount, opelFee, settings.tax_rate])

  useEffect(() => {
    setLoading(true);
    setTipAmount((Number(paymentAmount) * Number(tipPercent)) / 100);
  }, [tipPercent, paymentAmount]);

  useEffect(() => {
    setLoading(true);
    setTotal(Number(paymentAmount) + Number(tipAmount) + Number(opelFee) + Number(tax));
    setSubtotal(Number(paymentAmount) + Number(opelFee));
  }, [paymentAmount, tipAmount, opelFee, tax])

  function onSuccess() {
    props.updateScreen('confirmation');
  }

  useEffect(() => {
    if(loading) {
      setLoading(!loading);
    }
  }, [loading])

  function shouldShowTax() {
    if(settings && (settings.tax_rate && settings.tax_rate > 0)) {
      return true;
    }

    return false;
  }

  function checkRequiredFields() {
    if (paymentType === '') return false;
    if (paymentAmount < 1) return false;
    if (loading) return false;

    return true;
  }

  useEffect(() => {
    if(selectedAmount !== 'custom') {
      setPaymentAmount(Number(selectedAmount));
    }
    if(selectedAmount === 'custom') {
      setPaymentAmount(0);
    }
  }, [selectedAmount])

  function handleCustomAmount(eventValue: any) {
    let removedPrefix = eventValue.split('$');
    if(removedPrefix.length === 1) {
      const newValue = `$${eventValue}`;
      removedPrefix = newValue.split('$');
    }
    const newAmount:any = Number(removedPrefix[1]);
    setCustomAmount({
      formatted: `$${removedPrefix[1]}`,
      unformatted: (newAmount * 100)
    })
  }

  useEffect(() => {
    if(selectedAmount === 'custom') {
      setPaymentAmount(customAmount.unformatted)
    }
  }, [customAmount, selectedAmount]);

  return (
    <React.Fragment>
      <IonList lines="full" className="card-margin">
        
        <IonItem color="tertiary">
          <IonLabel>Type of payment:*</IonLabel>
          <IonSelect
            interface="popover"
            value={paymentType} 
            onIonChange={(e) => {setPaymentType(e.detail.value)}}
          >
            {(() => {
              if (settings && settings.only_donate) {
                return <IonSelectOption value="donate">Donation</IonSelectOption>;
              }

              return (
                <React.Fragment>
                  <IonSelectOption value="donate">Donate</IonSelectOption>
                  <IonSelectOption value="transfer">Add credit</IonSelectOption>
                </React.Fragment>
              )
            })()}
          </IonSelect>
        </IonItem>
        <IonItem color="tertiary">
          <IonLabel>Amount:*</IonLabel>
          <IonSelect 
            interface="popover"
            value={selectedAmount} 
            onIonChange={(e) => {setSelectedAmount(e.detail.value)}}
            placeholder="$0.00"
          >
            {amountParams.map((o:any, key) => (
              <IonSelectOption 
                key={key} 
                value={o.value}
              >
                {o.text}
              </IonSelectOption>  
            ))}
          </IonSelect>
        </IonItem>
        {selectedAmount === 'custom' && <IonItem color="tertiary">
          <IonLabel>Custom Amount:*</IonLabel>
          <IonInput 
            value={customAmount.formatted}
            onIonChange={(e: any) => handleCustomAmount(e.target.value)}
            className="ion-text-end" 
            slot="end"
            placeholder="XX"
          />
        </IonItem>}
        {(settings && settings.pay_what_you_want) && <IonItem color="tertiary">
          <IonLabel>Number Of Drinks:*</IonLabel>
          <IonInput 
            value={numberOfDrinks}
            onIonChange={(e: any) => setNumberOfDrinks(e.target.value)}
            className="ion-text-end" 
            slot="end" 
            type="number" 
            placeholder="XX"
          />
        </IonItem>}
        {!settings.tipping_off && <IonItem color="tertiary">
          <IonLabel>Tip Percent (%):*</IonLabel>
          <IonSelect 
            interface="popover"
            value={tipPercent} 
            onIonChange={(e) => {setTipPercent(Number(e.detail.value))}}
            placeholder="%"
          >
            {tipParams.map((o:any, key) => (
              <IonSelectOption 
                key={key} 
                value={o.value}
              >
                {o.text}
              </IonSelectOption>  
            ))}
          </IonSelect>
        </IonItem>}
        
        {/* <IonItemDivider color="tertiary" /> */}
        <IonItem>
          <IonLabel>Service Fee:</IonLabel>
          <IonLabel slot="end" color="dark" className="ion-text-right"><FormatData type={`dollar`} value={round(opelFee)} /></IonLabel>
        </IonItem>
        {shouldShowTax() && <IonItem>
          <IonLabel>Tax:</IonLabel>
          <IonLabel slot="end" color="dark" className="ion-text-right"><FormatData type={`dollar`} value={round(tax)} /></IonLabel>
        </IonItem>}
        {!settings.tipping_off && <IonItem>
          <IonLabel>Tip Amount ($):</IonLabel>
          <IonLabel slot="end" color="dark" className="ion-text-right"><FormatData value={round(tipAmount)} type={`dollar`} /></IonLabel>
        </IonItem>}
        <IonItem color="primary">
          <IonLabel>Total:</IonLabel>
          <IonLabel slot="end" color="light" className="ion-text-right"><FormatData type={`dollar`} value={round(total)} /></IonLabel>
        </IonItem>
      </IonList>
      <IonGrid>
        <IonRow>
          <IonCol>
            <div
              style={{
                width: '100%'
              }}
            >
              {(paymentProcess === 'square' && checkRequiredFields()) && <SquarePayment 
                paymentDetails={{
                  subtotal: subtotal,
                  total: total,
                  opel_fee: opelFee,
                  tip_amount: tipAmount,
                  tip_percent: tipPercent,
                  tax: tax,
                  tax_percent: (settings.tax_rate) ? settings.tax_rate : 0,
                  payment_process: paymentProcess,
                  payment_amount: total,
                  transfer_amount: Number(paymentAmount),
                  payment_type: paymentType
                }} 
                tab={props.tab}
                numberOfDrinks={settings.pay_what_you_want && numberOfDrinks}
                onSuccess={onSuccess}
              />}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  )
}
