import { createStore } from 'react-use-sub';
import firebase from 'firebase/app';
import 'firebase/auth';

declare global {
  interface Window {
    analytics: any;
  }
}

const [useAuthStore, AuthStore] = createStore({
  user: undefined,
  merchant: undefined,
  userType: undefined,
  loading: true,
  merchantLoading: true,
  signOut: async function() {
    if (window.analytics) {
      window.analytics.reset();
    }
    await firebase.auth().signOut();
    window.location.href = "/";
  }
});



export { useAuthStore, AuthStore };