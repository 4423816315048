import React, { useState, useEffect } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect } from 'react-router-dom';
import { IonRouterOutlet, IonProgressBar } from '@ionic/react';
import ProtectedRoute from './components/ProtectedRoute';
// basket, shuffle
import { walletOutline, homeOutline, cogOutline } from 'ionicons/icons';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { AppPage } from './declarations';

/* Pages */
import RegularSettings from './pages/regular/settings';
import RegularTabIndex from './pages/regular/tab';
import Menu from './components/Menu';
import TabList from './pages/regular/TabList';
import { RegularsList } from './pages/merchant/RegularsList';
import { MerchantTabIndex } from './pages/merchant/tab';
import MerchantSettings from './pages/merchant/settings';
import ConnectSquare from './pages/merchant/settings/ConnectSquare';
import MerchantOrders from './pages/merchant/Orders';
import MerchantTransfers from './pages/merchant/Transfers';
import ChatDetailPage from './pages/merchant/chat/ChatDetailPage';
import ChatIndexPage from './pages/merchant/chat/ChatIndexPage';
import Confirmation from './pages/auth/Confirmation';
import MerchantSetup from './pages/merchant/settings/MerchantSetup';
import SplitPanePageWrapper from './components/SplitPanePageWrapper';
import PhoneInfo from './pages/auth/PhoneInfo';

import { AuthStore, useAuthStore } from './store/AuthStore';
import SignupRegular from './pages/auth/SignupRegular';
import InviteWrapper from './pages/auth/InviteWrapper';
import Signin from './pages/auth/Signin';
import AddCustomers from './pages/merchant/AddCustomers';
import CreateCampaign from './pages/merchant/CreateCampaign';
import NoMatch from './pages/auth/NoMatch';
import MerchantPresenceWrapper from './components/MerchantPresenceWrapper';
import StreamWrapper from './components/StreamWrapper';
import CommunityFeedRegular from './pages/regular/CommunityFeedRegular';
import CommunityFeedMerchant from './pages/merchant/CommunityFeedMerchant';
import BusinessProfileActivity from './pages/common/BusinessProfileActivity';
import BusinessProfilePay from './pages/common/BusinessProfilePay';
import BusinessProfileDonate from './pages/common/BusinessProfileDonate';
import EditCatalogItems from './pages/merchant/EditCatalogItems';
import SignupBusiness from './pages/auth/SignupBusiness';

// import { ChatStore } from './store/ChatStore';

interface Props {

}


const InitRoutes: React.FC<Props> = () => {
  const { userType, user, merchant, merchantLoading } = useAuthStore((store) => store);
  const [loading, setLoading] = useState(true);

  const regularPages: AppPage[] = [
    {
      title: 'Global Feed',
      url: '/news',
      icon: homeOutline
    },
    {
      title: 'Tabs',
      url: '/tabs',
      icon: walletOutline
    },
    // {
    //   title: 'Orders',
    //   url: '/orders',
    //   icon: basket
    // },
    // {
    //   title: 'Transfers',
    //   url: '/transfers',
    //   icon: shuffle
    // },
    {
      title: 'Settings',
      url: '/settings',
      icon: cogOutline
    }
  ];


  useEffect(() => {

    if (!!userType) {
      setLoading(false);
    } else if (userType === '') {
      setLoading(false);
    }
  }, [userType])

  useEffect(() => {
    function handleChange(doc: any) {
      AuthStore.set({
        merchant: { id: doc.id, ...doc.data(), exists: doc.exists },
        merchantLoading: false
      });
    }

    if (userType !== 'regular' && user && user.merchant_id) {
      const sub = firebase.firestore().collection('merchants').doc(user.merchant_id).onSnapshot(handleChange);
      return sub;
    } else {
      window.location.href = 'https://opelpay.com/'
      AuthStore.set({
        merchantLoading: false
      });
    }

  }, [userType, user]);


  if (loading || (userType === 'merchant' && merchantLoading)) {
    return (
      <IonProgressBar type="indeterminate" />
    )
  }

  return (
    <StreamWrapper>
      <IonReactRouter>
        {(() => {
          if (userType === 'regular') {
            return (
              <React.Fragment>
                <Menu appPages={regularPages} />
                <IonRouterOutlet id="main">
                  <Route path="/profile/:id" exact component={BusinessProfileActivity} />
                  {/* <Route path="/profile/:id" exact component={BusinessProfileShop} /> */}
                  <Route path="/profile/:id/pay" component={BusinessProfilePay} />
                  <Route path="/profile/:id/donate" component={BusinessProfileDonate} />
                  <ProtectedRoute path="/news" component={CommunityFeedRegular} exact={true} />
                  <ProtectedRoute path="/tabs" component={TabList} exact={true} />
                  <ProtectedRoute path="/tabs/:tabId" component={RegularTabIndex} exact />
                  <ProtectedRoute path="/tabs/:tabId/:screen" component={RegularTabIndex} />
                  <ProtectedRoute path="/settings" component={RegularSettings} />
                  <ProtectedRoute path="/" component={TabList} exact={true} />
                  <Route path="/r/:inviteId">
                    <InviteWrapper>
                      <SignupRegular noRedirectSuccess={true} />
                    </InviteWrapper>
                  </Route>
                  <Route path="/login/:inviteId">
                    <InviteWrapper>
                      <Signin noRedirectSuccess={true} />
                    </InviteWrapper>
                  </Route>
                  <Route path="/edit" component={SignupRegular} />
                  <Route path="/confirmation" component={Confirmation} />
                  <Route path="/completeSignup" component={RegularSettings} />
                  <Route path="*" component={NoMatch} />
                  <Redirect from="/signup" to="/" />
                </IonRouterOutlet>
              </React.Fragment>
            )
          }

          if (userType === 'merchant' && user) {
            if (!user.merchant_id || !merchant || !merchant['merchant_phone_id']) {
              // Init Merchant Signup
              return (
                <IonRouterOutlet id="main">
                  <Route path="/signupBusiness" component={SignupBusiness} />
                  <Route path="*" component={SignupBusiness} />
                  <Redirect from="/signup" to="/" />
                </IonRouterOutlet>
              );
            }

            return (
              <MerchantPresenceWrapper>
                <SplitPanePageWrapper>
                  <IonRouterOutlet id="main">
                    <ProtectedRoute path="/" component={ChatIndexPage} exact />
                    <ProtectedRoute path="/tabs" component={RegularsList} exact />
                    <ProtectedRoute path="/tabs/:tabId" component={MerchantTabIndex} />
                    <ProtectedRoute path="/settings" component={MerchantSettings} exact />
                    <ProtectedRoute path="/settings/phone" component={PhoneInfo} />
                    <ProtectedRoute path="/orders" exact component={MerchantOrders} />
                    <ProtectedRoute path="/orders/:orderId" component={MerchantOrders} />
                    <ProtectedRoute path="/transfers" exact component={MerchantTransfers} />
                    <ProtectedRoute path="/transfers/:transferId" component={MerchantTransfers} />
                    <ProtectedRoute path="/chats" component={ChatIndexPage} exact />
                    <ProtectedRoute path="/chats/:chatId" component={ChatDetailPage} />
                    <ProtectedRoute path="/connectSquare" component={ConnectSquare} />
                    <ProtectedRoute path="/addCustomers" component={AddCustomers} />
                    <ProtectedRoute path="/createCampaign" component={CreateCampaign} />
                    <ProtectedRoute path="/posts" component={CommunityFeedMerchant} />
                    <ProtectedRoute path="/editCatalog" component={EditCatalogItems} />
                    <Route path="/profile/:id" exact component={BusinessProfileActivity} />
                    {/* <Route path="/profile/:id" exact component={BusinessProfileShop} /> */}
                    <Route path="/profile/:id/pay" component={BusinessProfilePay} />
                    <Route path="/profile/:id/donate" component={BusinessProfileDonate} />
                    <Route path="/completeSignup" component={MerchantSettings} />
                    <ProtectedRoute path="/merchantSetup">
                      <MerchantSetup user={user} />
                    </ProtectedRoute>
                    <Route path="*" component={NoMatch} />
                    <Redirect from="/signup" to="/" />
                    <Redirect from="/signupBusiness" to="/settings" />
                  </IonRouterOutlet>
                </SplitPanePageWrapper>
              </MerchantPresenceWrapper>
            )
          }

          // We need an error screen here?
          return (
            <IonRouterOutlet id="main">
              <Route path="/signupBusiness" component={SignupBusiness} />
              <Route path="/edit" component={SignupRegular} />
              <ProtectedRoute exact path="/" component={SignupBusiness} />
              <Route path="/confirmation" component={Confirmation} />
              <Route path="/profile/:id" exact component={BusinessProfileActivity} />
              {/* <Route path="/profile/:id" exact component={BusinessProfileShop} /> */}
              <Route path="/profile/:id/pay" component={BusinessProfilePay} />
              <Route path="/profile/:id/donate" component={BusinessProfileDonate} />
              {/* <Redirect from="/signup" to="/" /> */}
            </IonRouterOutlet>
          )
        })()}
      </IonReactRouter>
    </StreamWrapper>
  )
}

export default InitRoutes
