import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore/';
import { IonItem, IonLabel, IonGrid, IonItemOptions, IonItemOption, IonIcon, IonItemSliding, IonCol, IonButton, IonRow, IonContent, IonList, IonListHeader, IonModal, IonToolbar, IonProgressBar, IonHeader, IonTitle, IonFooter, IonToast } from '@ionic/react';
import { FormatData } from './FormatData';
import CatalogList from './CatalogList';

import { informationCircle, alert, add, checkmarkCircle } from 'ionicons/icons';

import find from 'lodash/find';
import reject from 'lodash/reject';
import isUndefined from 'lodash/isUndefined';
import CustomerInfo from './CustomerInfo';
import { useAuthStore } from '../store/AuthStore';
import CustomAmount from './CustomAmount';
import { useHistory } from 'react-router';

interface Props {
  tabId: any;
}

const SecondaryTabInfo: React.FC<Props> = ({ tabId }) => {
  const { merchant } = useAuthStore((store) => store);
  const [tab, setTab] = useState<any>({});
  const [catalogOpen, setCatalogOpen] = useState(false);
  const [customerInfoOpen, setCustomerInfoOpen] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingOrderCreated, setLoadingOrderCreated] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [successOrder, setSuccessOrder] = useState(undefined);
  const [doesTabExist, setDoesTabExist] = useState(false);
  const [customAmountOpen, setCustomAmountOpen] = useState(false);
  let history = useHistory();


  useEffect(() => {
    function handleTicketChange(doc: any) {
      let newTicketTotal = 0;
      let newQuantity = 0;
      let newTicket = [];
      if (doc.exists) {
        newTicketTotal = doc.data().ticket_total ? doc.data().ticket_total : 0;
        newQuantity = doc.data().quantity ? doc.data().quantity : 0;
        newTicket = doc.data().ticket_items ? doc.data().ticket_items : [];
      }

      setTicketTotal(newTicketTotal);
      setQuantity(newQuantity);
      setTicket(newTicket);
    }

    const ticketSub = firebase.firestore().collection('tickets').doc(tabId).onSnapshot(handleTicketChange);

    return ticketSub
  }, [tabId])

  useEffect(() => {
    function handleTabChange(doc: any) {
      setDoesTabExist(doc.exists);
      const newTab: any = { id: doc.id, ...doc.data() }
      if (isUndefined(newTab.balance)) {
        newTab.balance = 0;
      }
      setTab(newTab);
      setLoading(false);
    }

    const tabSub = firebase.firestore().collection('tabs').doc(tabId).onSnapshot(handleTabChange);

    return () => {
      setTicketTotal(0);
      setQuantity(0);
      setTicket([]);
      setLoadingOrderCreated(false);
      setSuccessOrder(undefined)
      if (tabSub) {
        tabSub();
      }
    }
  }, [tabId])

  useEffect(() => {
    let newTicketTotal = 0;
    let newQuantity = 0;
    ticket.forEach((item: any) => {
      newQuantity = newQuantity + item.quantity;
      const itemTotal = item.price * item.quantity;
      newTicketTotal = newTicketTotal + itemTotal;
    });
    firebase.firestore().collection('tickets').doc(tabId).set({
      ticket_total: newTicketTotal,
      quantity: newQuantity
    }, { merge: true });
  }, [ticket, tabId])

  function removeFromTicket(item: any) {
    document.querySelector("ion-item-sliding").closeOpened();
    const newTicketItems = reject(ticket, { id: item.id });
    firebase.firestore().collection('tickets').doc(tabId).set({
      ticket_items: newTicketItems
    }, { merge: true });
  }

  function addToTicket(item: any) {
    let currentItem: any = find(ticket, { id: item.id });
    let newTicket: any = ticket;
    if (merchant && merchant.pay_what_you_want && item && item.price < 1) {
      item.price = Number(tab.price_per_drink);
    }
    if (currentItem) {
      newTicket = reject(ticket, { id: item.id });
      currentItem.quantity = currentItem.quantity + 1;
    } else {
      currentItem = {
        ...item,
        quantity: 1
      }
    }
    firebase.firestore().collection('tickets').doc(tabId).set({
      ticket_items: [...newTicket, currentItem]
    }, { merge: true });
  }

  async function addToTab() {
    let sub: any = undefined
    setLoadingOrderCreated(true);
    const currentTab = tab;
    if (Number(currentTab.balance) < Number(ticketTotal) || isUndefined(currentTab.balance) || Number(ticketTotal) < 1) {
      setLoadingOrderCreated(false);
      return;
    }

    const tabId = tab.id;
    const orderDoc = await firebase.firestore().collection('orders').add({
      fulfillment_status: 'open',
      lineItems: ticket,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      total: ticketTotal,
      tab_id: tabId,
      merchant_phone: merchant.merchant_phone,
      ...currentTab
    });

    async function handleOrder(doc: any) {
      setSuccessOrder({ id: doc.id, ...doc.data() });
      if (doc.data().status === 'SUCCESS') {
        await firebase.firestore().collection('tickets').doc(tabId).update({
          ticket_total: 0,
          quantity: 0,
          ticket_items: []
        });
      }
      if (doc.data().status === 'SUCCESS' || doc.data().status === 'FAILURE') {
        sub();
      }
    }

    sub = firebase.firestore().collection('orders').doc(orderDoc.id).onSnapshot(handleOrder);

    setTicketTotal(0);
    setQuantity(0);
    setTicket([]);
    setLoadingOrderCreated(false);
  }

  async function inviteUser() {
    const messageBox:any = document.getElementById('messageBox');

    const element = await messageBox.getInputElement()
    console.log(element);
    element.value = `Add yourself to our contacts to subscribe and use store credit: https://opel.org/profile/${merchant.merchant_id}/pay`
    return messageBox.setFocus();
    // const ChatId = tabId
    // const chatDoc = await firebase.firestore().collection('chats').doc(ChatId).get();
    // const chat = chatDoc.data();
    // const newMessage = {
    //   message: ``,
    //   created_at: firebase.firestore.FieldValue.serverTimestamp(),
    //   merchant_id: chat.merchant_id,
    //   user_display_name: chat.user_display_name,
    //   user_id: chat.user_id,
    //   to: chat.user_phone,
    //   from: chat.merchant_phone,
    //   merchant_name: chat.merchant_name,
    //   type: 'send'
    // }
    // // return firebase.firestore().collection('chats').doc(ChatId).collection('items').add(newMessage);
  }

  function closeChat() {
    return firebase.firestore().collection('chats').doc(tabId).update({
      status: 'archived'
    }).then(() => {
      history.push('/', { direction: 'forward' });
    })
  }

  async function requestFunds() {
    const chatId = tabId
    const chatDoc = await firebase.firestore().collection('chats').doc(chatId).get();
    const chat = chatDoc.data();
    const newMessage = {
      message: `The subtotal for your order is $${(ticketTotal / 100).toFixed(2)}. Add funds to to your Store Credit on Opel to finish your order.`,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      merchant_id: chat.merchant_id,
      user_display_name: chat.user_display_name,
      user_id: chat.user_id,
      to: chat.user_phone,
      from: chat.merchant_phone,
      merchant_name: chat.merchant_name,
      type: 'send'
    }
    await firebase.firestore().collection('chats').doc(chatId).collection('items').add(newMessage);
    const newLink = {
      message: `https://opel.org/profile/${chat.merchant_id}/pay`,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      merchant_id: chat.merchant_id,
      user_display_name: chat.user_display_name,
      user_id: chat.user_id,
      to: chat.user_phone,
      from: chat.merchant_phone,
      merchant_name: chat.merchant_name,
      type: 'send'
    }
    await firebase.firestore().collection('chats').doc(chatId).collection('items').add(newLink);
    await firebase.firestore().collection('tickets').doc(chatId).update({
      status: 'requested'
    })
  }




  if (successOrder) {
    return (
      <React.Fragment>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {(successOrder && successOrder.status && successOrder.status === 'SUCCESS') && <IonIcon size="large" icon={checkmarkCircle} />}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {(!successOrder.status || (successOrder.status !== 'SUCCESS' && successOrder.status !== 'FAILURE')) && <IonProgressBar type="indeterminate" />}
          <h2>Order: {(successOrder && successOrder.status) ? successOrder.status : 'CREATED'}</h2>
          {(successOrder.status && (successOrder.status === 'SUCCESS' || successOrder.status === 'FAILURE')) && <IonButton
            expand="block"
            color="secondary"
            onClick={() => setSuccessOrder(undefined)}
          >DONE</IonButton>}
        </IonContent>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <IonToast 
        isOpen={!loading && !doesTabExist}
        duration={4000}
        header="Invite customer"
        message="This customer does not have an account."
        color="tertiary"
        position="top"
        buttons={[
          {
            text: 'Not yet',
            role: 'cancel'
          },
          {
            text: 'Send it',
            handler: () => {
              console.log(1);
              inviteUser();
            }
          }
        ]}
      />
      <IonModal
        isOpen={customAmountOpen}
        onDidDismiss={() => setCustomAmountOpen(false)}
      >
        <CustomAmount
          onAction={addToTicket}
          done={() => setCustomAmountOpen(false)}
        />
      </IonModal>
      <IonModal
        isOpen={catalogOpen}
        onDidDismiss={() => setCatalogOpen(false)}
        cssClass="opel-modal-large"
      >
        <CatalogList
          merchantId={merchant.merchant_id}
          quantity={quantity}
          onAction={addToTicket}
          done={() => setCatalogOpen(false)}
        />
      </IonModal>
      <IonModal
        isOpen={customerInfoOpen}
        onDidDismiss={() => setCustomerInfoOpen(false)}
        cssClass="opel-modal-large"
      >
        <CustomerInfo />
      </IonModal>
      <IonHeader>
        <IonToolbar>
          {(loading || loadingOrderCreated) && <IonProgressBar type="indeterminate" />}
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-align-items-center ion-no-padding ion-no-margin">
              <IonCol size="12">
                <IonList lines="full" className="ion-no-margin ion-no-padding">
                  {/* {doesTabExist && <IonItem detail button onClick={() => setCustomerInfoOpen(true)}>
                    <IonLabel>
                      <h2>Customer Info</h2>
                      <p>Look at past orders, transfers, etc.</p>
                    </IonLabel>
                  </IonItem>} */}
                  <IonItem color={(Number(tab.balance) < Number(ticketTotal)) ? 'danger' : 'light'}>
                    <IonLabel>
                      <h2>Balance:</h2>
                      <h2><FormatData value={tab.balance} type="dollar" loading={loading} /></h2>
                    </IonLabel>
                    {(Number(tab.balance) >= Number(ticketTotal)) && <IonIcon color="medium" icon={informationCircle} slot="end" />}
                    {(Number(tab.balance) < Number(ticketTotal)) && <IonIcon color="dark" icon={alert} slot="end" />}
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h3>Total:</h3>
                      <h2><FormatData value={ticketTotal} type={`dollar`} /></h2>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonCol>
              <IonCol
                size="12"
                className="ion-no-padding ion-no-margin"
              >
                <IonList
                  lines={(ticket.length < 1) ? 'none' : 'full'}
                  className="ion-no-padding ion-no-margin"
                >
                  <IonItem button detail onClick={() => setCustomAmountOpen(true)}>
                    <IonLabel>Quick Item</IonLabel>
                  </IonItem>
                  {!merchant.hide_items && <IonItem button onClick={() => setCatalogOpen(true)}>
                    <IonLabel>
                      <h2>Add Item(s)</h2>
                      <p>Search from the catalog</p>
                    </IonLabel>
                    <IonIcon slot="end" icon={add} />
                  </IonItem>}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines="full">
          <IonListHeader>
            {(ticket.length === 0) ? 'No Items' : 'Ticket'}
          </IonListHeader>
          {ticket.map((item: any, key: any) => {
            return (
              <IonItemSliding id={`ticket-slide-${key}`} key={key}>
                <IonItem
                  onClick={() => {
                    const element: any = document.getElementById(`ticket-slide-${key}`);
                    element.open();
                  }}
                  button
                >
                  <IonLabel slot="start" className="ion-no-margin">
                    <h2><FormatData value={item.name} type={`text`} /></h2>
                    <p>Quantity: <FormatData value={item.quantity} type={`number`} /></p>
                  </IonLabel>
                  <IonLabel className="ion-no-margin ion-text-wrap ion-text-end">
                    <FormatData value={item.price} type={`dollar`} />
                  </IonLabel>
                </IonItem>
                <IonItemOptions side="end">
                  <IonItemOption color="danger" onClick={() => removeFromTicket(item)}>Remove</IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            )
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {Number(ticketTotal) < 1 && <IonButton
            onClick={closeChat}
            className="ion-no-margin"
            expand="full"
            size="large"
            color="secondary"
          >
            CLOSE
          </IonButton>}
          {/* {(Number(tab.balance) < Number(ticketTotal) && tab.square_customer_card_id) && <IonButton
            onClick={requestFunds}
            className="ion-no-margin"
            expand="full"
            size="large"
            color="primary"
          >SAVED CARD</IonButton>} */}
          {(Number(tab.balance) < Number(ticketTotal) || (isUndefined(tab.balance) && ticketTotal > 0)) && <IonButton
            onClick={requestFunds}
            className="ion-no-margin"
            expand="full"
            size="large"
            color="secondary"
          >REQUEST</IonButton>}
          <IonButton
            onClick={addToTab}
            className="ion-no-margin"
            expand="full"
            size="large"
            color="primary"
            disabled={isUndefined(tab.balance) || Number(tab.balance) < Number(ticketTotal) || Number(ticketTotal) < 1}
          >CHARGE</IonButton>
        </IonToolbar>
      </IonFooter>
    </React.Fragment>
  )
}

export default SecondaryTabInfo
