import React, { useState, useEffect } from 'react';
import SquarePaymentForm, { ApplePayButton, GooglePayButton, CreditCardNumberInput, CreditCardExpirationDateInput, CreditCardPostalCodeInput, CreditCardSubmitButton, CreditCardCVVInput } from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuthStore } from '../../store/AuthStore';
import { IonProgressBar, IonAlert, IonToast, IonItem, IonLabel, IonCheckbox, IonNote, IonButton, IonIcon } from '@ionic/react';

const loadingView = <div className="sq-wallet-loading"></div>
const unavailableViewApplyPay = <div />
const unavailableViewGooglePay = <div />



interface Props {
  paymentDetails: any;
  tab: any;
  onSuccess: any;
  numberOfDrinks: any;
}

const SquarePayment: React.FC<Props> = ({ paymentDetails, tab, onSuccess, numberOfDrinks }) => {
  const { user } = useAuthStore((store) => store);
  const [errorMessage, setErrorMessage] = useState({
    isOpen: false,
    message: ''
  });
  const [paymentRequest, setPaymentRequest] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [transfer, setTransfer] = useState(undefined);
  const [saveCard, setSaveCard] = useState(false);

  function cardNonceResponseReceived(errors: any, nonce: any, cardData: any, buyerVerificationToken: any) {
    if (errors && errors.length > 0) {
      setErrorMessage({
        isOpen: true,
        message: errors[0].message
      });
      setLoading(false);
      return
    }

    const newTransfer = {
      email: user.email,
      ...tab,
      ...paymentDetails,
      nonce: nonce,
      ...cardData,
      buyer_verification_token: buyerVerificationToken,
      status: 'CREATED',
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (newTransfer.digital_wallet_type !== 'NONE') {
      sendPayment(newTransfer);
    } else {
      setTransfer({
        ...newTransfer,
        save_card: saveCard
      });
    }
  }

  function sendSavedCard() {
    const newTransfer = {
      email: user.email,
      ...tab,
      ...paymentDetails,
      status: 'CREATED',
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      nonce: tab.square_customer_card_id
    };
    sendPayment(newTransfer);
  }

  useEffect(() => {
    setPaymentRequest({
      amount: `${Number(paymentDetails.total / 100).toFixed(2)}`,
      requestShippingAddress: false,
      requestBillingInfo: false,
      currencyCode: "USD",
      countryCode: "US",
      total: {
        label: tab.merchant_name ? tab.merchant_name : '',
        amount: `${Number(paymentDetails.total / 100).toFixed(2)}`,
        pending: false
      },
      lineItems: []
    });
    if (!isNaN(paymentDetails.total)) {
      setLoading(false);
    }

  }, [paymentDetails, tab]);

  function createPaymentRequest() {
    return paymentRequest;
  }

  async function sendPayment(data: any) {
    setLoading(true);
    return firebase.firestore().collection('transfers').add(data).then(onSuccess);
  }

  if (loading) {
    return <IonProgressBar type="indeterminate" />
  }

  return (
    <React.Fragment>
      <IonToast
        isOpen={errorMessage.isOpen}
        message={errorMessage.message}
        duration={4000}
        color="danger"
        position="top"
        onDidDismiss={() => setErrorMessage({ isOpen: false, message: '' })}
      />
      <IonAlert
        isOpen={!!transfer}
        onDidDismiss={() => setTransfer(undefined)}
        header={'Confirm Payment'}
        // message={`Are you sure you want to pay a  ${Number(paymentDetails.total / 100).toFixed(2)}`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Confirm',
            handler: () => sendPayment(transfer)
          }
        ]}
      />
      <SquarePaymentForm
        sandbox={Boolean(process.env.REACT_APP_SQUARE_SANDBOX)}
        locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        cardNonceResponseReceived={cardNonceResponseReceived}
        createPaymentRequest={createPaymentRequest}
        inputStyles={[
          {
            fontSize: '16px',
            fontFamily: 'Helvetica Neue',
            padding: '16px',
            color: '#333333',
            backgroundColor: 'transparent',
            lineHeight: '24px',
            placeholderColor: '#777777',
            _webkitFontSmoothing: 'antialiased',
            _mozOsxFontSmoothing: 'grayscale',
          }
        ]}
      >
        {tab.square_customer_card_id && <React.Fragment>
          <IonButton onClick={sendSavedCard} size="large" style={{marginBottom: 10, height: '2.4em', fontSize: 18}} expand="block">
            Pay with saved card <IonIcon slot="end" src="/assets/logo.svg" />
          </IonButton>
        </React.Fragment>}
        <ApplePayButton loadingView={loadingView} unavailableView={unavailableViewApplyPay} />
        <GooglePayButton loadingView={loadingView} unavailableView={unavailableViewGooglePay} />
        <div className="credit-card-wrapper">
          <br />
          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />
            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>
            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
            <div className="sq-form-third">
              <CreditCardPostalCodeInput />
            </div>
          </fieldset>
          <IonItem lines="none" color="secondary">
            <IonLabel className="ion-text-wrap">
              Save card at {paymentRequest.total.label} <br />
              <IonNote>This allows {paymentRequest.total.label} you to charge this card.</IonNote>
            </IonLabel>

            <IonCheckbox
              onIonChange={(e: any) => setSaveCard(e.target.checked)}
              checked={saveCard}
              style={{ marginRight: 16 }}
              color="tertiary"
              slot="start"
              mode="ios"
            />
          </IonItem>
          <CreditCardSubmitButton>
            {(paymentDetails.payment_type === 'donate') ? `Donate` : `Add Credit`} - ${Number(paymentDetails.total / 100).toFixed(2)}
          </CreditCardSubmitButton>
        </div>
      </SquarePaymentForm>
    </React.Fragment>
  )
}

export default SquarePayment
