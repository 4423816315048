import React from 'react';
import { IonButton } from '@ionic/react';

interface Props {
  updateScreen: any;
}

export const Confirmation: React.FC<Props> = (props) => {

  return (
    <div className="ion-text-center" style={{maxWidth: 310, margin: '0 auto'}}>
      <h1>Payment Submitted</h1>
      <p>If there are any issues we'll text you.</p>
      <p>Thank you for supporting local businesses.</p>
      <IonButton 
        className="ion-margin"
        expand="block"
        onClick={() => props.updateScreen('transfers')}
      >
        Done
      </IonButton>
    </div>
  )
}
