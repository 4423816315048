import React, { useState } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonList, IonItem, IonLabel, IonInput, IonButton, IonText, IonToast, IonProgressBar } from '@ionic/react';
import phone from 'phone';

interface Props {

}

const RegisterUserComponent = (props: Props) => {
  const [screen, setScreen] = useState('login');
  const [error, setError] = useState({
    isOpen: false,
    message: ''
  });
  const [phoneConfirmationResult, setPhoneConfirmationResult] = useState<any>(undefined);

  async function onSubmitSignup(e: any) {
    try {
      if(e.preventDefault) {
        e.preventDefault();
      }
      if(e.stopPropagation) {
        e.stopPropagation();
      }

      const registerNewUser = firebase.functions().httpsCallable('registerNewUser');

      const displayName: any = document.getElementById('displayName');
      const email: any = document.getElementById('email');
      const phoneNumber: any = document.getElementById('phone');
      setScreen('loading');
      await registerNewUser({
        displayName: displayName.value,
        email: email.value,
        phoneNumber: phone(phoneNumber.value)[0]
      });


      return onSubmitLogin({}, {value: phone(phoneNumber.value)[0]});

    } catch (error) {
      setScreen('signup');
      setError({
        isOpen: true,
        message: error.message
      })
    }
  }

  async function onSubmitLogin(e: any, phoneElement?: any) {
    try {
      if(e.preventDefault) {
        e.preventDefault();
      }
      if(e.stopPropagation) {
        e.stopPropagation();
      }

      let phoneNumber:any = document.getElementById('phone');
      if (phoneElement) {
        phoneNumber = phoneElement;
      }
      
      const validatedPhone:any = phone(phoneNumber.value, 'USA');

      setScreen('loading');
      if(validatedPhone.length < 1) {
        throw new Error('Not a valid phone number');
      }

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
      });

      const confirmation:any = await firebase.auth().signInWithPhoneNumber(validatedPhone[0], window.recaptchaVerifier);
      setPhoneConfirmationResult(confirmation);

      phoneNumber.value = '';
      setScreen('verify');
    } catch (error) {

      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        document.getElementById('recaptcha-wrapper').innerHTML = '<div id="recaptcha"></div>';
      }
      setScreen('login');
      setError({
        isOpen: true,
        message: error.message
      })
    }
  }

  async function onSubmitVerify(e: any) {
    try {
      if(e.preventDefault) {
        e.preventDefault();
      }
      if(e.stopPropagation) {
        e.stopPropagation();
      }

      const verifyCode: any = document.getElementById('verify');
      await phoneConfirmationResult.confirm(verifyCode.value);
      setScreen('loading');
    } catch (error) {
      setError({
        isOpen: true,
        message: error.message
      })
    }
  }

  async function updateUserInfo(e:any) {
    try {
      if(e.preventDefault) {
        e.preventDefault();
      }
      if(e.stopPropagation) {
        e.stopPropagation();
      }

      const email: any = document.getElementById('email');
      const displayName: any = document.getElementById('displayName');
      
      setScreen('loading');

      await firebase.auth().currentUser.updateEmail(email.value);
      await firebase.auth().currentUser.updateProfile({
        displayName: displayName.value
      });

      
      window.location.reload();
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        
        setScreen('login');
        const phone:any = document.getElementById('phone');
        if(phone) {
          phone.value = '';
        }
        await firebase.auth().signOut();
        window.location.reload();
      }

      setScreen('');
      setError({
        isOpen: true,
        message: error.message
      })
    }
  }

  return (
    <div className="ion-margin-vertical" style={{maxWidth: 500, marginLeft: 'auto', marginRight: 'auto'}}>
      <div id="recaptcha-wrapper"><div id="recaptcha"></div></div>
      <IonToast
        isOpen={error.isOpen}
        message={error.message}
        onDidDismiss={() => setError({
          isOpen: false,
          message: ''
        })}
        duration={4000}
        color="danger"
      />
      {(() => {
        if (screen === 'loading') {
          return (
            <IonProgressBar type="indeterminate" />
          )
        }

        if (
          firebase.auth().currentUser && 
          (!firebase.auth().currentUser.displayName || !firebase.auth().currentUser.email)
        ) {
          return (
            <form onSubmit={updateUserInfo}>
              <IonCard color="secondary" className="ion-no-margin">
                <IonCardHeader>
                  <IonCardTitle>About you:</IonCardTitle>
                  <IonCardSubtitle>Enter your info below</IonCardSubtitle>
                </IonCardHeader>
                <IonList className="ion-no-padding">
                  <IonItem color="dark">
                    <IonLabel position="floating">Full Name</IonLabel>
                    <IonInput name="displayName" id="displayName" type="text" required />
                  </IonItem>
                  <IonItem color="dark">
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput name="email" id="email" type="email" required />
                  </IonItem>
                  <IonItem color="secondary" lines="none">
                    <IonText color="medium" className="ion-margin-vertical">
                      <p className="ion-no-margin">By clicking Next, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.</p>
                    </IonText>
                  </IonItem>
                </IonList>
              </IonCard>
              <br />
              <IonButton className="ion-no-margin" expand="block" type="submit">Next</IonButton>
            </form>
          )
        }

        if (screen === 'verify') {
          return (
            <form onSubmit={onSubmitVerify}>
              <IonCard color="secondary" className="ion-no-margin">
                <IonCardHeader>
                  <IonCardTitle>Verify your phone:</IonCardTitle>
                  <IonCardSubtitle>Check your phone: +13347501252</IonCardSubtitle>
                </IonCardHeader>
                <IonList className="ion-no-padding">
                  <IonItem color="dark">
                    <IonLabel position="floating">Verification Code</IonLabel>
                    <IonInput name="verify" id="verify" type="tel" required />
                  </IonItem>
                  <IonItem color="secondary" lines="none">
                    <IonText color="medium" className="ion-margin-vertical">
                      <p className="ion-no-margin">Please wait at least 30 seconds to receive a confirmation code depending upon cell service or <button style={{ background: 'transparent', color: 'inherit', fontSize: '1rem', padding: 0, textDecoration: 'underline' }} onClick={() => window.location.reload()}>refresh</button> to start over.</p>
                    </IonText>
                  </IonItem>
                </IonList>
              </IonCard>
              <br />
              <IonButton className="ion-no-margin" expand="block" type="submit">Next</IonButton>
            </form>

          )
        }

        if (screen === 'login') {
          return (
            <form onSubmit={onSubmitLogin}>
              <IonCard color="secondary" className="ion-no-margin">
                <IonCardHeader>
                  <IonCardTitle>Start</IonCardTitle>
                  <IonCardSubtitle>Enter your phone number to start</IonCardSubtitle>
                </IonCardHeader>
                <IonList className="ion-no-padding">
                  <IonItem color="dark">
                    <IonLabel position="floating">Phone Number</IonLabel>
                    <IonInput name="phone" id="phone" type="tel" required />
                  </IonItem>
                  <IonItem color="secondary" lines="none">
                    <IonText color="medium" className="ion-margin-vertical">
                      <p className="ion-no-margin">By clicking Next, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.</p>
                    </IonText>
                  </IonItem>

                </IonList>
              </IonCard>
              <br />
              <IonButton className="ion-no-margin" expand="block" type="submit">Next</IonButton>
              <div className="ion-text-center">
                <p>- or -</p>
                <IonButton
                  color="secondary"
                  expand="block"
                  fill="clear"
                  onClick={() => setScreen('signup')}
                >Don't have an account? Sign up</IonButton>
              </div>
            </form>
          )
        }

        return (
          <form onSubmit={onSubmitSignup}>
            <IonCard color="secondary" className="ion-no-margin">
              <IonCardHeader>
                <IonCardTitle>Sign up to start:</IonCardTitle>
              </IonCardHeader>
              <IonList className="ion-no-padding">
                <IonItem color="dark">
                  <IonLabel position="floating">Display Name</IonLabel>
                  <IonInput name="displayName" id="displayName" required />
                </IonItem>
                <IonItem color="dark">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput name="email" id="email" type="email" required />
                </IonItem>
                <IonItem color="dark">
                  <IonLabel position="floating">Phone</IonLabel>
                  <IonInput name="phone" id="phone" type="tel" required />
                </IonItem>
                <IonItem color="secondary" lines="none">
                  <IonText color="medium" className="ion-margin-vertical">
                    <p className="ion-no-margin">By clicking Next, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.</p>
                  </IonText>
                </IonItem>
              </IonList>
            </IonCard>
            <br />
            <IonButton className="ion-no-margin" expand="block" type="submit">Next</IonButton>
            <div className="ion-text-center">
              <p>- or -</p>
              <IonButton
                color="secondary"
                expand="block"
                fill="clear"
                onClick={() => setScreen('login')}
              >Have an account? Log in</IonButton>
            </div>
          </form>
        )

      })()}
    </div>
  )
}

export default RegisterUserComponent
