import React, {useEffect, useState} from 'react';
import FuzzySearch from 'fuzzy-search';
import { IonSearchbar, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonHeader, IonToolbar, IonContent, IonTitle, IonFooter, IonButton } from '@ionic/react';

import firebase from 'firebase/app';
import 'firebase/firestore';

interface Props {
  merchantId: any;
  onAction: any;
  footer?: any;
  quantity?: any;
  done?: any;
  hideTitle?: boolean;
}

const CatalogList: React.FC<Props> = (props) => {
  const { merchantId, onAction } = props;
  const [searchIndex, setSearchIndex] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleItemsChange(query: any) {
      const items:any =[];
      query.forEach((item:any) => {
        const o = item.data();
        if(o.is_deleted) {
          return;
        }
        if(!o.item_data.variations[0].item_variation_data.price_money) {
          return;
        }
        items.push({
          name: o.item_data.name,
          price: o.item_data.variations[0].item_variation_data.price_money.amount,
          id: o.id
        })
      });
      const searcher = new FuzzySearch(items, ['name']);
      setSearchIndex(searcher);
      setLoading(false);
    }

    let itemsSub = () => {};
    if(merchantId) {
      itemsSub = firebase.firestore().collection('square-items').doc(merchantId).collection('items').onSnapshot(handleItemsChange);
    }
    
    return itemsSub;
  }, [merchantId])

  return (
    <React.Fragment>
      <IonHeader>
        {!props.hideTitle && <IonToolbar>
          <IonTitle>
            {props.quantity} item{props.quantity > 1 && `s`} on ticket
          </IonTitle>
          {props.done && <IonButton 
            expand="full" 
            slot="primary" 
            fill="clear" 
            onClick={props.done}
          >
            Done
          </IonButton>}
        </IonToolbar>}
        <IonToolbar>
          <IonSearchbar onInput={(e: any) => setSearchValue(e.target.value)}></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            {(searchIndex && !loading) && <React.Fragment>
              {searchIndex.search(searchValue).slice(0, (searchValue === '') ? 20 : searchIndex.search(searchValue).length).map((item: any, key: number) => {
                return (
                  <IonCol size="6" sizeSm="3" key={key}>
                    <IonCard
                      onClick={() => onAction(item)}
                      button 
                      className="no-margin"
                      color="light"
                    >
                      <IonCardContent style={{background: 'black'}}>
                        <h2 style={{fontSize: 40, textAlign: 'center', color: 'white'}}>{item.name.substring(0, 2)}</h2>
                      </IonCardContent>
                      <IonCardHeader>
                        {/* <IonCardTitle>
                          <FormatData value={item.price} type="dollar" />
                        </IonCardTitle> */}
                        <IonCardSubtitle>
                          {item.name}
                        </IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                  </IonCol>
                )
              })}
            </React.Fragment>}
          </IonRow>
        </IonGrid>
      </IonContent>
      {props.footer && <IonFooter>
        {props.footer}  
      </IonFooter>}
    </React.Fragment>
  )
}

export default CatalogList
