import React, {useEffect, useState} from 'react'
import PageWrapper from '../../../components/PageWrapper'
import { IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonButton, IonCardTitle, IonNote, IonProgressBar } from '@ionic/react'
import { useLocation, useHistory } from 'react-router'
import querystring from 'querystring';
import trim from 'lodash/trim';

import firebase from 'firebase/app';
import 'firebase/functions';

interface Props {
  
}

const ConnectSquare: React.FC<Props> = () => {
  let location = useLocation();
  let history = useHistory();
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function paramsAuth(params: any) {
      try {
        const oauthSquare = firebase.functions().httpsCallable('oauthSquare');
        await oauthSquare(params);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    }

    const params = querystring.parse(
      trim(location.search, '?')
    );

    if(params && params.code) {
      setLoading(true);
      paramsAuth(params)
    }
  }, [location, history])

  useEffect(() => {
    let baseUrl = `https://squareup.com/oauth2/authorize?client_id=${process.env.REACT_APP_SQUARE_APPLICATION_ID}`;
    baseUrl += `&scope=MERCHANT_PROFILE_READ,PAYMENTS_WRITE,PAYMENTS_READ,PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS,CUSTOMERS_READ,ITEMS_READ`
    setUrl(baseUrl);
  }, [])

  return (
    <PageWrapper title="Setup" hideMenu>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeSm="5"> 
            <IonCard>
              <IonCardHeader style={{paddingBottom: 0}}>
                <IonCardSubtitle>Get Started</IonCardSubtitle>
                <IonCardTitle>Connect to Square</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {error && <div className="ion-margin-bottom"><IonNote color="danger">Oops! Something went wrong. Try connecting again.</IonNote></div>}
                {!loading && <IonButton 
                  expand="full"
                  href={url}
                >Start</IonButton>}
                {loading && <IonProgressBar type="indeterminate" />}
              </IonCardContent>
            </IonCard>
            <h2>Pricing:</h2>
            <IonNote color="dark">
              Consumers pay a service fee to cover square's transaction fees (3% + $0.30).
              <br />
              <br />
              Opel, Inc charges a 2% application fee for all payments.
            </IonNote>
          </IonCol>
        </IonRow>
      </IonGrid>
    </PageWrapper>
  )
}

export default ConnectSquare
