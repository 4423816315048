import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonList, IonLabel, IonButton, IonInput, IonItem, IonItemDivider, IonTextarea } from '@ionic/react';
import MerchantHeader from '../../components/MerchantHeader';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuthStore } from '../../store/AuthStore';
import phone from 'phone';

interface Props {

}

const AddCustomers: React.FC<Props> = () => {
  const {merchant} = useAuthStore((store) => store);
  const [inviteList, setInviteList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    function handleQuery(query: any) {
      const newItems:any = [];
      query.forEach((doc: any) => {
        newItems.push({
          id: doc.id,
          ...doc.data()
        });
      });
      setInviteList(newItems);
    }

    if(merchant && merchant.merchant_id) {
      const sub = firebase.firestore()
        .collection('invites')
        .where('merchant_id', '==', merchant.merchant_id)
        .where('status', '==', 'SENT')
        .orderBy('created_at', 'desc')
        .onSnapshot(handleQuery)

      return sub;
    }

  }, [merchant]);

  useEffect(() => {
    async function init() {
      if(!merchant.invite_link) {
        const invite:any = {
          merchant_id: merchant.merchant_id,
          merchant_name: merchant.business_name,
          merchant_phone: merchant.merchant_phone,
          status: 'CREATED',
          is_user_sent: true,
          sent_by_user_id: firebase.auth().currentUser.uid,
          sent_by_user_display_name: firebase.auth().currentUser.displayName,
          created_at: firebase.firestore.FieldValue.serverTimestamp()
        }
        const inviteDoc:any = await firebase.firestore().collection('invites').add(invite);
        invite.id = inviteDoc.id;

        return firebase.firestore().collection('merchants').doc(merchant.merchant_id).update({
          invite_link: `https://opel.org/r/${invite.id}`
        })
      }
    }


    init();
  }, [merchant])

  useEffect(() => {
    if(merchant) {
      setMessage(`Hey It's ${merchant.business_name}. Add yourself to our contacts so you don't miss out on any updates.`);
    }
  }, [merchant]);

  function addInvite() {
    const validatedPhone = phone(phoneNumber, 'USA');

    if(validatedPhone.length < 1) {
      setError(true);
      return;
    }

    if(validatedPhone[1] === 'USA') {
      setPhoneNumber('');
      return firebase.firestore().collection('invites').add({
        message: message,
        merchant_id: merchant.merchant_id,
        merchant_phone: merchant.merchant_phone,
        merchant_phone_id: merchant.merchant_phone_id,
        merchant_name: merchant.business_name,
        phone: validatedPhone[0],
        status: 'SENT',
        from: merchant.merchant_phone,
        to: validatedPhone[0],
        created_at: firebase.firestore.FieldValue.serverTimestamp()
      });
    }
  }

  return (
    <IonPage>
      <MerchantHeader title="Invites" />
      <IonContent className="ion-padding">
        <div style={{ maxWidth: 500 }}>
          <IonList className="ion-no-padding ion-margin-vertical">
            <IonItem>
              <IonLabel position="floating">Invite URL:</IonLabel>
              <IonInput value={merchant.invite_link} />
            </IonItem>
            <IonItemDivider />
            <IonItem color="secondary">
              <IonLabel position="floating">To (Phone Number):</IonLabel>
              <IonInput
                value={phoneNumber}
                onIonChange={(e:any) => setPhoneNumber(e.target.value)}
              />
            </IonItem>
            <IonItem color="secondary">
              <IonLabel position="floating">Message:</IonLabel>
              <IonTextarea maxlength={160} value={message} onIonChange={(e: any) => setMessage(e.target.value)} required rows={5} cols={10} />
            </IonItem>
          </IonList>
          {error && <div className="ion-margin-vertical">
            <IonLabel color="danger">Oops! Something doesn't look right.</IonLabel>
          </div>}
          <IonButton 
            onClick={addInvite} 
            color="primary" 
            expand="block"
          >
            Submit
          </IonButton>
          <IonList>
            {(inviteList.length < 1) && <IonItem lines="none">
              <IonLabel>
                No invites sent
              </IonLabel>  
            </IonItem>}
            {inviteList.map((item: any) => {
              return (
                <IonItem key={item.id}>
                  <IonLabel className="ion-text-wrap">
                    <h3>Status: {item.status}</h3>
                    <h2>Phone: {item.phone}</h2>
                    <p>Message: {item.message}</p>
                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default AddCustomers
