import React, {useEffect, useState, FormEvent} from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonToast, IonCardContent, IonList, IonItem, IonLabel, IonInput, IonToggle, IonProgressBar, IonButton } from '@ionic/react';
import { useAuthStore } from '../store/AuthStore';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';


interface Props {

}

const BusinessAccountInfo = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { merchant } = useAuthStore((store) => store);
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: ''
  });
  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    setFormValues({
      forwardingNumber: merchant.forwarding_phone,
      textNotifications: merchant.notification_number,
      serviceFee: merchant.service_fee,
      allowTipping: !merchant.tipping_off,
      businessName: merchant.business_name
    })
  }, [merchant]);

  async function updateBusinessSettings(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    const updateMerchantSettings = firebase.functions().httpsCallable('updateMerchantSettings');

    try {
      setLoading(true);
      await updateMerchantSettings({
        forwarding_phone: formValues.forwardingNumber,
        notification_number: formValues.textNotifications,
        tipping_off: !formValues.allowTipping,
        service_fee: formValues.serviceFee,
        area_code: formValues.areaCode,
        business_name: formValues.businessName,
        merchant_id: merchant.merchant_id
      });
      setToast({
        isOpen: true,
        message: 'Successfully updated information',
        color: 'primary'
      })
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setToast({
        isOpen: true,
        message: error.message,
        color: 'danger'
      })
    }
  }

  function changeValue(key: any, value: any) {
    setFormValues((prevState: any) => {
      return ({
        ...prevState,
        [key]: value
      })
    })
  }

  return (
    <React.Fragment>
      <IonToast
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({
          isOpen: false,
          color: '',
          message: ''
        })}
        message={toast.message}
        color={toast.color}
        duration={4500}
      />
      
      <form onSubmit={updateBusinessSettings}>
        <IonCard color="secondary" className="ion-no-margin ion-margin-vertical">
          <IonCardHeader>
            <IonCardTitle>Account Info</IonCardTitle>
            <IonCardSubtitle>Have questions? Text us @ +1 (334) 459-9901</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList className="ion-no-padding" lines="full">
              <IonItem color="dark">
                <IonLabel position="floating">Business Name</IonLabel>
                <IonInput 
                  onIonChange={(e:any) => changeValue('businessName', e.target.value)}
                  value={formValues.businessName} 
                  id="businessName" 
                  required
                />
              </IonItem>
              {!merchant.merchant_phone_id && <IonItem color="dark">
                <IonLabel position="floating">What is your area code?</IonLabel>
                <IonInput 
                  minlength={3}
                  maxlength={3}
                  id="areaCode"
                  required
                  onIonChange={(e:any) => changeValue('areaCode', e.target.value)}
                />
              </IonItem>}
              <IonItem color="dark">
                <IonLabel position="floating">What phone number should calls be forwarded to?</IonLabel>
                <IonInput 
                  value={formValues.forwardingNumber} 
                  id="forwardingNumber" 
                  required
                  onIonChange={(e:any) => changeValue('forwardingNumber', e.target.value)}
                />
              </IonItem>
              <IonItem color="dark">
                <IonLabel position="floating">What phone number can text notifications be sent to?</IonLabel>
                <IonInput 
                  value={formValues.textNotifications} 
                  id="textNotifications"
                  required
                  onIonChange={(e:any) => changeValue('textNotifications', e.target.value)}
                />
              </IonItem>
              <IonItem color="dark">
                <IonLabel position="floating">How much would you like to charge for a service fee? (%)</IonLabel>
                <IonInput 
                  value={formValues.serviceFee} 
                  id="serviceFee"
                  required 
                  onIonChange={(e:any) => changeValue('serviceFee', e.target.value)}

                />
              </IonItem>
              <IonItem color="dark">
                <IonLabel className="ion-margin-vertical">Would you like to allow tipping?</IonLabel>
                <IonToggle 
                  checked={!!formValues.allowTipping} 
                  id="allowTipping" 
                  color="tertiary"
                  onIonChange={(e: any) => changeValue('allowTipping', e.target.checked)}
                />
              </IonItem>
            </IonList>
            <br />

          </IonCardContent>
        </IonCard>
        {loading && <IonProgressBar type="indeterminate" />}
        {!loading && <IonButton type="submit" className="ion-no-margin" expand="block">Done</IonButton>}
      </form>
    </React.Fragment>
  )
}

export default BusinessAccountInfo
