import React, { useEffect, useState } from 'react'
import { IonProgressBar } from '@ionic/react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useAuthStore } from '../../store/AuthStore';
import { useParams, Redirect, useHistory } from 'react-router';

interface Props {
  children: any;
}

const InviteWrapper: React.FC<Props> = (props) => {
  const { user } = useAuthStore((store) => store);
  let params: any = useParams();
  let history: any = useHistory();
  const [invite, setInvite] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const inviteId = params.inviteId;
      const inviteDoc = await firebase.firestore().collection('invites').doc(inviteId).get();
      if (inviteDoc.exists) {
        setInvite({
          id: inviteId,
          ...inviteDoc.data()
        });
        history.push(`/profile/${inviteDoc.data().merchant_id}/pay`)

      }
      setLoading(false);
    }

    init();
  }, [params.inviteId, user, history]);

  // async function signOut() {
  //   await firebase.auth().signOut();
  //   window.location.href = '/';
  // }

  // async function acceptInvite() {
  //   try {
  //     const existingTab = await firebase.firestore().collection('tabs')
  //       .doc(`${invite.merchant_id}${user.uid}`).get();

  //     if (!existingTab.exists) {
  //       await firebase.firestore().collection('tabs').doc(`${invite.merchant_id}${user.uid}`).set({
  //         merchant_id: invite.merchant_id,
  //         merchant_name: invite.merchant_name,
  //         merchant_phone: invite.merchant_phone,
  //         user_id: user.uid,
  //         user_display_name: user.displayName,
  //         user_phone: user.phoneNumber
  //       }, { merge: true });
  //     }

  //     setSuccess(true);

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }


  if (!loading && invite && invite.merchant_id) {
    return (
      <Redirect to={`/profile/${invite.merchant_id}/pay`} />
    );
  }

  return (
    <IonProgressBar type="indeterminate" />
  )


  // if (user && user.displayName && user.email) {

  //   if (loading) {
  //     return (
  //       <IonProgressBar type="indeterminate" />
  //     )
  //   }

  //   return (
  //     <IonPage>
  //       <IonContent className="ion-padding">
  //         <div style={{ maxWidth: 400, margin: 'auto' }}>
  //           <a href="/">
  //             <img
  //               style={{
  //                 maxWidth: 100,
  //                 margin: 'auto',
  //                 display: 'block',
  //               }}
  //               src="/assets/logo.svg"
  //               alt="Opel"
  //             />
  //           </a>
  //           <IonCard color="secondary" className="ion-margin-top">
  //             {(() => {
  //               if (success) {
  //                 return (
  //                   <React.Fragment>
  //                     <IonCardHeader>
  //                       <IonCardTitle>Invite Accepted</IonCardTitle>
  //                     </IonCardHeader>
  //                     <IonCardContent>
  //                       <IonText color="light">
  //                         Did you know that you can carry a balance with your favorite merchants?
  //                       </IonText>
  //                       <IonButton
  //                         className="ion-no-margin ion-margin-vertical"
  //                         type="submit"
  //                         expand="block"
  //                         onClick={() => history.replace(`/tabs/${invite.merchant_id}${user.uid}`)}
  //                       >
  //                         Go to tab
  //                       </IonButton>
  //                     </IonCardContent>
  //                   </React.Fragment>
  //                 )
  //               }

  //               return (
  //                 <React.Fragment>
  //                   <IonCardHeader className="ion-margin-start">
  //                     <IonCardTitle>Invite from: {invite.merchant_name}</IonCardTitle>
  //                   </IonCardHeader>
  //                   <IonCardContent>
  //                     <IonList className="ion-no-padding" lines="full">
  //                       <IonListHeader color="secondary">This allows you to:</IonListHeader>
  //                       <IonItem color="secondary">
  //                         <IonIcon icon={shieldCheckmarkOutline} slot="start" />
  //                         <IonLabel className="ion-text-wrap">
  //                           <h2>Order by sending a text message to {invite.merchant_name} via Opel</h2>
  //                         </IonLabel>
  //                       </IonItem>
  //                       <IonItem color="secondary">
  //                         <IonIcon icon={shieldCheckmarkOutline} slot="start" />
  //                         <IonLabel className="ion-text-wrap">
  //                           <h2>Pay {invite.merchant_name} via Apple Pay, Google Pay, or Credit Card</h2>
  //                         </IonLabel>
  //                       </IonItem>
  //                       <IonItem color="secondary">
  //                         <IonIcon icon={shieldCheckmarkOutline} slot="start" />
  //                         <IonLabel className="ion-text-wrap">
  //                           <h2>Carry a balance at {invite.merchant_name} for convenience.</h2>
  //                         </IonLabel>
  //                       </IonItem>
  //                     </IonList>
  //                     <IonButton
  //                       className="ion-no-margin ion-margin-vertical"
  //                       type="submit"
  //                       expand="block"
  //                       onClick={acceptInvite}
  //                     >
  //                       Accept
  //                     </IonButton>
  //                     <IonText color="medium">
  //                       By clicking accept, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a>. You may receive SMS Notifications from us and can opt out any time.
  //                     </IonText>
  //                   </IonCardContent>
  //                 </React.Fragment>
  //               )
  //             })()}
  //           </IonCard>

  //           {(() => {
  //             const user = firebase.auth().currentUser;
  //             if (!user) {
  //               return (
  //                 <IonGrid>
  //                   <IonRow className="ion-justify-content-center">
  //                     <IonCol size="auto">
  //                       <IonButton
  //                         routerLink="/login"
  //                         fill="clear"
  //                         color="dark"
  //                       >Log in</IonButton>
  //                     </IonCol>
  //                   </IonRow>
  //                 </IonGrid>
  //               )
  //             }
  //           })()}
  //           {(() => {
  //             const user = firebase.auth().currentUser;
  //             if (user && user.email && user.phoneNumber && user.email) {
  //               return (
  //                 <IonGrid>
  //                   <IonRow className="ion-justify-content-center">
  //                     <IonCol size="auto">
  //                       <IonButton
  //                         onClick={() => window.location.href = '/'}
  //                         fill="clear"
  //                         color="dark"
  //                       >Go Home</IonButton>
  //                     </IonCol>
  //                   </IonRow>
  //                 </IonGrid>
  //               )
  //             }
  //           })()}
  //           {(firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous) && <IonGrid>
  //             <IonRow className="ion-justify-content-center">
  //               <IonCol size="auto">
  //                 <IonButton
  //                   onClick={signOut}
  //                   fill="clear"
  //                   color="dark"
  //                 >Sign out</IonButton>
  //               </IonCol>
  //             </IonRow>
  //           </IonGrid>}
  //         </div>
  //       </IonContent>
  //     </IonPage>
  //   )
  // }

  // return props.children;

}

export default InviteWrapper
