import React from 'react';
import { IonPage, IonContent, IonRow, IonGrid, IonCol, IonButton } from '@ionic/react';

import firebase from 'firebase/app';
import 'firebase/auth';
import Signin from './Signin';

const NoMatch = () => {

  if(!firebase.auth().currentUser) {
    return <Signin noRedirectSuccess={true} />
  }

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="4">
              <h1>404 - Page not found</h1>
              <h5>Oops! Something isn't right. We couldn't find that url.</h5>
              <br />
              <IonButton expand="block" href="/">Go Home</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default NoMatch
