import React, { useState } from 'react';
import { IonCard, IonToast, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonInput, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import isUndefined from 'lodash/isUndefined';
import { useEffect } from 'react';


interface Props {
  options: any;
  calculateQuickSelectOptions?: any;
  title: string;
  onNext: any;
  defaultValue: any;
  minimumAmount?: number;
}



const AmountQuickSelect = (props: Props) => {
  const [formError, setFormError] = useState<any>({
    isOpen: false,
    message: ''
  });
  const [amount, setAmount] = useState<any>({
    formatted: '',
    unformatted: 0,
    selectedText: ''
  })

  useEffect(() => {
    setAmount(props.defaultValue);
  }, [props.defaultValue])

  function handleCustomAmount(eventValue: any, selectedText?: any) {
    let removedPrefix = eventValue.split('$');
    if (removedPrefix.length === 1) {
      const newValue = `$${eventValue}`;
      removedPrefix = newValue.split('$');
    }
    const newAmount: any = Number(removedPrefix[1]);
    setAmount({
      formatted: `$${removedPrefix[1]}`,
      unformatted: (newAmount * 100),
      selectedText: selectedText
    })
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if(props.minimumAmount && (amount.unformatted < props.minimumAmount)) {
      setFormError(() => ({
        isOpen: true,
        message: `The amount must be greater than $${(props.minimumAmount / 100).toFixed(2)}.`
      }));
      return;
    }

    if (isNaN(amount.unformatted) || isUndefined (amount.unformatted)) {
      setFormError(() => ({
        isOpen: true,
        message: "The amount is badly formatted "
      }));
      return;
    }

    return props.onNext(amount);

  }

  function optionAction(option:any) {
    let optionAmount = option.amount
    if(props.calculateQuickSelectOptions) {
      optionAmount = props.calculateQuickSelectOptions(optionAmount);
    }
    handleCustomAmount(optionAmount, option.text)
  }

  return (
    <form onSubmit={onSubmit}>
      <IonToast
        isOpen={formError.isOpen}
        onDidDismiss={() => setFormError(false)}
        message={formError.message}
        position="top"
        color="danger"
        duration={4000}
      />
      <IonCard color="secondary" className="ion-no-margin">
        <IonCardHeader>
          <IonCardTitle>{props.title}</IonCardTitle>
        </IonCardHeader>
        {/* <IonCardContent> */}

        <IonItem color="dark">
          <IonLabel position="floating">Amount:</IonLabel>
          <IonInput
            placeholder="Type or select an amount"
            value={amount.formatted}
            required
            onIonChange={(e: any) => handleCustomAmount(e.target.value, amount.selectedText)}
          />
        </IonItem>
        <br />
        <IonGrid>
          <IonRow>
            {props.options.map((option: any, index: any) => (
              <IonCol key={index} size={`${12 / (props.options.length)}`}>
                <IonButton
                  onClick={() => optionAction(option)}
                  color={'tertiary'}
                  fill={(option.text === amount.selectedText) ? 'solid' : 'outline'}
                  expand="block"
                >{option.text}</IonButton>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <br />

        {/* </IonCardContent> */}
      </IonCard>
      <br />
      <IonButton className="ion-no-margin" expand="block" type="submit">Next</IonButton>
    </form>
  )
}

export default AmountQuickSelect
