import React, { useEffect, useState } from 'react'
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonInput, IonCard, IonCardContent, IonToast, IonText, IonGrid, IonCol, IonRow, IonButton} from '@ionic/react';
import firebase from 'firebase/app';
import 'firebase/auth';

interface Props {

}

const SignupMerchant: React.FC<Props> = () => {
  const [error, setError] = useState<any>();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if(user && user.displayName) {
      setDisplayName(user.displayName);
    }
    if(user && user.email) {
      setEmail(user.email);
    }
  }, []);

  async function signUpWithEmail(e: any) {
    try {
      e.preventDefault();
      e.stopPropagation();
      let user = firebase.auth().currentUser;
      if(!user) {
        await firebase.auth().signInAnonymously();
        user = firebase.auth().currentUser;
      }

      console.log(user);
      await user.updateProfile({displayName: displayName});

      var credential = firebase.auth.EmailAuthProvider.credential(email, password);

      const result = await firebase.auth().currentUser.linkWithCredential(credential);

      if(!!result) {
        window.location.href = '/';
      }
      
    } catch (error) {
      const user = firebase.auth().currentUser;
      setError(error);
      if(user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }
  }

  async function signOut() {
    await firebase.auth().signOut();
    window.location.href = '/';
  }

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div style={{ maxWidth: 400, margin: 'auto' }}>
          <a href="/">
            <img
              style={{
                maxWidth: 100,
                margin: 'auto',
                display: 'block',
              }}
              src="/assets/logo.svg"
              alt="Opel"
            />
          </a>
          <h2 className="ion-padding-horizontal ion-padding-bottom">
            {(() => {
              const user = firebase.auth().currentUser;
              if(!user || (user && user.isAnonymous)) {
                return `Sign up as a business`;
              }

              return `Edit Info`;
            })()}
          </h2>
          <IonToast
            position="top"
            isOpen={!!error}
            onDidDismiss={() => setError(undefined)}
            message={error ? error.message : '' }
            duration={4000}
          />
          <form onSubmit={signUpWithEmail}>
            <IonCard color="secondary">
              <IonCardContent>
                <IonList className="ion-no-padding">
                  <IonItem color="secondary">
                    <IonLabel position="floating">
                      Your personal name:*
                    </IonLabel>
                    <IonInput 
                      required
                      type="text"
                      name="name"
                      value={displayName}
                      onIonChange={(e: any) => setDisplayName(e.target.value)}
                    />
                  </IonItem>
                  <IonItem color="secondary">
                    <IonLabel position="floating">
                      Work email:*
                    </IonLabel>
                    <IonInput 
                      required
                      type="email"
                      value={email}
                      onIonChange={(e: any) => setEmail(e.target.value)}
                    />
                  </IonItem>
                  <IonItem color="secondary">
                    <IonLabel position="floating">
                      Password:*
                    </IonLabel>
                    <IonInput
                      required
                      type="password"
                      value={password}
                      onIonChange={(e: any) => setPassword(e.target.value)}
                    />
                  </IonItem>
                </IonList>
                <div className="ion-padding">
                  <IonText color="medium">
                  By clicking Sign Up, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.
                  </IonText>
                </div>
                <IonButton type="submit" expand="block">
                  {(() => {
                    const user = firebase.auth().currentUser;
                    if(!user || (user && user.isAnonymous)) {
                      return `Sign Up`;
                    }

                    return `Done`;
                  })()}
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
          {(() => {
            const user = firebase.auth().currentUser;
            if (!user) {
              return (
                <IonGrid>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="auto">
                      <IonButton
                        routerLink="/login"
                        fill="clear"
                        color="dark"
                      >Log in</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )
            }
          })()}
          {(() => {
            const user = firebase.auth().currentUser;
            if (user && user.email && user.phoneNumber && user.email) {
              return (
                <IonGrid>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="auto">
                      <IonButton
                        onClick={() => window.location.href = '/'}
                        fill="clear"
                        color="dark"
                      >Go Home</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )
            }
          })()}
          {(firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous) && <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">
                <IonButton
                  onClick={signOut}
                  fill="clear"
                  color="dark"
                >Sign out</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SignupMerchant
