import React, { useState } from 'react'
import { IonPage, IonProgressBar } from '@ionic/react'

import BusinessProfileWrapper from '../../components/BusinessProfileWrapper'
import { useParams } from 'react-router'
import firebase from 'firebase/app';
import 'firebase/auth';
import { useEffect } from 'react';
import CommunityFeed from './CommunityFeed';
import { useAuthStore } from '../../store/AuthStore';
import CustomerTextStart from '../../components/CustomerTextStart';
import RegisterUserComponent from '../../components/RegisterUserComponent';

interface Props {

}

const BusinessProfileActivity = (props: Props) => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [businessInfo, setBusinessInfo] = useState({});
  const {merchant} = useAuthStore((store) => store);

  useEffect(() => {
    if (id) {
      firebase
        .firestore()
        .collection("merchants")
        .doc(id)
        .get()
        .then((doc) => {
          setBusinessInfo({
            id: doc.id,
            ...doc.data(),
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [id]);


  if (loading) {
    return <IonProgressBar type="indeterminate" />
  }

  return (
    <IonPage>
      <BusinessProfileWrapper
        businessInfo={businessInfo}
        loading={loading}
        isMerchant={!!merchant}
      >
        <CustomerTextStart 
          businessInfo={businessInfo}
        />
        {(() => {
          if (!firebase.auth().currentUser || !firebase.auth().currentUser.displayName || !firebase.auth().currentUser.email) {
            return (
              <div className="ion-padding">
                <RegisterUserComponent />
              </div>
            );
          }

          return <div style={{padding: '0 10px'}}>
            <CommunityFeed isMerchant={(merchant && merchant.merchant_id === id)} id={id} profileId={id} />
          </div>
        })()}
      </BusinessProfileWrapper>

    </IonPage>
  )
}

export default BusinessProfileActivity
