export default {
  id: 'lineItems',
  name: 'Line Items',
  singular_name: 'Item',
  fields: [
    {
      displayName: 'Name',
      id: 'name',
      type: 'text'
    },
    {
      displayName: 'Quantity',
      id: 'qty',
      type: 'number'
    },
    {
      displayName: 'Price',
      id: 'price',
      type: 'dollar'
    }
  ]
}