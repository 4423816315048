import React, {useEffect} from 'react'
import { useAuthStore } from './store/AuthStore';

interface Props {
  children: any;
}

declare global {
  interface Window {
    analytics: any;
    Beacon: any;
  }
}

declare var Beacon: any;

const Identity: React.FC<Props> = (props) => {
  const {user, merchant, userType} = useAuthStore((store) => store);

  useEffect(() => {
    if(userType === 'regular' && !!user && !!user.email && !!user.displayName) {
      window.analytics.identify(user.uid, {
        email: user.email,
        phone: user.phoneNumber,
        name: user.displayName,
        id: user.uid
      });
      
      Beacon('identify', {
        email: user.email,
        phone: user.phoneNumber,
        name: user.displayName,
        user_id: user.uid,
      })
    }
  }, [user, userType])

  useEffect(() => {
    if(merchant && userType === 'merchant' && !!user && !!user.email && !!user.displayName) {
      window.analytics.identify(user.uid, {
        email: user.email,
        phone: user.phoneNumber,
        name: user.displayName,
        id: user.uid,
        company: {
          name: merchant.business_name,
          id: merchant.merchant_id
        }
      });

      window.analytics.group(merchant.merchant_id, {
        name: merchant.business_name,
        phone: merchant.merchant_phone,
        id: merchant.merchant_id,
        email: user.email
      });
      Beacon('identify', {
        name: user.displayName,
        email: user.email,
        company: merchant.business_name,
        phone: merchant.merchant_phone,
        user_id: user.uid,
        merchant_id: merchant.merchant_id,
        merchant: true
      })
    }
  }, [merchant, user, userType])

  useEffect(() => {
    Beacon('init', 'e4327e2d-7ced-4b78-a35e-8e979d0a4b18');
  }, [])

  return props.children;
}

export default Identity
