import React, { FormEvent, useState } from 'react'
import { IonPage, IonContent, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonText, IonToast, IonProgressBar, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, IonHeader, IonToolbar } from '@ionic/react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router'
import querystring from 'querystring';
import trim from 'lodash/trim';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import StripePayment from '../../components/payments/StripePayment';
import { useAuthStore } from '../../store/AuthStore';
import BusinessAccountInfo from '../../components/BusinessAccountInfo';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

interface Props {

}

declare var window: any;

const SignupBusiness = (props: Props) => {
  let location = useLocation();
  let history = useHistory();
  let squareUrl = `https://${process.env.REACT_APP_SQUARE_DOMAIN}.com/oauth2/authorize?client_id=${process.env.REACT_APP_SQUARE_APPLICATION_ID}`;
  squareUrl += `&scope=MERCHANT_PROFILE_READ,PAYMENTS_WRITE,PAYMENTS_READ,PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS,ORDERS_READ,ORDERS_WRITE,ITEMS_READ`
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: ''
  });
  const [loading, setLoading] = useState(false);
  const { user, merchant } = useAuthStore((store) => store);

  async function onCreateSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    const email: any = document.getElementById('email');
    const password: any = document.getElementById('password');
    try {
      await firebase.auth().createUserWithEmailAndPassword(email.value, password.value);
      window.location.reload();
    } catch (error) {
      setToast({
        isOpen: true,
        message: error.message,
        color: 'danger'
      })
    }
  }

  useEffect(() => {
    async function paramsAuth(params: any) {
      try {
        setLoading(true);
        const oauthSquare = firebase.functions().httpsCallable('oauthSquare');
        const result: any = await oauthSquare(params);
        if (result.error) {
          return;
        }
        setToast({
          isOpen: true,
          message: 'Successfully connected square account',
          color: 'primary'
        })
        window.location.reload();
      } catch (error) {
        setLoading(false);
        setToast({
          isOpen: true,
          message: 'Something went wrong. Try again.',
          color: 'danger'
        })
      }
    }


    const params = querystring.parse(
      trim(location.search, '?')
    );

    if ((!merchant || !merchant.exists) && params && params.code) {
      paramsAuth(params)
    }
  }, [location, history, merchant]);

  // async function onPaymentSuccess(paymentMethod: any) {
  //   const newSubscription = firebase.functions().httpsCallable('newSubscription');
  //   setLoading(true);
  //   try {
  //     await newSubscription({
  //       merchant_id: merchant.id,
  //       payment_method: paymentMethod,
  //       email: firebase.auth().currentUser.email
  //     });
  //     setToast({
  //       isOpen: true,
  //       message: 'Successfully connected square account',
  //       color: 'primary'
  //     })
  //     window.location.search = ""
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     setToast({
  //       isOpen: true,
  //       message: 'Something went wrong. Try again.',
  //       color: 'danger'
  //     })
  //   }
  // }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonButton color="secondary" className="ion-no-margin" fill="clear" onClick={() => window.location = "sms:+13344599901"}>Have a question? Text us: +1 (334) 459-9901</IonButton>  
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({
            isOpen: false,
            color: '',
            message: ''
          })}
          message={toast.message}
          color={toast.color}
          duration={4500}
        />
        <div style={{ maxWidth: 500, margin: 'auto' }}>
         <br />
          <a href="/">
            <img
              style={{
                maxWidth: 100,
                margin: 'auto',
                display: 'block',
              }}
              src="/assets/logo.svg"
              alt="Opel"
            />
          </a>
          <br />
          {(() => {
            if (loading) {
              return <IonProgressBar type="indeterminate" />
            }

            // if ((merchant && merchant.exists && !merchant.stripe_customer_id) || (user && user.claims && user.claims.billing_past_due)) {
            //   return (
            //     <React.Fragment>
            //       <h1 className="ion-text-center">Subscribe to Opel:</h1>
            //       <IonCard color="secondary">
            //         <IonCardHeader>
            //           <IonCardTitle>$30 per month</IonCardTitle>
            //           <IonCardSubtitle>A recurring charge every month.</IonCardSubtitle>
            //         </IonCardHeader>
            //         <IonCardContent>
            //           {/* <IonList className="ion-no-padding">
            //             <IonItem color="secondary">
            //               <IonLabel>A local phone number</IonLabel>
            //               <IonIcon color="primary" slot="start" icon={checkbox} />
            //             </IonItem>
            //             <IonItem color="secondary">
            //               <IonLabel>Text all subscribers at once.</IonLabel>
            //               <IonIcon color="primary" slot="start" icon={checkbox} />
            //             </IonItem>
            //             <IonItem color="secondary">
            //               <IonLabel>Forward phone calls to any US phone.</IonLabel>
            //               <IonIcon color="primary" slot="start" icon={checkbox} />
            //             </IonItem>
            //             <IonListHeader color="secondary">Billing Info:</IonListHeader>
            //           </IonList> */}
            //           <Elements stripe={stripePromise}>
            //             <StripePayment onSuccess={onPaymentSuccess} />
            //           </Elements>
            //         </IonCardContent>
            //       </IonCard>
            //     </React.Fragment>
            //   )
            // }

            if (user && merchant && merchant.exists && merchant.merchant_id) {
              return (
                <BusinessAccountInfo />
              )
            }

            if (firebase.auth().currentUser && (!merchant || !merchant.exists)) {
              return (
                <React.Fragment>
                  <h1 className="ion-text-center">Payment Integration:</h1>
                  <IonCard color="secondary">
                    <IonCardHeader>
                      <IonCardTitle>Choose an option</IonCardTitle>
                      <IonCardSubtitle>Opel currently only works with Square.</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem href={squareUrl} color="dark" detail>
                        <IonLabel className="ion-text-wrap">
                          <h2>Connect Square Account</h2>
                          <p>Opel works with square to process payments securely. If you don't have a square account you'll be prompted to create one.</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none" color="secondary" />
                      <IonItem color="dark">
                        <IonLabel className="ion-text-wrap">
                          <h2>More options coming soon</h2>
                          <p>We are working quickly on other payment options to help people get started as fast as possible</p>
                        </IonLabel>

                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </React.Fragment>
              )
            }

            if (!firebase.auth().currentUser) {
              return (
                <React.Fragment>
                  <h1 className="ion-text-center">Sign up as a business</h1>
                  <IonCard color="secondary" className="ion-no-margin ion-margin-top">
                    <form onSubmit={onCreateSubmit}>
                      <IonCardContent>
                        <IonList className="ion-no-padding">
                          <IonItem color="dark">
                            <IonLabel position="floating">Work email</IonLabel>
                            <IonInput id="email" type="email" required />
                          </IonItem>
                          <IonItem color="dark">
                            <IonLabel position="floating">Password</IonLabel>
                            <IonInput id="password" type="password" required />
                          </IonItem>
                        </IonList>
                        <div className="ion-padding">
                          <IonText color="medium">
                            By clicking Sign Up, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.
                          </IonText>
                        </div>
                        <IonButton type="submit" color="primary" expand="block">Sign up</IonButton>
                        <div className="ion-padding">
                          <IonText color="light">
                            Already have an account? <a href="/login">Log in</a>.
                          </IonText>
                        </div>
                      </IonCardContent>
                    </form>
                  </IonCard>
                </React.Fragment>
              )
            }


            return (
              <IonProgressBar type="indeterminate" />
            )

          })()}


        </div>
      </IonContent>
    </IonPage>
  )

}

export default SignupBusiness
