export default {
  id: 'transfers',
  name: 'Transfers',
  singular_name: 'Transfer',
  fields: [
    {
      displayName: 'Business',
      id: 'merchant_name',
      type: 'text'
    },
    {
      displayName: 'Type',
      id: 'payment_type',
      type: 'text'
    },
    {
      displayName: 'Status',
      id: 'status',
      type: 'status'
    },
    {
      displayName: 'Date',
      id: 'created_at',
      type: 'date'
    },
    {
      displayName: 'Store Credit',
      id: 'transfer_amount',
      type: 'dollar'
    },
    {
      displayName: 'Tip',
      id: 'tip_amount',
      type: 'dollar'
    },
    {
      displayName: 'Service Fee',
      id: 'opel_fee',
      type: 'dollar'
    },
    {
      displayName: "Tax",
      id: 'tax',
      type: 'dollar'
    },
    {
      displayName: 'Total',
      id: 'total',
      type: 'dollar'
    },
    {
      displayName: 'Receipt',
      id: 'receipt',
      type: 'receipt'
    }
  ]
}