import React from 'react'
import {IonList, IonItem} from '@ionic/react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import PageWrapper from '../../../components/PageWrapper';
import { useAuthStore } from '../../../store/AuthStore';

declare var Beacon: any;

interface Props {
  history: any;
}

const Settings: React.FC<Props> = () => {
  const {signOut} = useAuthStore((store) => store);

  return (
    <PageWrapper title="Settings">
      <IonList lines="full">
        <IonItem>
          {firebase.auth().currentUser.email}
        </IonItem>
        <IonItem button onClick={() => {
          Beacon('open')
        }}>
          Support
        </IonItem>
        <IonItem button onClick={() => window.location.reload()}>
          Update
        </IonItem>
        <IonItem button onClick={() => {
          signOut();
        }}>
          Signout
        </IonItem>
      </IonList>
    </PageWrapper>
  )
}

export default Settings;