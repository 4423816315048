import React, { useState, useEffect } from 'react'
import { IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonTextarea } from '@ionic/react'
import { person } from 'ionicons/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { FormatData } from './FormatData';

interface Props {
  chat: any;
}

const ChatInfoDetail = (props: Props) => {
  const { chat } = props;
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState('');

  function updateNotes() {
    if (chat && chat.tab_id && notes) {
      firebase.firestore().collection('notes').doc(chat.merchant_id).collection('items').doc(chat.tab_id).set({
        notes: notes,
        merchant_id: chat.merchant_id,
        user_id: chat.user_id
      }, { merge: true });
    }
    setOpen(false);
  }

  useEffect(() => {
    function handleDocChange(doc: any) {
      if (doc.exists && doc.data() && doc.data().notes) {
        setNotes(doc.data().notes);
      }
    }

    if (chat && chat.id && chat.merchant_id) {
      firebase.firestore().collection('notes').doc(chat.merchant_id).collection('items').doc(chat.id).onSnapshot(handleDocChange);
    }
  }, [chat])

  return (
    <React.Fragment>
      <IonButton fill="clear" color="secondary" onClick={() => setOpen(true)}>
        <FormatData 
          type="text" 
          value={() => (
            <React.Fragment>
              {chat.user_display_name}
              <IonIcon slot="start" icon={person} />
            </React.Fragment>
          )}
        />
      </IonButton>
      <IonModal isOpen={open} onDidDismiss={() => setOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Name: {chat.user_display_name}</IonTitle>
              <IonButton slot="end" onClick={() => setOpen(false)} fill="clear" color="secondary">Close</IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="ion-margin">
              <IonList>
                <IonItem>
                  <IonLabel position="floating">Notes:</IonLabel>
                  <IonTextarea value={notes} onIonChange={(e: any) => setNotes(e.target.value)} />
                </IonItem>
              </IonList>
              <IonButton onClick={updateNotes} color="primary" expand="block">Done</IonButton>
            </div>
          </IonContent>
        </IonModal>
    </React.Fragment>
  )
}

export default ChatInfoDetail
