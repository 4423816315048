import React, { useState, useEffect } from 'react'
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { IonSkeletonText } from '@ionic/react';
import ReactTextFormat from 'react-text-format';


export const FormatData = (props) => {
  const {type, value, loading} = props;
  const [formattedValue, setFormattedValue] = useState(undefined);
  const [skeleton, setSkeleton] = useState(true);

  useEffect(() => {
    if(loading) {
      setSkeleton(true);
      return;
    }
    switch (type) {
      case 'dollar':
        if((isUndefined(value) || isNull(value))) {
          setFormattedValue(0);
          setSkeleton(false);
        } else {
          setSkeleton(false);
          setFormattedValue(value);
        }
        break;
      case 'date':
        if(value && value.toDate) {
          setFormattedValue(value.toDate());
          setSkeleton(false);
        } else {
          setFormattedValue(value);
          setSkeleton(false);
        }
        break;
      default:
        setSkeleton(false);
        setFormattedValue(value);
        break;
    }
  }, [value, type, loading])

  if(skeleton) {
    return <IonSkeletonText />
  }

  return (
    <React.Fragment>
      {(() => {
        switch (type) {
          case 'date':
            return (
              <React.Fragment>
                <Moment format="MM/DD/YYYY @ h:mma " fromNow fromNowDuring={86400000} date={formattedValue} />
              </React.Fragment>
            )
          case 'dollar':
            return (
              <React.Fragment>
                <NumberFormat value={(Number(formattedValue) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              </React.Fragment>
            )
          case 'array':
            return (
              <React.Fragment />
            )
          case 'status':
            return (
              <span style={{textTransform: 'capitalize'}}>{formattedValue}</span>
            )
          case 'text':
            return (
              <ReactTextFormat
                allowedFormats={['URL', 'Email', 'Image']}
                terms={["Link", "phone", "image", "Anchor", "email", "Credit"]}
                linkTarget="_blank"

              >
                {formattedValue}
              </ReactTextFormat>
            )
          case 'number':
          default:
            return <React.Fragment>{formattedValue}</React.Fragment>
        }
      })()}
    </React.Fragment>
  )
}
