import React, {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { IonList, IonListHeader, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { add, checkmark } from 'ionicons/icons';
import find from 'lodash/find';
import FuzzySearch from 'fuzzy-search';
import { FormatData } from '../../../components/FormatData';

interface Props {
  searchValue: String;
  tabsList: any;
}

declare var navigator:any;

const MerchantSearch: React.FC<Props> = (props) => {
  const { searchValue } = props;
  const [merchantList, setMerchantList] = useState([]);
  const [searchIndex, setSearchIndex] = useState(undefined);

  useEffect(() => {
    function handleData(query: any) {
      const items:any[] = [];
      query.forEach((doc: any) => {
        const singleItem = {
          id: doc.id,
          ...doc.data(), 
        }
        const tabInfo = find(props.tabsList, {merchant_id: doc.data().merchant_id});
        if(tabInfo) {
          singleItem.has_tab = true;
          singleItem.tab_info = tabInfo;
        }
        items.push(singleItem);
      });
      setMerchantList(items);
    }

    const unsub = firebase.firestore()
      .collection('merchants')
      .where('status', '==', 'ACTIVE')
      .onSnapshot(handleData);

    return () => {
      if(unsub) {
        unsub();
      }
    };
  }, [props.tabsList]);

  useEffect(() => {
    const searcher = new FuzzySearch(merchantList, ['business_name']);
    setSearchIndex(searcher);
  }, [merchantList])

  async function inviteMerchant() {
    if(navigator && navigator.share) {
      
      return navigator.share({
        title: `Using Opel at your business`,
        text: `I'd love to use Opel at your business. It allows regulars to pay ahead and donate money. Could you sign up?`,
        url: `https://opel.org/m`
      }).then(() => {
        console.log('thanks for sharing');
      }).catch(() => {
        console.log('oh no!');
      })
    }
  }

  return (
    <IonList>
      {(navigator && navigator.share) &&<IonItem color="primary" detail onClick={inviteMerchant}>
        <IonLabel className="ion-text-wrap">Don't see your favorite spot? <br /> Invite them!</IonLabel>
      </IonItem>}
      <IonListHeader>Results:</IonListHeader>
        {searchIndex && <React.Fragment>
          {searchIndex.search(searchValue).map((item: any, key: any) => (
            <IonItem 
              key={key}
              button
              detail={(item.has_tab) ? true : false} 
              routerLink={`/profile/${item.merchant_id}`}

            >
              {item.has_tab && <IonIcon size="large" icon={checkmark} slot="start" />}
              <IonLabel>
                <h2>{item.business_name}</h2>
                {(item.has_tab && item.tab_info && item.tab_info.balance) && <h3>
                  <FormatData value={item.tab_info.balance} type={'dollar'} />
                </h3>}
                <p>{item.description}</p>
              </IonLabel>
              {!item.has_tab && <IonIcon size="large" icon={add} slot="end" />}
            </IonItem>
          ))}
        </React.Fragment>}
      </IonList>
  )
}

export default MerchantSearch
