import React from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { useEffect } from 'react';
import { useAuthStore } from '../store/AuthStore';


interface Props {
  children: any;
}

const MerchantPresenceWrapper = (props: Props) => {
  const { merchant } = useAuthStore((store) => store);

  useEffect(() => {
    function rtdbAndLocalFsPresence() {
      // [START rtdb_and_local_fs_presence]
      // [START_EXCLUDE]
      var merchantStatusDatabaseRef = firebase.database().ref('/merchant-status/' + firebase.auth().currentUser.uid);
  
      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        merchant_id: merchant.merchant_id
      };
  
      var isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        merchant_id: merchant.merchant_id
      };
  
      // [END_EXCLUDE]
      var merchantStatusFirestoreRef = firebase.firestore().doc('/merchant-status/' + firebase.auth().currentUser.uid);
  
      // Firestore uses a different server timestamp value, so we'll 
      // create two more constants for Firestore state.
      var isOfflineForFirestore = {
        state: 'offline',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
        merchant_id: merchant.merchant_id
      };
  
      var isOnlineForFirestore = {
        state: 'online',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
        merchant_id: merchant.merchant_id
      };
  
      firebase.database().ref('.info/connected').on('value', function (snapshot) {
        if (snapshot.val() === false) {
          // Instead of simply returning, we'll also set Firestore's state
          // to 'offline'. This ensures that our Firestore cache is aware
          // of the switch to 'offline.'
          merchantStatusFirestoreRef.set(isOfflineForFirestore);
          return;
        };
  
        merchantStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          merchantStatusDatabaseRef.set(isOnlineForDatabase);
  
          // We'll also add Firestore set here for when we come online.
          merchantStatusFirestoreRef.set(isOnlineForFirestore);
        });
      });
      // [END rtdb_and_local_fs_presence]
    }
    
    if(merchant && merchant.merchant_id) {
      rtdbAndLocalFsPresence();
    }
  }, [merchant])


  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default MerchantPresenceWrapper
