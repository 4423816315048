import React from 'react'
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent } from '@ionic/react'

interface Props {
  children: any;
  isOpen: boolean;
  closeModal: any;
  title: string;
  animated?: boolean;
}

export const Modal: React.FC<Props> = (props) => {
  return (
    <IonModal animated={props.animated} isOpen={props.isOpen} >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.closeModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {props.children}
      </IonContent>
    </IonModal>
  )
}
