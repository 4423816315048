import React from 'react';
import {IonList, IonListHeader, IonItem, IonNote, IonLabel} from '@ionic/react';
import {FormatData} from './FormatData';

interface Props {
  data: any;
  headerTitle?: string;
  selectedId?: string;
}

const ListView: React.FC<Props> = ({data, headerTitle, selectedId}) => {
  return (
    <IonList>
      {headerTitle && <IonListHeader>
        {headerTitle}
      </IonListHeader>}
      {data.map((item: any, key: any) => {
        let action = (item: any) => {};
        if(item.action) {
          action = item.action;
        }

        return (
          <IonItem
            color={item.id === selectedId ? `secondary` : ''}
            key={key}
            button
            onClick={() => action(item)}
            routerLink={(item.link) && item.link}
            routerDirection="none"
          >
            <IonLabel>
              <h2><FormatData type="text" value={item.primary} /></h2>
              <p><FormatData type="date" value={item.date} /></p>
            </IonLabel>
            <IonNote slot="end" color="dark">
              <FormatData type="dollar" value={item.secondary} />
            </IonNote>
          </IonItem>
        )
      })}
      {data.length === 0 && <IonItem>
        <IonLabel>No items found.</IonLabel>
      </IonItem>}
    </IonList>
  )
}

export default ListView
