import React from 'react'
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';

interface Props {
  routerLink?: string;
  subtitle?: any;
  title: any; 
  content?: any;
  action?: any;
  headerAction?: any;
  color?: any;
  disabled?: boolean;
  className?: string;
  button?: boolean;
  href?: string;
  id?: string;
  onClick?: any;
  children?: any;
}


const TabCard: React.FC<Props> = (props) => {
  return (
    <IonCard 
      href={props.href} 
      button={props.button} 
      className={props.className} 
      color={props.color ? props.color : 'dark'} 
      disabled={props.disabled} 
      routerLink={props.routerLink}
      id={props.id}
      onClick={props.onClick}
    >
      {(() => {
        if(props.headerAction) {
          return (
            <IonCardHeader>
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="6" className="ion-no-padding">
                    {props.subtitle && <IonCardSubtitle>{props.subtitle}</IonCardSubtitle>}
                    <IonCardTitle
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {props.title}
                    </IonCardTitle>
                  </IonCol>
                  <IonCol size="6" className="ion-no-padding">
                    {props.headerAction}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          )
        }

        return (
          <IonCardHeader className="ion-no-padding ion-padding-vertical ion-padding-horizontal">
            {props.subtitle && <IonCardSubtitle>{props.subtitle}</IonCardSubtitle>}
            <IonCardTitle
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {props.title}
            </IonCardTitle>
          </IonCardHeader>
        )
      })()}
      {(props.content || props.action || props.children) && <IonCardContent className="ion-no-padding ion-padding-bottom ion-padding-horizontal">
        {props.content && <h3>{props.content}</h3>}
        {props.children}
        {props.action && <div style={{marginTop: 10}}>{props.action}</div>}
      </IonCardContent>}
    </IonCard>
  )
}

export default TabCard
