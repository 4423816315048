import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { IonIcon, IonButton, IonItem, IonLabel, IonContent, IonToolbar, IonTextarea, IonGrid, IonRow, IonCol, IonNote, IonPage, IonFooter, IonProgressBar, useIonViewWillEnter, useIonViewWillLeave, useIonViewDidEnter, IonList, IonText, IonAvatar } from '@ionic/react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import md5 from 'md5';
import { FormatData } from '../../../components/FormatData';
import { useParams } from 'react-router';
import isUndefined from 'lodash/isUndefined';
import { sendOutline } from 'ionicons/icons';
import MerchantHeader from '../../../components/MerchantHeader';
import SecondaryTabInfo from '../../../components/SecondaryInfoTab';
import { ChatStore } from '../../../store/ChatStore';
import ChatInfoDetail from '../../../components/ChatInfoDetail';


interface Props {

}

const ChatDetailPage: React.FC<Props> = () => {
  const chatRef = useRef<any>(null);
  const { chatId } = useParams();
  const [messageList, setMessageList] = useState([]);
  const [chat, setChat] = useState<any>({});
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [chatDetailId, setChatDetailId] = useState('');
  const [chatListLimit, setChatListLimit] = useState(10);
  const [isViewing, setIsViewing] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  async function sendMessage() {
    const messageBox: any = document.getElementById('messageBox');
    const element = await messageBox.getInputElement()

    if (element.value.length < 1) {
      return;
    }
    setSubmitDisabled(true);

    const newMessage = {
      message: element.value,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      merchant_id: chat.merchant_id,
      user_display_name: chat.user_display_name,
      user_id: chat.user_id,
      to: chat.user_phone,
      from: chat.merchant_phone,
      merchant_name: chat.merchant_name,
      type: 'send'
    }

    setTimeout(() => {
      if (chatRef.current && chatRef.current.scrollToBottom) {
        chatRef.current.scrollToBottom();
      }
    }, 750);

    setValue('');
    element.value = '';
    await firebase.firestore().collection('chats')
      .doc(chatDetailId)
      .collection('items')
      .add(newMessage);
    setSubmitDisabled(false);
  }

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();

    sendMessage();
  }

  useEffect(() => {
    if (chatRef && chatRef.current && chatListLimit === 10) {
      setTimeout(() => {
        if (chatRef.current && chatRef.current.scrollToBottom) {
          chatRef.current.scrollToBottom();
        }
      }, 750);
    }
  }, [messageList, chatListLimit]);

  useEffect(() => {
    let items: any = [];
    function handleList(query: any) {
      items = [];
      query.forEach((doc: any) => {
        items.push({
          id: doc.id,
          ...doc.data()
        })
      });
      setMessageList(items);
      setLoading(false);
    }

    function handleDoc(doc: any) {
      setChat({
        id: doc.id,
        ...doc.data()
      })
      if (doc.data().status === 'open') {
        ChatStore.set({
          status: 'open'
        });
      }
      if (isViewing && (doc.data().unread > 0 || isUndefined(doc.data().unread))) {
        setTimeout(() => {
          if (chatRef.current && chatRef.current.scrollToBottom) {
            chatRef.current.scrollToBottom();
          }
        }, 750);
        firebase.firestore().collection('chats').doc(doc.id).update({ unread: 0 });
      }
    }
    if (chatDetailId !== '') {
      ChatStore.set({
        chatId: chatDetailId,
        secondaryMenuContent: <SecondaryTabInfo tabId={chatDetailId} />
      });
      setMessageList([]);
      setChat({});
      const subCollection = firebase.firestore().collection('chats')
        .doc(chatDetailId)
        .collection('items')
        .orderBy('created_at', 'asc')
        .limitToLast(chatListLimit)
        .onSnapshot(handleList);
      const subDoc = firebase.firestore().collection('chats')
        .doc(chatDetailId)
        .onSnapshot(handleDoc);

      return () => {
        ChatStore.set({
          chatId: '',
          secondaryMenuContent: null
        })
        setChat({});
        setMessageList([]);
        subCollection();
        subDoc();
      }
    }
  }, [chatDetailId, chatListLimit, isViewing, chatRef]);

  useIonViewWillEnter(() => {
    setChatDetailId(chatId);
    setChatListLimit(10);
    setIsViewing(true);
  }, [chatId]);

  useIonViewDidEnter(() => {
    firebase.firestore().collection('chats').doc(chatId).set({
      unread: 0
    }, { merge: true });
    setIsViewing(true);
    setChatDetailId(chatId);
  }, [chatId]);

  useIonViewWillLeave(() => {
    setIsViewing(false);
    setChatDetailId('');
    setChat({});
    setMessageList([]);
    setChatListLimit(10);
    ChatStore.set({
      chatId: '',
      secondaryMenuContent: null
    })
  })

  function loadmore() {
    setChatListLimit((prevLimit: any) => (prevLimit + 10));
  }


  return (
    <IonPage>
      <MerchantHeader
        title={
          <ChatInfoDetail chat={chat} />
        }
      />

      <IonContent ref={chatRef}>
        {(() => {
          if (loading) {
            return (
              <IonProgressBar type="indeterminate" />
            )
          }

          if (isUndefined(chatId)) {
            return (
              <p className="ion-text-center">Nothing to see here.</p>
            )
          }

          return (
            <IonGrid>
              <IonRow>
                {(messageList.length % 10 === 0 && messageList.length > 0) && <IonCol>
                  <IonButton expand="full" fill="clear" onClick={loadmore}>
                    Load More
                  </IonButton>
                </IonCol>}
                <IonCol size="12">
                  <IonList lines="none">
                    {messageList.map((chatSingle: any, key) => {
                      if (chatSingle.from === chat.merchant_phone) {
                        return (
                          <IonItem key={key} className="ion-justify-content-end">
                            <IonAvatar slot="end">
                              <img alt={(chatSingle.from === chat.merchant_phone) ? chat.merchant_name : chat.user_display_name} src={`https://www.gravatar.com/avatar/${md5(chatSingle.from)}?d=identicon`} />
                            </IonAvatar>
                            <IonLabel className="ion-text-wrap ion-text-end">
                              <IonText color="primary">
                                <b>
                                  {(chatSingle.from === chat.merchant_phone) ? chat.merchant_name : chat.user_display_name}
                                </b>
                              </IonText>
                              <h2>
                                <FormatData type="text" value={chatSingle.message} loading={loading} />
                              </h2>
                              <IonNote color="medium">
                                <FormatData type="date" value={chatSingle.created_at} loading={loading} />
                              </IonNote>
                            </IonLabel>
                          </IonItem>
                        )
                      }

                      return (
                        <IonItem key={key} className="ion-justify-content-start">
                            <IonAvatar slot="start">
                              <img alt={(chatSingle.from === chat.merchant_phone) ? chat.merchant_name : chat.user_display_name} src={`https://www.gravatar.com/avatar/${md5(chatSingle.from)}?d=identicon`} />
                            </IonAvatar>
                            <IonLabel className="ion-text-wrap ion-text-start">
                              <IonText color="primary">
                                <b>
                                  {(chatSingle.from === chat.merchant_phone) ? chat.merchant_name : chat.user_display_name}
                                </b>
                              </IonText>
                              <h2>
                                <FormatData type="text" value={chatSingle.message} loading={loading} />
                              </h2>
                              <IonNote color="medium">
                                <FormatData type="date" value={chatSingle.created_at} loading={loading} />
                              </IonNote>
                            </IonLabel>
                          </IonItem>
                      )
                    })}
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          )
        })()}
      </IonContent>
      <IonFooter>
        <form onSubmit={onSubmit}>
          <IonToolbar>
            <IonItem lines="none">
              <IonLabel position="floating">
                Text message
            </IonLabel>
              <IonTextarea
                id="messageBox"
                className="ion-no-margin"
                value={value}
                onIonChange={(e: any) => setValue(e.target.value)}
                disabled={isUndefined(chatId)}
                autoGrow
              />
              <IonButton disabled={submitDisabled} type="submit" style={{ height: '100%' }} slot="end" size="default" fill="clear">
                <IonIcon size="large" icon={sendOutline} />
              </IonButton>
            </IonItem>
          </IonToolbar>
        </form>
      </IonFooter>
    </IonPage>
  )
}

export default ChatDetailPage

