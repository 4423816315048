import React, { useState, useEffect } from 'react'
import { IonPage, IonList, IonItem, IonLabel, IonCard, IonCardContent, IonText, IonButton, IonIcon, IonCardHeader, IonCardTitle, IonCardSubtitle, IonGrid, IonRow, IonCol, useIonViewWillLeave } from '@ionic/react'
import BusinessProfileWrapper from '../../components/BusinessProfileWrapper'
// import CatalogList from '../../components/CatalogList'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useParams, useHistory } from 'react-router';
import AmountQuickSelect from '../../components/payments/AmountQuickSelect';
import SquarePayment from '../../components/payments/SquarePayment';
import RegisterUserComponent from '../../components/RegisterUserComponent';
import { shieldCheckmarkOutline, checkmarkCircle } from 'ionicons/icons';
import PriceBreakDown from '../../components/payments/PriceBreakDown';
import { FormatData } from '../../components/FormatData';
import { useAuthStore } from '../../store/AuthStore';


declare var Beacon: any; 

interface Props {

}

const quickOptionsTip = [
  {
    amount: '15',
    text: '15%'
  },
  {
    amount: '20',
    text: '20%'
  },
  {
    amount: '25',
    text: '25%'
  }
];

const quickOptionsTotal = [
  {
    amount: '$10.00',
    text: '$10.00'
  },
  {
    amount: '$25.00',
    text: '$25.00'
  },
  {
    amount: '$50.00',
    text: '$50.00'
  },
  {
    amount: '$100.00',
    text: '$100.00'
  }
]

const BusinessProfilePay = (props: Props) => {
  const { id } = useParams();
  const [businessInfo, setBusinessInfo] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState<any>('amount');
  const [transferAmount, setTranferAmount] = useState<any>({
    unformatted: 0,
    formatted: '$0.00'
  });
  const [tipAmount, setTipAmount] = useState<any>({
    unformatted: 0,
    formatted: '$0.00'
  });
  const [tab, setTab] = useState<any>({});
  const [ticket, setTicket] = useState<any>({
    ticket_items: [],
    ticket_total: 0,
    quantity: 0,
    id: ''
  });
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [amountDefaultValue, setAmountDefaultValue] = useState<any>({
    formatted: ``,
    unformatted: 0,
    selectedText: ``
  });
  let history = useHistory();
  const { merchant } = useAuthStore((store) => store);

  function calculateQuickSelectOptions(amount: any) {
    return `$${((amount * transferAmount.unformatted) / 10000).toFixed(2)}`
  }

  useIonViewWillLeave(() => {
    setPaymentDetails({});
    setTipAmount({
      unformatted: 0,
      formatted: '$0.00'
    });
    setTranferAmount({
      unformatted: 0,
      formatted: '$0.00'
    });
    setScreen('amount');
  });

  useEffect(() => {
    if (id) {
      firebase
        .firestore()
        .collection("merchants")
        .doc(id)
        .get()
        .then((doc) => {
          setBusinessInfo({
            id: doc.id,
            ...doc.data(),
          });
          if (doc.data().only_donate) {
            history.replace(`/profile/${id}/donate`);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [id, history]);

  useEffect(() => {
    const user = firebase.auth().currentUser
    if (id && user) {
      firebase
        .firestore()
        .collection("tickets")
        .doc(`${id}${user.uid}`)
        .get()
        .then((doc) => {
          setTicket({
            id: doc.id,
            ...doc.data(),
          });
          if (doc.data() && doc.data().ticket_total > 0 && doc.data().status === 'requested') {
            setScreen('ticket');
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [id])

  function updateTab(newTab: any) {
    setLoading(false);
    setTab(newTab);
  }

  async function acceptInvite() {
    const user = firebase.auth().currentUser;

    await firebase.firestore().collection('tabs').doc(`${businessInfo.merchant_id}${user.uid}`).set({
      merchant_id: businessInfo.merchant_id,
      merchant_name: businessInfo.business_name,
      merchant_phone: businessInfo.merchant_phone,
      user_id: user.uid,
      user_display_name: user.displayName,
      user_phone: user.phoneNumber
    });
  }

  async function onSuccess() {
    setScreen('success');
  }

  return (
    <IonPage>
      <BusinessProfileWrapper
        businessInfo={businessInfo}
        loading={loading}
        updateTab={updateTab}
      >
        {(() => {
          if (!firebase.auth().currentUser || !firebase.auth().currentUser.displayName || !firebase.auth().currentUser.email) {
            return (
              <div style={{ padding: 10 }}>
                <RegisterUserComponent />
              </div>
            );
          }

          if (merchant) {
            return <IonCard color="secondary">
              <IonCardHeader>
                <IonCardTitle>Not available</IonCardTitle>
                <IonCardSubtitle>You are logged in as a business.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <p>Unforunately we haven't built this feature for businesses yet. Reach out to us below if you'd like this feature.</p>
                <br />
                <IonButton className="ion-no-margin" onClick={() => Beacon('open')}>Talk To Support</IonButton>
              </IonCardContent>
            </IonCard>
          }

          if (!tab.exists || tab.first_time) {
            return (
              <IonCard color="secondary">
                <IonCardHeader className="ion-margin-start">
                  <IonCardTitle>Pay With Store Credit</IonCardTitle>
                  <IonCardSubtitle>Start store credit at {businessInfo.business_name}.</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList className="ion-no-padding" lines="full">
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Talk and order via texting.</h2>
                      </IonLabel>
                    </IonItem>
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Reload your store credit.</h2>
                      </IonLabel>
                    </IonItem>
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Receive updates via text.</h2>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <IonButton
                    className="ion-no-margin ion-margin-vertical"
                    type="submit"
                    expand="block"
                    onClick={acceptInvite}
                  >
                    Start Now
                  </IonButton>
                  <IonText color="medium">
                    By clicking start now, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a>. You may receive SMS Notifications from us and can opt out any time.
                  </IonText>
                </IonCardContent>
              </IonCard>
            )
          }

          return (
            <div style={{ padding: 10 }}>
              {firebase.auth().currentUser && <React.Fragment>
                {(screen !== 'success' && screen !== 'ticket') && <React.Fragment>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" sizeSm="4">
                        <IonButton
                          expand="block"
                          fill="clear"
                          color={(screen === 'amount') ? 'primary' : 'secondary'}
                          onClick={() => setScreen('amount')}
                        >
                          <IonIcon slot="start" icon={checkmarkCircle} />
                        Select Amount
                      </IonButton>
                      </IonCol>
                      {!businessInfo.tipping_off && <IonCol size="12" sizeSm="4">
                        <IonButton
                          expand="block"
                          fill="clear"
                          color={(screen === 'tip') ? 'primary' : 'secondary'}
                          disabled={screen === 'amount'}
                          onClick={() => setScreen('tip')}
                        >
                          <IonIcon slot="start" icon={checkmarkCircle} />
                        Select Tip
                      </IonButton>
                      </IonCol>}
                      <IonCol size="12" sizeSm="4">
                        <IonButton
                          expand="block"
                          fill="clear"
                          color={(screen === 'pay') ? 'primary' : 'secondary'}
                          disabled={screen === 'tip' || screen === 'amount'}
                          onClick={() => setScreen('pay')}
                        >
                          <IonIcon slot="start" icon={checkmarkCircle} />
                          Payment Method
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {(screen !== 'amount' && screen !== '') && <PriceBreakDown
                    updatePaymentDetails={setPaymentDetails}
                    transferAmount={transferAmount}
                    tipAmount={tipAmount}
                    businessInfo={businessInfo}
                    type="transfer"
                  />}
                </React.Fragment>}


                {(() => {
                  switch (screen) {
                    case 'success':
                      return (
                        <React.Fragment>
                          <IonCard color="secondary" className="ion-no-margin">
                            <IonCardHeader>
                              <IonCardTitle>
                                Payment Submitted
                              </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <p>If there are any issues we'll text you.</p>
                              <p>Thank you for supporting local businesses.</p>
                              <IonButton
                                className="ion-margin"
                                expand="block"
                                onClick={() => history.push(`/profile/${businessInfo.merchant_id}`)}
                              >
                                Done
                              </IonButton>
                            </IonCardContent>
                          </IonCard>
                        </React.Fragment>
                      )
                    case 'pay':
                      return (
                        <React.Fragment>
                          <IonCard color="secondary" className="ion-no-margin">
                            <IonCardContent>
                              <SquarePayment
                                paymentDetails={paymentDetails}
                                tab={tab}
                                numberOfDrinks={undefined}
                                onSuccess={onSuccess}
                              />
                            </IonCardContent>
                          </IonCard>
                        </React.Fragment>
                      )
                    case 'tip':
                      return (
                        <React.Fragment>
                          <AmountQuickSelect
                            title="Tip"
                            options={quickOptionsTip}
                            calculateQuickSelectOptions={calculateQuickSelectOptions}
                            defaultValue={{
                              formatted: '',
                              unformatted: 0,
                              selectedText: ''
                            }}
                            onNext={(value: any) => {
                              setScreen('pay');
                              setTipAmount(value);
                            }}
                          />
                        </React.Fragment>

                      )
                    case 'ticket':
                      return (
                        <React.Fragment>
                          <IonCard color="secondary" className="ion-no-margin">
                            <IonCardHeader>
                              <IonCardTitle>Ticket</IonCardTitle>
                            </IonCardHeader>
                            <IonList className="ion-no-padding" lines="full">
                              {(() => {
                                if (ticket.ticket_items.length > 0) {
                                  return (
                                    <React.Fragment>
                                      {ticket.ticket_items.map((item: any, index: any) => {
                                        return (
                                          <IonItem key={index} color="secondary">
                                            <IonLabel slot="start" className="ion-no-margin">
                                              <h2><FormatData value={item.name} type={`text`} /></h2>
                                              <p>Quantity: <FormatData value={item.quantity} type={`number`} /></p>
                                            </IonLabel>
                                            <IonLabel className="ion-no-margin ion-text-end ion-text-wrap">
                                              <FormatData value={item.price} type={`dollar`} />
                                            </IonLabel>
                                          </IonItem>
                                        )
                                      })}
                                    </React.Fragment>
                                  )
                                }

                                return <IonItem>
                                  <IonLabel>No Items</IonLabel>
                                </IonItem>
                              })()}
                            </IonList>
                            <h2 className="ion-margin ion-text-end">Subtotal: <FormatData value={ticket.ticket_total} type={`dollar`} /></h2>

                          </IonCard>
                          <br />
                          <IonButton
                            onClick={() => {
                              setAmountDefaultValue({
                                formatted: `$${(ticket.ticket_total / 100).toFixed(2)}`,
                                unformatted: ticket.ticket_total,
                                selectedText: `$${(ticket.ticket_total / 100).toFixed(2)}`
                              })
                              setScreen('amount');
                            }}
                            className="ion-no-margin"
                            expand="block"
                          >
                            Next
                          </IonButton>
                        </React.Fragment>
                      )
                    case 'amount':
                    default:
                      return <React.Fragment>
                        <AmountQuickSelect
                          title="Add Store Credit"
                          options={quickOptionsTotal}
                          defaultValue={amountDefaultValue}
                          minimumAmount={100}
                          onNext={(value: any) => {
                            if (businessInfo.tipping_off) {
                              setScreen('pay');
                            } else {
                              setScreen('tip');
                            }
                            setTranferAmount(value);
                          }}
                        />
                        <br />
                        <p className="ion-text-center">- OR -</p>
                        <IonButton fill="clear" color="secondary" expand="block" routerLink={`/profile/${businessInfo.merchant_id}/donate`}>DONATE</IonButton>
                      </React.Fragment>
                  }

                })()}

              </React.Fragment>}
            </div>
          )

        })()}
      </BusinessProfileWrapper>
    </IonPage>
  )
}

export default BusinessProfilePay
