import React, { useState, useEffect }  from 'react'
import { IonGrid, IonRow, IonCol, useIonViewWillEnter, useIonViewWillLeave, IonList, IonItem, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonProgressBar, IonLabel, IonPage, IonContent, IonCardSubtitle } from '@ionic/react'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FormatData } from '../../components/FormatData';
import { useParams } from 'react-router';
import find from 'lodash/find';
import ListView from '../../components/ListView';
import MerchantHeader from '../../components/MerchantHeader';
import { useAuthStore } from '../../store/AuthStore';

interface Props {
  
}

const Transfers: React.FC<Props> = () => {
  const {merchant} = useAuthStore((store) => store);
  let { transferId } = useParams();
  const [listener, setListener] = useState(undefined);
  const [transfersList, setTransfersList] = useState([]);
  const [selectedTransfer, setSelectedTransfer] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useIonViewWillEnter(() => {
    function handleTransfers(query: any) {
      const items:any = [];

      setLoading(false);

      query.forEach((doc: any) => {
        items.push({
          ...doc.data(),
          id: doc.id,
          link: `/transfers/${doc.id}`,
          date: doc.data().created_at,
          primary: doc.data().user_display_name,
          secondary: doc.data().total
        })
      })
      setTransfersList(items);
    }
    const merchantId = merchant.merchant_id;
    const sub = firebase.firestore().collection('transfers').where('merchant_id', '==', merchantId).orderBy('created_at', 'desc').onSnapshot(handleTransfers);
    setListener(() => sub);
  });

  useEffect(() => {
    if(transferId && transfersList.length > 0) {
      setSelectedTransfer(find(transfersList, function(o) {return o.id === transferId;}));
    }
  }, [transferId, transfersList])

  useIonViewWillLeave(() => {
    listener && listener();
  }, [listener]);

  return (
    <IonPage>
      <MerchantHeader title="Payments" />
      <IonContent>
        {loading && <IonProgressBar type="indeterminate" />}
        {!loading && <div style={{maxWidth: 768, margin: '0 auto'}}>
          <IonGrid>
            <IonRow>
              <IonCol size={transfersList.length === 0 ? '12' : '5'}>
                <ListView
                  data={transfersList}
                  selectedId={transferId}
                />
              </IonCol>
              {(transferId && selectedTransfer) && <IonCol size="7">
                <div className="ion-margin-start ion-margin-end">
                  <IonCard>
                    <IonCardHeader className="ion-no-padding ion-padding-top ion-padding-horizontal">
                      <IonCardSubtitle>
                        Customer:
                      </IonCardSubtitle>
                      <IonCardTitle>
                        <FormatData type="text" value={selectedTransfer.user_display_name} />
                      </IonCardTitle>
                    </IonCardHeader>
                    <br />
                    <IonCardContent className="ion-no-padding ion-padding-bottom ion-padding-horizontal">
                      <IonList lines="full">
                        {/* {selectedTransfer.order.map((item:any) => (
                          <IonItem key={item.id} className="ion-no-padding">
                            <IonLabel>
                              <FormatData type="text" value={item.name} /> x <FormatData type="text" value={item.quantity} />
                            </IonLabel>
                            <IonNote slot="end" color="dark">
                              <FormatData type="dollar" value={item.price} />
                            </IonNote>
                          </IonItem>
                        ))} */}
                        <IonItem>
                          <IonLabel slot="start">
                            Store Credit:
                          </IonLabel>
                          <IonLabel slot="end">
                            <FormatData type="dollar" value={selectedTransfer.transfer_amount} />
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot="start">
                            Tip Amount:
                          </IonLabel>
                          <IonLabel slot="end">
                            <FormatData type="dollar" value={selectedTransfer.tip_amount} />
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot="start">
                            Service Fee
                          </IonLabel>
                          <IonLabel slot="end">
                            <FormatData type="dollar" value={selectedTransfer.opel_fee} />
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot="start">
                            Total:
                          </IonLabel>
                          <IonLabel slot="end">
                            <FormatData type="dollar" value={selectedTransfer.total} />
                          </IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>
                            <FormatData type="text" value={selectedTransfer.status} /> @ <FormatData type="date" value={selectedTransfer.created_at} />
                          </IonLabel>
                        </IonItem>
                      </IonList>
                      <IonButton 
                        fill="outline" 
                        expand="block"
                        routerLink={`/chats/${selectedTransfer.tab_id}`}
                      >View Tab</IonButton>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>}
            </IonRow>
          </IonGrid>
        </div>}
      </IonContent>
    </IonPage>
  )
}

export default Transfers
