import React, { useEffect, useState } from 'react'
import SCHEMA from '../constants/regulars';
import find from 'lodash/find';
import { IonList, IonItem, IonLabel, IonListHeader } from '@ionic/react';
import { FormatData } from './FormatData';

interface Props {
  data: any;
  type: string;
}

export const DisplayData: React.FC<Props> = (props) => {
  const [schema, setSchema] = useState(undefined);
  const {data} = props;

  useEffect(() => {
    setSchema(find(SCHEMA, {id: props.type}));
  }, [props.type])

  if(schema) {
    return (
      <IonList lines="full">
        {schema.fields.map((item: any, key: number) => {
          if(!item) {
            return <React.Fragment key={key} />
          }
          return (
            <React.Fragment key={key}>
              {(item.type ==='receipt') && <IonItem color="primary" detail target="_blank" href={data.proccesor_details.payment.receipt_url} key={item.id}>
                <IonLabel className="ion-text-center">View Receipt</IonLabel>
              </IonItem>}
              {(item.type !== 'array' && item.type !== 'receipt') && <IonItem key={item.id}>
                <IonLabel>
                  {item.displayName}:
                </IonLabel>
                <IonLabel color="dark" slot="end">
                  <FormatData type={item.type} value={data[item.id]} />
                </IonLabel>
              </IonItem>}
              {(item.type === 'array' && data[item.id]) && <React.Fragment>
                <IonListHeader>
                  {item.schema.name}:
                </IonListHeader>
                {data[item.id].map((lineItem: any, key: number) => (
                  <React.Fragment key={key}>
                    <IonItem>
                      <IonLabel>
                        <h2>{lineItem['name']} x {lineItem['quantity']}</h2>
                        <h4 className="list">
                          {item.schema.fields.map((schema: any, key: number) => {
                            if(schema.id === 'name') {
                              return <span key={key} />
                            }
                            if(lineItem[schema.id]) {
                              return (
                                <div key={key}>
                                  {schema.displayName}: <FormatData value={lineItem[schema.id]} type={schema.type} />
                                </div>
                              );
                            }

                            return <span key={key} />
                          })}
                        </h4>
                      </IonLabel>
                    </IonItem>
                  </React.Fragment>
                ))}
              </React.Fragment>}
            </React.Fragment>
          );
        })}
      </IonList>
    );
  }

  return <div />
}
