import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../components/PageWrapper';
import { IonGrid, IonRow, IonCol, IonItem, IonCardSubtitle, IonLabel, IonList, IonNote, IonItemSliding, IonItemOptions, IonItemOption, IonListHeader, IonIcon, useIonViewDidEnter, useIonViewWillLeave, IonButton, IonCardTitle, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonProgressBar } from '@ionic/react'
import { checkmarkCircle, informationCircle, alert } from 'ionicons/icons';
import { useParams, useHistory } from 'react-router';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { FormatData } from '../../../components/FormatData';
import find from 'lodash/find';
import reject from 'lodash/reject';
import CatalogList from '../../../components/CatalogList';
import isUndefined from 'lodash/isUndefined';

interface Props {
  
}

const MerchantTabIndex: React.FC<Props> = () => {
  let { tabId } = useParams();
  let history = useHistory();
  const [tab, setTab] = useState<any>({});
  const [ticket, setTicket] = useState<any>([]);
  const [listeners, setListeners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticketTotal, setTicketTotal] = useState<any>(0);
  const [successOrder, setSuccessOrder] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  function handleTabChange(doc: any) {
    setTab({id: doc.id, ...doc.data()});
    setLoading(false);
  }

  useEffect(() => {
    setTicket([]);
    setTab({});
    setSuccessOrder(undefined);
    return () => {
      setTicket([]);
      setTab({});
      setSuccessOrder(undefined);
    }
  }, [tabId])

  useIonViewDidEnter(() => {
    setTab({});
    setTicket([])
    setSuccessOrder(undefined)
    const tabSub = firebase.firestore().collection('tabs').doc(tabId).onSnapshot(handleTabChange);
    setListeners((listeners: any) => [...listeners, tabSub]);
  },[tabId]);

  useIonViewWillLeave(() => {
    setTab({});
    setTicket([])
    setSuccessOrder(undefined);
    if(listeners) {
      listeners.forEach((element: any) => {
        element();
      });
    }
  }, [listeners]);

  function addToTicket(item: any) {
    let currentItem: any = find(ticket, {id: item.id});
    let newTicket: any = ticket;
    if(currentItem) {
      newTicket = reject(ticket, {id: item.id});
      currentItem.quantity = currentItem.quantity + 1;
    } else {
      currentItem = {
        ...item,
        quantity: 1
      }
    }
    setTicket([...newTicket, currentItem]);
  }

  function removeFromTicket(item: any) {
    document.querySelector("ion-item-sliding").closeOpened();
    const newItem = reject(ticket, {id: item.id});
    setTicket([...newItem]);
  }

  async function addToTab() {

    async function handleOrder(doc: any) {
      setSuccessOrder({id: doc.id, ...doc.data()});
    }

    const currentTab = tab;
    if(Number(currentTab.balance) < Number(ticketTotal) || isUndefined(currentTab.balance)) {
      return;
    }

    const tabId = tab.id;
    const orderDoc = await firebase.firestore().collection('orders').add({
      fulfillment_status: 'open',
      lineItems: ticket,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      total: ticketTotal,
      tab_id: tabId,
      ...currentTab
    });

    const sub = firebase.firestore().collection('orders').doc(orderDoc.id).onSnapshot(handleOrder);
    setListeners((prevListeners: any) => [...prevListeners, sub]);
  }

  useEffect(() => {
    let newTicketTotal = 0;
    ticket.forEach((item: any) => {
      const itemTotal = item.price * item.quantity;
      newTicketTotal = newTicketTotal + itemTotal;
    });
    setTicketTotal(newTicketTotal);
  }, [ticket])


  return (
    <PageWrapper 
      title={<IonButton size="large" fill="clear" onClick={() => setShowModal(!showModal)}>
         <FormatData value={tab.user_display_name} type="text" loading={loading} />
      </IonButton>} 
      isChild
      hideMenu
    >
      <IonModal onDidDismiss={() => setShowModal(false)} isOpen={showModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              Tab Info
            </IonTitle>
            <IonButton fill="clear" slot="end" onClick={() => setShowModal(false)}>
              Close
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonList>
                  <IonItem>
                    <IonLabel>
                      <h3>Name:</h3>
                      <h2><FormatData value={tab.user_display_name} type="text" loading={loading} /></h2>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel className="ion-align-items-center">
                      <h3>Balance:</h3>
                      <h2><FormatData value={tab.balance} type="dollar" loading={loading} /></h2>
                      <p>The amount <FormatData value={tab.user_display_name} type="text" loading={loading} /> has pre-paid.</p>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      {(() => {
        if(successOrder) {
          return (
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol size="4" className="ion-text-center">
                  <br />
                  {(successOrder && successOrder.status && successOrder.status === 'SUCCESS') && <IonIcon size="large" icon={checkmarkCircle} />}
                  <h1 style={{marginTop: 0}}>{(successOrder && successOrder.status) ? successOrder.status : 'CREATED'}</h1>
                  <h6>You have successfully added {ticket.length} item(s) to {tab.user_display_name}'s tab.</h6>
                  <br />
                  {((!successOrder || !successOrder.status) || successOrder.status !== 'SUCCESS') && <IonProgressBar type="indeterminate" />}
                  {(successOrder && successOrder.status && successOrder.status === 'SUCCESS') && <IonButton 
                    style={{maxWidth: 250, margin: 'auto'}} 
                    expand="block" 
                    color="secondary"
                    onClick={() => history.goBack()}
                  >DONE</IonButton>}
                </IonCol>
              </IonRow>
            </IonGrid>
          )
        }

        return (
          <IonGrid className="ion-no-padding ion-no-margin opel-full-height">
            <IonRow className="ion-no-padding ion-no-margin opel-full-height">
              <IonCol 
                sizeMd="3"
                size="12"
                className="ion-no-padding ion-no-margin opel-full-height opel-scroll"
                style={{
                  borderRight: '1px solid #c5d6d6'
                }}
              >
                <IonGrid className="ion-no-padding ion-no-margin">
                  <IonRow className="ion-align-items-center ion-no-padding ion-no-margin">
                    <IonCol size="12">
                      <IonButton 
                        onClick={addToTab}
                        className="ion-no-margin"
                        expand="full" 
                        color="secondary"
                        disabled={Number(tab.balance) < Number(ticketTotal)}
                      >CHARGE</IonButton>
                    </IonCol>
                    <IonCol size="12">
                      <IonList lines="full" className="ion-no-margin">
                        <IonItem onClick={() => setShowModal(!showModal)}>
                          <IonLabel>
                            <h3>Balance:</h3>
                            <h2><FormatData value={tab.balance} type="dollar" loading={loading} /></h2>
                          </IonLabel>
                          {(Number(tab.balance) >= Number(ticketTotal)) && <IonIcon color="medium" icon={informationCircle} slot="end" />}
                          {(Number(tab.balance) < Number(ticketTotal)) && <IonIcon color="danger" icon={alert} slot="end" />}
                        </IonItem>
                      </IonList>
                      <div className="ion-padding" style={{borderBottom: '1px solid rgb(200, 199, 204)'}}>
                        <IonCardSubtitle>Total:</IonCardSubtitle>
                        <IonCardTitle><FormatData value={ticketTotal} type={`dollar`} /></IonCardTitle>
                      </div>
                    </IonCol>
                    <IonCol 
                      size="12"
                      className="ion-no-padding ion-no-margin"
                    >  
                      <IonList 
                        lines={(ticket.length < 1) ? 'none' : 'full'} 
                        className="ion-no-padding ion-no-margin"
                      >
                        <IonListHeader>
                          Ticket
                        </IonListHeader>
                        {(ticket.length < 1) && <IonItem>
                          <IonNote>
                            No Items
                          </IonNote>  
                        </IonItem>}
                        {ticket.map((item: any, key: any) => {
                          return (
                            <IonItemSliding key={key}>
                              <IonItem>
                                <IonLabel slot="start">
                                  <h2 slot="start"><FormatData value={item.name} type={`text`} /> x <FormatData value={item.quantity} type={`number`} /></h2>
                                </IonLabel>
                                <IonLabel slot="end">
                                  <h2 slot="end"><FormatData value={item.price} type={`dollar`} /></h2>
                                </IonLabel>
                              </IonItem>
                              <IonItemOptions side="end">
                                <IonItemOption color="danger" onClick={() => removeFromTicket(item)}>Remove</IonItemOption>
                              </IonItemOptions>
                            </IonItemSliding>
                          )
                        })}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol sizeMd='9' size="12" className="ion-no-padding ion-no-margin opel-full-height opel-scroll">
                <CatalogList merchantId={tab.merchant_id} onAction={addToTicket} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )

      })()}
    </PageWrapper>
  )
}

export { MerchantTabIndex };

