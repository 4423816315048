import React, { useState } from 'react';

import { IonList, IonItem, IonLabel, IonContent, IonToolbar, IonHeader, IonModal, IonTitle, IonInput, IonButton, IonToast, IonProgressBar, IonIcon } from '@ionic/react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useHistory } from 'react-router';
import phone from 'phone';
import { createOutline } from 'ionicons/icons';
import { useAuthStore } from '../../store/AuthStore';

interface Props {

}


const CreateMessage: React.FC<Props> = () => {
  const { merchant } = useAuthStore((store) => store);
  const [isOpen, setIsOpen] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    color: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  async function registerNewCustomer(e: any) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setLoading(true);

    const displayName: any = document.getElementById('displayName');
    const phoneNumber: any = document.getElementById('phoneNumber');

    const registerNewUserAndChat = firebase.functions().httpsCallable('registerNewUserAndChat');

    if (phone(phoneNumber.value).length < 1) {
      setToast({
        isOpen: true,
        message: 'The phone number you have entered is invalid',
        color: 'danger'
      });
      setLoading(false);
      return;
    }

    try {
      const result: any = await registerNewUserAndChat({
        merchant_id: merchant.merchant_id,
        phone_number: phone(phoneNumber.value)[0],
        display_name: displayName.value
      })

      setToast({
        isOpen: true,
        message: `You have successfully registered ${displayName.value}.`,
        color: 'tertiary'
      });
      setLoading(false);
      setIsOpen(false);
      history.push(`/chats/${result.data.id}`);

    } catch (error) {
      setToast({
        isOpen: true,
        message: error.message,
        color: 'error'
      });
      setLoading(false);
    }

  }

  return (
    <React.Fragment>
      <IonToast
        color={toast.color}
        duration={5000}
        isOpen={toast.isOpen}
        message={toast.message}
        onDidDismiss={() => setToast({
          isOpen: false,
          color: '',
          message: ''
        })}
      />

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Register New Customer</IonTitle>
            <IonButton fill="clear" color="secondary" slot="end" onClick={() => setIsOpen(false)}>Close</IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {loading && <IonProgressBar type="indeterminate" />}
          {!loading && <form onSubmit={registerNewCustomer}>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Full Name</IonLabel>
                <IonInput id="displayName" />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Phone Number</IonLabel>
                <IonInput id="phoneNumber" />
              </IonItem>
            </IonList>
            <IonButton type="submit" className="ion-margin" expand="block">Submit</IonButton>
          </form>}
        </IonContent>
      </IonModal>

      <IonButton color="primary" fill="clear" slot="start" onClick={() => setIsOpen(true)}>
        <IonIcon slot="icon-only" icon={createOutline} />
      </IonButton>
      {/* <IonFooter>
        <IonButton routerLink="/createCampaign" expand="full" color="secondary" className="ion-no-margin">
          Create Campaign
        </IonButton>
        <IonButton routerLink="/addCustomers" expand="full" color="primary" className="ion-no-margin">
          Invite Customers
        </IonButton>
      </IonFooter> */}
    </React.Fragment >
  )
}

export default CreateMessage;
