import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonBackButton,
  useIonViewWillLeave,
  useIonViewWillEnter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
} from "@ionic/react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useParams, useHistory, useRouteMatch } from "react-router";
import {
  chatboxOutline,
  newspaper,
  card
} from "ionicons/icons";
import { FormatData } from "./FormatData";
import { useAuthStore } from "../store/AuthStore";

function BusinessProfileWrapper(props) {
  let params = useParams();
  let history = useHistory();
  let { url } = useRouteMatch();
  const { businessInfo, loading, updateTab } = props;
  const [linkSelected, setLinkSelected] = useState(undefined);
  const [tab, setTab] = useState({});
  const [internalLoading, setInternalLoading] = useState(true);
  const { merchant } = useAuthStore((store) => store);

  useIonViewWillLeave(() => {
    setLinkSelected(undefined);
  });

  useIonViewWillEnter(() => {
    setLinkSelected(url);
  }, [url]);

  useEffect(() => {
    if (linkSelected && linkSelected !== url) {
      history.replace(linkSelected);
    }
  }, [url, linkSelected, history]);

  useEffect(() => {
    if (updateTab && !internalLoading) {
      updateTab(tab);
    }
  }, [updateTab, tab, internalLoading]);

  useEffect(() => {
    function handleSnap(doc) {
      const newTab = {
        tab_id: doc.id,
        id: doc.id,
        ...doc.data(),
        exists: doc.exists,
      };
      setTab(newTab);
      setInternalLoading(false);
    }

    let unsub;
    const user = firebase.auth().currentUser;
    if (params.id && user) {
      unsub = firebase
        .firestore()
        .collection("tabs")
        .doc(`${params.id}${user.uid}`)
        .onSnapshot(handleSnap);
    } else {
      setTab({});
      setInternalLoading(false);
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [params, businessInfo]);

  function onPhoneAction(phoneNumber, type) {
    if (type === "sms") {
      window.location = `sms:${phoneNumber}`;
    }
    if (type === "tel") {
      window.location = `tel:${phoneNumber}`;
    }
  }

  if (loading) {
    return <IonProgressBar type="indeterminate" />;
  }

  return (
    <React.Fragment>
      <IonHeader>
        <div style={{ maxWidth: 1100, margin: "auto" }}>
          <IonToolbar>
            {!props.isMerchant && (
              <IonBackButton slot="start" defaultHref="/" />
            )}
            <IonItem
              routerLink={
                tab && tab.exists
                  ? `/tabs/${tab.id}/transfers`
                  : `/profile/${businessInfo.merchant_id}/pay`
              }
            >
              
              <IonLabel>
                <h1>{businessInfo.business_name}</h1>
                <h2
                  style={{
                    color: 'var(--ion-color-medium)'
                  }}
                >Store Credit: <FormatData
                    value={tab.balance ? tab.balance : 0}
                    type={`dollar`}
                    loading={loading}
                  />
                </h2>
              </IonLabel>
            </IonItem>
            <IonButtons slot="end">
              {/* <IonButton
                onClick={() =>
                  onPhoneAction(businessInfo.merchant_phone, "tel")
                }
              >
                <IonIcon slot="icon-only" icon={callOutline} />
              </IonButton> */}
              <IonButton
                onClick={() =>
                  onPhoneAction(businessInfo.merchant_phone, "sms")
                }
              >
                <IonIcon slot="icon-only" icon={chatboxOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {!merchant && (
            <IonToolbar>
              <IonSegment
                color="primary"
                value={linkSelected}
                onIonChange={(e) => {
                  setLinkSelected(e.detail.value);
                }}
              >
                <IonSegmentButton
                  layout="icon-top"
                  value={`/profile/${params.id}`}
                >
                  <IonLabel>Posts</IonLabel>
                  <IonIcon icon={newspaper} />
                </IonSegmentButton>

                {businessInfo.only_donate && (
                  <IonSegmentButton
                    layout="icon-top"
                    value={`/profile/${params.id}/donate`}
                  >
                    <IonLabel>
                      Donate
                      {/* <span 
                    style={{
                      background: 'var(--ion-color-primary)',
                      color: 'var(--ion-color-tertiary)',
                      borderRadius: '100%',
                      padding: '1px 5px',
                      marginLeft: 3
                    }}
                  >
                    9
                  </span> */}
                    </IonLabel>
                    <IonIcon icon={card} />
                  </IonSegmentButton>
                )}
                {/* {!businessInfo.only_donate && (
                <IonSegmentButton layout="icon-start" value={`/profile/${params.id}/`}>
                  <IonLabel>Home</IonLabel>
                  <IonIcon icon={home} />
                </IonSegmentButton>
              )} */}
                {!businessInfo.only_donate && (
                  <IonSegmentButton
                    layout="icon-top"
                    value={`/profile/${params.id}/pay`}
                  >
                    <IonLabel>Pay</IonLabel>
                    <IonIcon icon={card} />
                  </IonSegmentButton>
                )}
              </IonSegment>
            </IonToolbar>
          )}
        </div>
      </IonHeader>
      <IonContent>
        <div
          style={{
            maxWidth: 1100,
            margin: "auto",
          }}
        >
          <IonGrid className="ion-no-padding">
            {businessInfo.only_donate && (
              <IonRow>
                <IonCol size="12">
                  <IonCard color="primary" className="ion-margin-vertical">
                    <IonCardHeader>
                      <IonCardTitle>
                        {businessInfo.business_name} uses Opel for donations.
                      </IonCardTitle>
                      <IonCardSubtitle>
                        Support {businessInfo.business_name} by using Opel to
                        donate and let us know how you like it.
                      </IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                </IonCol>
              </IonRow>
            )}
            <IonRow className="ion-hide-lg-up">
              <IonCol size="12">{props.Sidebar}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeLg="9">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12">{props.children}</IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="3" className="ion-hide-md-down">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12">{props.Sidebar}</IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </React.Fragment>
  );
}

export default BusinessProfileWrapper;
