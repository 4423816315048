import React, { useState, FormEvent } from 'react'
import { IonModal, IonItem, IonLabel, IonHeader, IonTitle, IonContent, IonList, IonInput, IonButton, IonToolbar } from '@ionic/react'
import { useEffect } from 'react';

interface Props {
  itemName: string;
  label: string;
  description?: any;
  onDone: any;
  initialValue: string | number;
  inputType?: 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time';
  itemColor: any;
}

const SettingsModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<any>('');

  function onSubmit(e: FormEvent) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    props.onDone(value);
    setIsOpen(false);
  }

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue])

  return (
    <React.Fragment>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <form style={{height: '100%'}} onSubmit={onSubmit}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                {props.itemName}
              </IonTitle>
              <IonButton 
                className="ion-margin" 
                type="submit" 
                expand="block"
                slot="end"
                color="secondary"
              >
                Done
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>

            <IonList className="ion-no-padding">
              <IonItem color="light">
                <IonLabel position="floating">
                  {props.label}
                </IonLabel>
                <IonInput
                  type={props.inputType ? props.inputType : `text`}
                  value={value}
                  onIonChange={(e) => setValue(e.detail.value)}
                />
              </IonItem>
              {props.description && <IonItem lines="none">
                <IonLabel color="dark" className="ion-text-wrap">
                  {props.description}
                </IonLabel>
              </IonItem>}
            </IonList>
          </IonContent>
        </form>
      </IonModal>
      <IonItem color={props.itemColor} button detail onClick={() => setIsOpen(true)}>
        <IonLabel>{props.itemName}</IonLabel>
      </IonItem>
    </React.Fragment>
  )
}

export default SettingsModal
