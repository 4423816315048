import React, {useState, useEffect} from 'react'
import PageWrapper from '../../components/PageWrapper'
import { IonSearchbar, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { FormatData } from '../../components/FormatData';
import FuzzySearch from 'fuzzy-search';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuthStore } from '../../store/AuthStore';

interface Props {
  
};

export const RegularsList: React.FC<Props> = () => {
  const {merchant} = useAuthStore((store) => store);
  const [data, setData] = useState([]);
  const [searchIndex, setSearchIndex] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [listener, setListener] = useState(undefined);

  function handleChange(query: any) {
    const items:any = [];
    query.forEach((doc: any) => {
      items.push({id: doc.id, ...doc.data()});
    })
    setData(items);
  }

  useIonViewWillEnter(() => {
    const sub = firebase.firestore().collection('tabs').where('merchant_id', '==', merchant.merchant_id).onSnapshot(handleChange);
    setListener(() => {
      return sub;
    })
  });

  useIonViewWillLeave(() => {
    if(listener) {
      listener();
    }
  });
  
  useEffect(() => {
    const searcher = new FuzzySearch(data, ['user_display_name', 'words']);
    setSearchIndex(searcher);
  }, [data]);

  return (
    <PageWrapper title={`Tabs`}>
      {/* <InviteModal /> */}
      <IonGrid className="ion-no-padding ion-no-margin opel-full-height">
        <IonRow className="ion-no-padding ion-no-margin opel-full-height opel-scroll">
          <IonCol 
            size="12" 
            className="ion-no-padding ion-no-margin opel-full-height opel-scroll"
          >
            <IonSearchbar onInput={(e: any) => setSearchValue(e.target.value)}></IonSearchbar>
            <IonGrid>
              <IonRow>
                {searchIndex && <React.Fragment>
                  {searchIndex.search(searchValue).map((item: any, key: number) => (
                    <IonCol key={key} size={`12`} sizeSm={`6`} sizeMd={`4`} sizeXl={`3`}>
                      <IonCard class="no-margin" routerLink={`/tabs/${item.id}`}>
                        <IonCardHeader>
                          <IonCardSubtitle>Balance: <FormatData type={`dollar`} value={item.balance} /></IonCardSubtitle>
                          <IonCardTitle
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <FormatData type={`text`} value={item.user_display_name} />
                          </IonCardTitle>
                        </IonCardHeader>
                      </IonCard>
                    </IonCol>
                  ))}
                  {searchIndex.search(searchValue) < 1 && <IonCol size={`12`} className="ion-text-center">
                    <h3 className="ion-text-align-center">No tabs found</h3>
                  </IonCol>}
                </React.Fragment>}
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </PageWrapper>
  )
}
