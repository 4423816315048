import React from 'react'
import phone from 'phone'
import NumberFormat from 'react-number-format'
import { IonCard, IonCardTitle, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import size from 'lodash/size';

interface Props {
  businessInfo: any;
  selectedItems?: any;
  updateSelectedItem?: any;
}

declare var window:any;

const CustomerTextStart = (props: Props) => {
  const { businessInfo, selectedItems } = props;

  function ticketClicked() {
    if (selectedItems && selectedItems.length > 0) {
      window.location = `sms:${businessInfo.merchant_phone}`;
    } else {
      window.location = `sms:${businessInfo.merchant_phone}`;
    }
  }

  return (
    <React.Fragment>
      <IonCard
        color={"primary"}
        className="ion-margin-bottom"
        onClick={ticketClicked}
      >
        {(() => {
          if (selectedItems && size(selectedItems) > 0) {
            return (
              <IonCardHeader>
                <IonCardTitle>{size(selectedItems)} item{(size(selectedItems) > 1) && `s`} </IonCardTitle>
                <IonCardSubtitle>Text in your order @ <NumberFormat value={phone(businessInfo.merchant_phone)[0]} displayType="text" format="+# (###) ###-####" mask="_" /></IonCardSubtitle>
              </IonCardHeader>
            )
          }

          return (
            <IonCardHeader>
              <IonCardTitle>Just as simple as a text! </IonCardTitle>
              <IonCardSubtitle>Text in your order @ <NumberFormat value={phone(businessInfo.merchant_phone)[0]} displayType="text" format="+# (###) ###-####" mask="_" /></IonCardSubtitle>
            </IonCardHeader>
          )

        })()}

      </IonCard>
    </React.Fragment>
  )
}

export default CustomerTextStart
