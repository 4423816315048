import React, { useState } from 'react'
import { IonCard, IonModal, IonContent, IonList, IonLabel, IonItem, IonHeader, IonToolbar, IonTitle, IonButton, IonRow, IonCol, IonGrid } from '@ionic/react'
import { useEffect } from 'react';

interface Props {
  transferAmount: any;
  tipAmount: any;
  updatePaymentDetails: any;
  businessInfo: any;
  type: string
}



const PriceBreakDown = (props: Props) => {
  const { transferAmount, tipAmount, businessInfo, updatePaymentDetails, type } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [tax, setTax] = useState<any>({});
  const [serviceFee, setServiceFee] = useState<any>({});
  const [total, setTotal] = useState<any>({});


  useEffect(() => {
    function calcTax(amount: any) {
      let taxRate = businessInfo.tax_rate
      if (!taxRate) {
        taxRate = 0;
      }

      if(type  === 'donate') {
        taxRate = 0;
      }

      return {
        formatted: `$${(((amount * taxRate) / 100) / 100).toFixed(2)}`,
        unformatted: ((amount * taxRate) / 100)
      }
    }

    function calcServiceFee(amount: any) {
      let service_fee = businessInfo.service_fee
      if(!businessInfo.service_fee || isNaN(businessInfo.service_fee)) {
        service_fee = 0;
      }
      return {
        formatted: `$${(((amount * service_fee) / 100) / 100).toFixed(2)}`,
        unformatted: ((amount * service_fee) / 100)
      }
    }

    function formatTotal(amount: any) {
      return {
        formatted: `$${(amount / 100).toFixed(2)}`,
        unformatted: amount
      }
    }

    const newServiceFeeRaw = calcServiceFee(tipAmount.unformatted + transferAmount.unformatted);
    const newTaxRaw = calcTax(newServiceFeeRaw.unformatted + transferAmount.unformatted);
    const newTotalRaw = formatTotal(newServiceFeeRaw.unformatted + newTaxRaw.unformatted + transferAmount.unformatted + tipAmount.unformatted);

    setTax(newTaxRaw);
    setServiceFee(newServiceFeeRaw);
    setTotal(newTotalRaw);

    const paymentDetails = {
      subtotal: transferAmount.unformatted ? transferAmount.unformatted : 0,
      total: newTotalRaw.unformatted ? newTotalRaw.unformatted : 0,
      service_fee: newServiceFeeRaw.unformatted ? newServiceFeeRaw.unformatted : 0,
      tip_amount: tipAmount.unformatted ? tipAmount.unformatted : 0,
      tax: newTaxRaw.formatted ? newTaxRaw.unformatted : 0,
      tax_percent: (businessInfo.tax_rate) ? businessInfo.tax_rate : 0,
      payment_amount: transferAmount.unformatted ? transferAmount.unformatted : 0,
      transfer_amount: transferAmount.unformatted ? transferAmount.unformatted : 0,
      payment_type: type
    }

    updatePaymentDetails(paymentDetails);

  }, [tipAmount, transferAmount, businessInfo, updatePaymentDetails, type]);

  return (
    <IonCard className="ion-no-margin ion-margin-bottom">
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Payment Summary</IonTitle>
            <IonButton
              fill="clear"
              slot="end"
              onClick={() => setIsOpen(false)}
            >
              Close
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="ion-padding" style={{ maxWidth: 450, margin: 'auto' }}>
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <p>{(type === 'donate') ? `Donation Amount` : `Transfer Amount` }:</p>
                </IonCol>
                <IonCol size="6">
                  <p className="ion-text-end">{transferAmount.formatted}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <p>Tip:</p>
                </IonCol>
                <IonCol size="6">
                  <p className="ion-text-end">{tipAmount.formatted}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <p>Service Fee:</p>
                </IonCol>
                <IonCol size="6">
                  <p className="ion-text-end">{serviceFee.formatted}</p>
                </IonCol>
              </IonRow>
              {(tax.unformatted > 0) && <IonRow>
                <IonCol size="6">
                  <p>Tax:</p>
                </IonCol>
                <IonCol size="6">
                  <p className="ion-text-end">{tax.formatted}</p>
                </IonCol>
              </IonRow>}
              <IonRow style={{borderTop: '1px solid var(--ion-color-secondary)'}}>
                <IonCol size="6">
                  <h3>Total:</h3>
                </IonCol>
                <IonCol size="6">
                  <h3 className="ion-text-end">{total.formatted}</h3>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
        {/* <IonFooter>
          <IonToolbar>
            <IonTitle>
              Total: {total.formatted}
            </IonTitle>
          </IonToolbar>
        </IonFooter> */}
      </IonModal>
      <IonList className="ion-no-padding" lines="full">
        <IonItem color="tertiary" detail onClick={() => setIsOpen(true)}>
          <IonLabel slot="start">Total:</IonLabel>
          <IonLabel className="ion-text-end">{total.formatted}</IonLabel>
        </IonItem>
      </IonList>
    </IonCard>
  )
}

export default PriceBreakDown
