import React from 'react'
import { IonPage, IonContent, IonProgressBar } from '@ionic/react'
import MerchantHeader from '../../components/MerchantHeader'
import CommunityFeed from '../common/CommunityFeed'
import { useAuthStore } from '../../store/AuthStore'

// interface Props {

// }

const CommunityFeedMerchant = () => {
  const { merchant } = useAuthStore((store) => store);
  if (merchant && merchant.merchant_id) {
    return (
      <IonPage>
        <MerchantHeader title="Posts" />
        <IonContent>
          <div style={{maxWidth: 600, margin: 'auto'}}>
            <CommunityFeed isMerchant={true} id={merchant.merchant_id} />
          </div>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonProgressBar type="indeterminate" />
  )
}

export default CommunityFeedMerchant
