import React, {useState, useEffect} from 'react'
import PageWrapper from '../../components/PageWrapper';
import { IonGrid, IonCol, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonButton } from '@ionic/react';
import NumberFormat from 'react-number-format';
import firebase from 'firebase/app';
import 'firebase/auth';

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

interface Props {
  
}

const Confirmation: React.FC<Props> = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(undefined);
  const [confirmationResult, setConfirmationResult] = useState(window.confirmationResult);


  function onPhoneChange(values: any) {
    setPhone(values.value);
  }

  function signInWithPhone() {
    console.log('1');
    return firebase.auth().signInWithPhoneNumber('+1' + phone, window.recaptchaVerifier).then(function (result) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = result;
      setConfirmationResult(result);
    }).catch((error: any) => {
      console.log(error);
      setError(error);
    })
  }

  async function sendConfirmationCode() {
    try {
      if(!!firebase.auth().currentUser) {
        var credential = firebase.auth.PhoneAuthProvider.credential(window.confirmationResult.verificationId, confirmationCode);
        const result  = await firebase.auth().currentUser.linkWithCredential(credential).catch((error: any) => {
          console.log(error);
          setError(error);
          return;
        });
        if(!!result) {
          window.location.href = window.location.origin;
        }
      } else {
        await window.confirmationResult.confirm(confirmationCode);
      }

    } catch (error) {
      console.log(error);
      setError(error);
      return;
    }
  }

  useEffect(() => {
    if(window.recaptchaVerifier && !window.confirmationResult && !confirmationResult) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier.render();
      return; 
    }
    
    if(!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible'
      });
      window.recaptchaVerifier.render();
      return;
    } 
  }, [confirmationResult])

  return (
    <PageWrapper hideHeader>
      <div id="recaptcha" />
      <IonGrid fixed style={{height: '100%'}}>
        <IonRow className="ion-justify-content-center" style={{height: '100%'}}>
          <IonCol size="12" sizeMd="4">
            <a href="/">
              <img
                style={{
                  maxWidth: 100,
                  margin: '10px auto 20px',
                  display: 'block',
                }}
                src="/assets/logo.svg" 
                alt="Opel"
              />
            </a>
            {(() => {
              if(!window.confirmationResult && !confirmationResult) {
                return (
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>Phone Number</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonList>
                        <IonItem>
                          <NumberFormat 
                            format="+1 (###) ###-####" 
                            mask="_"
                            defaultValue=''
                            placeholder="+1 (###) ###-####"
                            style={{
                              background: 'transparent',
                              border: 0,
                              width: '100%',
                              outline: 'none',
                              paddingBottom: 6
                            }}
                            type="tel"
                            onValueChange={onPhoneChange}
                          />
                        </IonItem>
                      </IonList>
                      <br />
                      {error && <p>
                        {error.message}
                        <br />
                      </p>}
                      <IonButton 
                        color="secondary"
                        expand="block"
                        onClick={signInWithPhone}
                      >
                        Done
                      </IonButton>
                    </IonCardContent>

                  </IonCard>
                )
              }

              return (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Confirmation Code</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none" className="ion-no-padding">
                        <NumberFormat 
                          format="######" 
                          mask="_"
                          value={confirmationCode}
                          placeholder="#-#-#-#-#-#"
                          style={{
                            background: 'transparent',
                            border: 0,
                            width: '100%',
                            outline: 'none',
                            paddingBottom: 6,
                            textAlign: 'center',
                            fontSize: 30
                          }}
                          required
                          onValueChange={(values) => setConfirmationCode(values.value)}
                        />
                      </IonItem>
                    </IonList>
                    <br />
                    <IonButton 
                      color="secondary"
                      expand="block"
                      onClick={sendConfirmationCode}
                    >
                      Done
                    </IonButton>
                    {error && <p>
                      {error.message}
                      <br />
                    </p>}
                  </IonCardContent>
                </IonCard>
              )
            })()}
          </IonCol>
        </IonRow>
      </IonGrid>
    </PageWrapper>
  );
}

export default Confirmation
