import React, { useState, useEffect } from 'react'
import { IonPage, IonList, IonItem, IonLabel, IonCard, IonCardContent, IonText, IonButton, IonIcon, IonCardHeader, IonCardTitle, IonCardSubtitle, IonGrid, IonRow, IonCol, useIonViewWillLeave, IonProgressBar } from '@ionic/react'
import BusinessProfileWrapper from '../../components/BusinessProfileWrapper'
// import CatalogList from '../../components/CatalogList'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useParams, useHistory } from 'react-router';
import AmountQuickSelect from '../../components/payments/AmountQuickSelect';
import SquarePayment from '../../components/payments/SquarePayment';
import RegisterUserComponent from '../../components/RegisterUserComponent';
import { shieldCheckmarkOutline, checkmarkCircle } from 'ionicons/icons';
import PriceBreakDown from '../../components/payments/PriceBreakDown';


interface Props {

}

const quickOptionsTotal = [
  {
    amount: '$10.00',
    text: '$10.00'
  },
  {
    amount: '$25.00',
    text: '$25.00'
  },
  {
    amount: '$50.00',
    text: '$50.00'
  },
  {
    amount: '$100.00',
    text: '$100.00'
  }
]

const BusinessProfileDonate = (props: Props) => {
  const { id } = useParams();
  const [businessInfo, setBusinessInfo] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState<any>('amount');
  const [transferAmount, setTranferAmount] = useState<any>({
    unformatted: 0,
    formatted: '$0.00'
  });
  const [tipAmount, setTipAmount] = useState<any>({
    unformatted: 0,
    formatted: '$0.00'
  });
  const [tab, setTab] = useState<any>({});
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  let history = useHistory();

  useIonViewWillLeave(() => {
    setPaymentDetails({});
    setTipAmount({
      unformatted: 0,
      formatted: '$0.00'
    });
    setTranferAmount({
      unformatted: 0,
      formatted: '$0.00'
    });
    setScreen('amount');
  })

  useEffect(() => {
    if (id) {
      firebase
        .firestore()
        .collection("merchants")
        .doc(id)
        .get()
        .then((doc) => {
          setBusinessInfo({
            id: doc.id,
            ...doc.data(),
          });
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [id]);

  function updateTab(newTab: any) {
    setTab(newTab);
    setLoading(false);
  }

  async function acceptInvite() {
    const user = firebase.auth().currentUser;

    await firebase.firestore().collection('tabs').doc(`${businessInfo.merchant_id}${user.uid}`).set({
      merchant_id: businessInfo.merchant_id,
      merchant_name: businessInfo.business_name,
      merchant_phone: businessInfo.merchant_phone,
      user_id: user.uid,
      user_display_name: user.displayName,
      user_phone: user.phoneNumber
    });
  }

  async function onSuccess(doc: any) {
    console.log(doc);
    setScreen('success');

  }

  

  return (
    <IonPage>
      <BusinessProfileWrapper
        businessInfo={businessInfo}
        loading={loading}
        updateTab={updateTab}
      >
        {(() => {
          if(loading) {
            return <IonProgressBar type="indeterminate" />
          }

          if (!firebase.auth().currentUser || !firebase.auth().currentUser.displayName || !firebase.auth().currentUser.email) {
            return (
              <div style={{ padding: 10 }}>
                <RegisterUserComponent />
              </div>
            );
          }

          if (!tab.exists || tab.first_time) {
            return (
              <IonCard color="secondary">
                <IonCardHeader className="ion-margin-start">
                  <IonCardTitle>Donate With Opel</IonCardTitle>
                  <IonCardSubtitle>Start a loyalty account at {businessInfo.business_name}.</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList className="ion-no-padding" lines="full">
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Donate to support.</h2>
                      </IonLabel>
                    </IonItem>
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Text us 1 on 1.</h2>
                      </IonLabel>
                    </IonItem>
                    <IonItem color="secondary">
                      <IonIcon icon={shieldCheckmarkOutline} slot="start" />
                      <IonLabel className="ion-text-wrap">
                        <h2>Receive updates via text.</h2>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <IonButton
                    className="ion-no-margin ion-margin-vertical"
                    type="submit"
                    expand="block"
                    onClick={acceptInvite}
                  >
                    Start Now
                  </IonButton>
                  <IonText color="medium">
                    By clicking start now, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a>. You may receive SMS Notifications from us and can opt out any time.
                  </IonText>
                </IonCardContent>
              </IonCard>
            )
          }

          return (
            <div style={{ padding: 10 }}>
              {firebase.auth().currentUser && <React.Fragment>
                {(screen !== 'success') && <React.Fragment>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" sizeSm="6">
                        <IonButton
                          expand="block"
                          fill="clear"
                          color={(screen === 'amount') ? 'primary' : 'secondary'}
                          onClick={() => setScreen('amount')}
                        >
                          <IonIcon slot="start" icon={checkmarkCircle} />
                            Select Amount
                          </IonButton>
                      </IonCol>
                      <IonCol size="12" sizeSm="6">
                        <IonButton
                          expand="block"
                          fill="clear"
                          color={(screen === 'pay') ? 'primary' : 'secondary'}
                          disabled={screen === '' || screen === 'amount'}
                          onClick={() => setScreen('pay')}
                        >
                          <IonIcon slot="start" icon={checkmarkCircle} />
                        Payment Method
                      </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {(screen !== 'amount' && screen !== '') && <PriceBreakDown
                    updatePaymentDetails={setPaymentDetails}
                    transferAmount={transferAmount}
                    tipAmount={tipAmount}
                    businessInfo={businessInfo}
                    type="donate"
                  />}
                </React.Fragment>}


                {(() => {
                  switch (screen) {
                    case 'success':
                      return (
                        <React.Fragment>
                          <IonCard color="secondary" className="ion-no-margin">
                            <IonCardHeader>
                              <IonCardTitle>
                                Donation Submitted
                              </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <p>If there are any issues we'll text you.</p>
                              <p>Thank you for supporting local businesses.</p>
                              <IonButton
                                className="ion-margin"
                                expand="block"
                                onClick={() => history.push(`/profile/${businessInfo.merchant_id}/activity`)}
                              >
                                Done
                              </IonButton>
                            </IonCardContent>
                          </IonCard>
                        </React.Fragment>
                      )
                    case 'pay':
                      return (
                        <React.Fragment>
                          <IonCard color="secondary" className="ion-no-margin">
                            <IonCardContent>
                              <SquarePayment
                                paymentDetails={paymentDetails}
                                tab={tab}
                                numberOfDrinks={undefined}
                                onSuccess={onSuccess}
                              />
                            </IonCardContent>
                          </IonCard>
                        </React.Fragment>
                      )
                    case 'amount':
                    default:
                      return (
                        <React.Fragment>
                          <AmountQuickSelect
                            title="Donate"
                            options={quickOptionsTotal}
                            defaultValue={{
                              formatted: '',
                              unformatted: 0,
                              selectedText: ''
                            }}
                            minimumAmount={100}
                            onNext={(value: any) => {
                              setScreen('pay');
                              setTranferAmount(value);
                            }}
                          />

                          {!businessInfo.only_donate && <React.Fragment>
                            <br />
                            <p className="ion-text-center">- OR -</p>
                            <IonButton fill="clear" color="secondary" expand="block" routerLink={`/profile/${businessInfo.merchant_id}/pay`}>PAY</IonButton>
                          </React.Fragment>}
                        </React.Fragment>
                      )
                  }

                })()}

              </React.Fragment>}
            </div>
          )

        })()}
      </BusinessProfileWrapper>
    </IonPage>
  )
}

export default BusinessProfileDonate
