import React from 'react'
import { IonPage, IonSplitPane, IonMenu, isPlatform } from '@ionic/react';

import ChatList from '../components/ChatList';
import { useChatStore } from '../store/ChatStore';


interface Props {
  title?: string;
  children: any;
  footer?: any;
}




const SplitPanePageWrapper: React.FC<Props> = (props) => {
  const secondaryMenuContent = useChatStore(({ secondaryMenuContent }) => secondaryMenuContent);

  return (
    <div>
      <IonSplitPane when="md" contentId="menuContent">
        {!(isPlatform('mobile') && !isPlatform('tablet')) && <IonMenu contentId="menuContent" menuId="primaryMenu" id="primaryMenu" type="overlay">
          <ChatList />
        </IonMenu>}

        {/*-- the main content --*/}
        <IonPage id="menuContent">
          {/* <IonHeader>
            <IonToolbar>
              <IonButtons className="ion-hide-sm-up" slot="start">
                <IonButton slot="start" onClick={() => toggleMenu('primaryMenu')}>
                  <IonIcon slot="icon-only" icon={menu} />
                </IonButton>
              </IonButtons>
              <IonTitle>{props.title}</IonTitle>
              <IonButtons className="ion-hide-sm-up" slot="end">
                <IonButton slot="start" onClick={() => toggleMenu('secondaryMenu')}>
                  <IonIcon slot="icon-only" icon={gitCompare} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent> */}
            {props.children}
          {/* </IonContent>
          {props.footer && <IonFooter>
            {props.footer}
          </IonFooter>} */}
        </IonPage>

        <IonMenu disabled={!secondaryMenuContent} side="end" contentId="menuContent" menuId="secondaryMenu" id="secondaryMenu" type="overlay">
          {secondaryMenuContent && <React.Fragment>
            {secondaryMenuContent}
          </React.Fragment>}
        </IonMenu>
      </IonSplitPane>
    </div>
  )
}

export default SplitPanePageWrapper;
