import React from 'react'
import { IonPage, IonContent, IonRow, IonCol, IonGrid } from '@ionic/react'
import MerchantHeader from '../../components/MerchantHeader'

// import firebase from 'firebase/app';
// import 'firebase/firestore';

interface Props {
  
}

const PhoneInfo: React.FC<Props> = () => {
  // useEffect(() => {
  //   firebase.firestore().collection('merchants');
  // }, [input])

  return (
   <IonPage>
     <MerchantHeader title="Phone Info" />
     <IonContent>
       <IonGrid>
         <IonRow>
           <IonCol size="12">
            <p>With Opel you get a phone number included!</p>
           </IonCol>
         </IonRow>
       </IonGrid>
     </IonContent>
   </IonPage>
  )
}

export default PhoneInfo
