import React from 'react'
import PageWrapper from '../../components/PageWrapper'
import CommunityFeed from '../common/CommunityFeed'
import firebase from 'firebase/app';
import 'firebase/auth';

// interface Props {
  
// }

const CommunityFeedRegular = () => {
  return (
    <PageWrapper title="News Feed">
      <CommunityFeed id={firebase.auth().currentUser.uid} />
    </PageWrapper>
  )
}

export default CommunityFeedRegular
