import { useIonViewWillEnter, IonSearchbar, useIonViewWillLeave, IonGrid, IonRow, IonCol, IonText, IonSkeletonText, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import PageWrapper from '../../components/PageWrapper';
import { FormatData } from '../../components/FormatData';
import MerchantSearch from './search/MerchantSearch';
import TabCard from '../../components/TabCard';

const TabList: React.FC = () => {
  const [tabsList, setTabsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [listener, setListener] = useState<any>(undefined);
  const [viewAll, setViewAll] = useState<any>(false);

  function handleChange(query: any) {
    const data: any = [];
    query.forEach((doc: any) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setTabsList(data);
    setLoading(false);
  }

  useIonViewWillEnter(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser && currentUser.uid) {
      const sub = firebase.firestore()
        .collection('tabs')
        .where('user_id', '==', currentUser.uid)
        .onSnapshot(handleChange);
      setListener(() => {
        return sub
      });
    }
  });


  useIonViewWillLeave(() => {
    if (listener) {
      listener();
    }
  }, [listener])


  return (
    <PageWrapper title="Tabs">
      <IonGrid>
        <IonRow>
          {(searchValue === '') && <IonCol size="2" sizeMd="1">
            <IonButton
              fill="clear"
              color="secondary"
              expand="block"
              onClick={() => { setViewAll((prevState: boolean) => !prevState) }}
            >
              {viewAll ? `X` : `ALL`}
            </IonButton>
          </IonCol>}
          <IonCol size={(searchValue !== '') ? `12` : `10`} sizeMd={(searchValue !== '') ? `12` : `11`}>
            <IonSearchbar 
              className="ion-no-padding" 
              showCancelButton={(searchValue !== '') ? 'always' : 'never'} 
              placeholder="Search tabs and merchants" 
              onInput={(e: any) => setSearchValue(e.target.value)} 
              onIonClear={() => {
                setSearchValue('');
                setViewAll(false);
              }}
            />
          </IonCol>

        </IonRow>
      </IonGrid>

      {(searchValue !== '' || viewAll) && <MerchantSearch searchValue={searchValue} tabsList={tabsList} />}
      {(searchValue === '' && !viewAll) && <IonGrid>
        <IonRow>
          {(tabsList.length < 1 && loading) && <IonCol size={`12`} sizeSm={`6`} sizeMd={`4`} sizeLg={`4`} sizeXl={`3`}>
            <TabCard
              subtitle={<React.Fragment><IonSkeletonText /></React.Fragment>}
              title={<React.Fragment><IonSkeletonText /></React.Fragment>}
            />
          </IonCol>}
          {(tabsList.length < 1 && !loading) && <IonCol size="12">
            <IonText>
              <h3>Welcome aboard!</h3>
              <p>Search for a merchant to get started.</p>
            </IonText>
          </IonCol>}
          {tabsList.map((item, key) => (
            <IonCol size={`12`} sizeSm={`6`} sizeMd={`4`} sizeLg={`4`} sizeXl={`3`} key={key}>
              <TabCard
                className='ion-no-margin'
                routerLink={`/profile/${item.merchant_id}`}
                subtitle={<React.Fragment>Balance: <FormatData value={item.balance} type={"dollar"} loading={loading} /></React.Fragment>}
                title={<React.Fragment><FormatData value={item.merchant_name} type={"text"} loading={loading} /></React.Fragment>}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>}
    </PageWrapper>
  );
};

export default TabList;
