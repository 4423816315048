import React, { useState, useEffect } from "react";
import {
  StatusUpdateForm,
  FlatFeed,
  Activity,
  LikeButton,
  // CommentField,
  // CommentList,
  // CommentItem,
} from "react-activity-feed";
import "react-activity-feed/dist/index.css";

import firebase from "firebase/app";
import "firebase/functions";
import moment from "moment";
import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonAlert,
} from "@ionic/react";

const CommunityFeed = (props) => {
  const [textPost, setTextPost] = useState(false);
  const [toast, setToast] = useState({});
  const [textPostInfo, setTextPostInfo] = useState(false);

  async function createPost(data) {
    try {
      const createStreamPost = firebase
        .functions()
        .httpsCallable("createStreamPost");
      let formattedData = data;
      delete formattedData.actor;
      var date = moment.utc().format();
      formattedData.time = moment.utc(date).local().format();
      formattedData.foreign_id = moment.now().toString();
      if (textPost) {
        return createPostAndText(formattedData);
      }
      await createStreamPost({
        activity: {
          ...formattedData,
        },
        merchant_id: props.id,
      });
      setToast({
        isOpen: true,
        message: 'Your post has been sent',
        color: 'primary'
      });
      return true;
    } catch (error) {
      setToast({
        isOpen: true,
        message: error.message,
        color: 'danger'
      });
    }
  }

  async function createPostAndText(formattedData) {
    try {
      const createCampaignAndPost = firebase
        .functions()
        .httpsCallable("createCampaignAndPost");
      await createCampaignAndPost({
        activity: {
          ...formattedData,
        },
        merchant_id: props.id,
      });
      setToast({
        isOpen: true,
        message: 'Your post has been sent',
        color: 'primary'
      });
    } catch (error) {
      setToast({
        isOpen: true,
        message: error.message,
        color: 'danger'
      });
    }
  }

  useEffect(() => {
    if (textPost) {
      setTextPostInfo(true);
    }
  }, [textPost])

  return (
    <div style={{margin: "auto" }}>
      <IonToast
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({isOpen: false})}
        message={toast.message}
        duration={6000}
        color={toast.color}
        position="top"
      />
      <IonAlert
        isOpen={textPostInfo}
        onDidDismiss={() => setTextPostInfo(false)}
        buttons={['Got it!']}
        header={'Text post to customers'}
        subHeader={'This post will be sent to all subscribed customers.'}
        message={'This can only be sent once per day and if this post is longer than 160 characters a preview will be sent.'}
      />
      {props.isMerchant && (
        <React.Fragment>
          <StatusUpdateForm
            doRequest={createPost}
            Header={(() => (
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-align-items-center">
                  <IonCol size="6">
                    <div className="raf-title">New Post</div>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem
                      lines="none"
                      color="secondary"
                      className="ion-no-padding ion-no-margin"
                    >
                      <IonLabel
                        style={{ marginBottom: 13 }}
                        className="ion-text-end"
                      >
                        Text post to all customers:
                      </IonLabel>
                      <IonCheckbox
                        style={{ marginLeft: 5 }}
                        className="ion-no-padding"
                        color="tertiary"
                        slot="end"
                        checked={textPost}
                        onIonChange={(e) => setTextPost(e.detail.checked)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ))()}
          />
        </React.Fragment>
      )}

      <FlatFeed
        feedGroup="user"
        userId={props.profileId ? props.profileId : `global`}
        options={{ reactions: { recent: true } }}
        notify
        Activity={(props) => {
          return (
            <Activity
              {...props}
              Footer={() => (
                <React.Fragment>
                  <div
                    style={{ padding: "8px 16px" }}
                    className="raf-like-button"
                  >
                    <LikeButton {...props} />
                  </div>
                </React.Fragment>
              )}
            />
          );
        }}
      />
    </div>
  );
};

export default CommunityFeed;
