import React, { useEffect, useState } from 'react'
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonCard, IonCardContent, IonToast, IonText, IonCardHeader, IonCardTitle, IonProgressBar, IonCardSubtitle } from '@ionic/react';
import * as queryString from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useParams } from 'react-router';
import phone from 'phone';

interface Props {
  noRedirectSuccess?: boolean;
}

const Signin: React.FC<Props> = (props) => {
  const params:any = useParams();
  const {noRedirectSuccess} = props;
  const [error, setError] = useState<any>();
  const [identifier, setIdentifier] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationScreen, setConfirmationScreen] = useState();
  const [passwordScreen, setPasswordScreen] = useState(false);
  const [query] = useState(queryString.parse(window.location.search));
  const [forgotPasswordScreen, setForgotPasswordScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query && query.phone) {
      setIdentifier((prevPhone: any) => prevPhone + query.phone);
    }
  }, [query]);

  async function submitInitialForm(e: any) {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
      });

      const validatedPhoneNumber = phone(identifier, 'USA');
      if (validatedPhoneNumber.length > 0) {
        const confirmation = await firebase.auth().signInWithPhoneNumber(validatedPhoneNumber[0], window.recaptchaVerifier);
        setConfirmationScreen(confirmation);
      } else {
        setPasswordScreen(true)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const user = firebase.auth().currentUser;
      setError(error);
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
      if(window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    }

  }

  async function signPhoneInWithCode(e: any) {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();
      const result = await confirmationScreen.confirm(code);
      setLoading(false);
      if (!!result && !noRedirectSuccess) {
        window.location.href = '/';
      }
    } catch (error) {
      setLoading(false);
      const user = firebase.auth().currentUser;
      setError(error);
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }
  }

  async function signInWithPassword(e: any) {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();

      const result = await firebase.auth().signInWithEmailAndPassword(identifier, password);
      setLoading(false);
      if (!!result && !noRedirectSuccess) {
        window.location.href = '/';
      }
    } catch (error) {
      setLoading(false);
      const user = firebase.auth().currentUser;
      setError(error);
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }
  }

  async function forgotPassword(e: any) {
    try {
      setLoading(true);
      await firebase.auth().sendPasswordResetEmail(identifier);
      setPasswordScreen(false);
      setForgotPasswordScreen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const user = firebase.auth().currentUser;
      setError(error);
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }
  }

  return (
    <IonPage>
      {loading && <IonProgressBar type={'indeterminate'} />}
      <IonContent className="ion-padding">
        <div id="recaptcha"></div>
        <div style={{ maxWidth: 400, margin: 'auto' }}>
          <a href="/">
            <img
              style={{
                maxWidth: 100,
                margin: 'auto',
                display: 'block',
              }}
              src="/assets/logo.svg"
              alt="Opel"
            />
          </a>
          <h1 className="ion-padding-horizontal">
            Log in:
          </h1>
          <IonToast
            position="top"
            isOpen={!!error}
            onDidDismiss={() => setError(undefined)}
            message={error ? error.message : ''}
            duration={4000}
          />
          {forgotPasswordScreen && <IonCard color="secondary">
            <IonCardHeader>
              <IonCardTitle>Reset Password Email Sent:</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>Check your inbox for an email to reset your password.</p>
            </IonCardContent>
          </IonCard>}
          {passwordScreen && <form onSubmit={signInWithPassword}>
            <IonCard color="secondary">
              <IonCardContent>
                <IonList lines="full" className="ion-no-padding">
                  <IonItem color="secondary">
                    <IonLabel>Signing in as: {identifier}</IonLabel>
                  </IonItem>
                  <IonItem color="dark">
                    <IonLabel position="stacked">
                      Password:
                    </IonLabel>
                    <IonInput
                      type="password"
                      name="name"
                      value={password}
                      onIonChange={(e: any) => setPassword(e.target.value)}
                    />
                  </IonItem>
                  {error && <IonItem color="danger">
                    <IonLabel>
                      {error.message}
                    </IonLabel>
                  </IonItem>}
                  <IonItem lines="none" button detail onClick={forgotPassword} color="secondary">
                    <IonLabel>Forgot password</IonLabel>
                  </IonItem>

                </IonList>
                <br />
                <IonButton type="submit" expand="block">
                  Done
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>}
          {confirmationScreen && <form onSubmit={signPhoneInWithCode}>
            <IonCard color="secondary">
              <IonCardContent>
                <IonList lines="full" className="ion-no-padding">
                  <IonItem color="secondary">
                    <IonLabel>Check your phone: {identifier}</IonLabel>
                  </IonItem>
                  <IonItem color="dark">
                    <IonLabel position="stacked">
                      Code
                    </IonLabel>
                    <IonInput
                      type="text"
                      name="name"
                      value={code}
                      onIonChange={(e: any) => setCode(e.target.value)}
                    />
                  </IonItem>
                  {error && <IonItem color="danger">
                    <IonLabel>
                      {error.message}
                    </IonLabel>
                  </IonItem>}
                </IonList>
                <br />
                <IonButton type="submit" expand="block">
                  Done
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>}
          {(!confirmationScreen && !passwordScreen) && <form onSubmit={submitInitialForm}>
            <IonCard color="secondary">
              <IonCardHeader className="ion-padding-horizontal ion-margin-horizontal">
                <IonCardSubtitle>Use your Opel Account</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList lines="full" className="ion-no-padding">
                  <IonItem color="dark">
                    <IonLabel position="floating">Phone number or email</IonLabel>
                    <IonInput
                      className="ion-padding"
                      value={identifier}
                      onIonChange={(e: any) => setIdentifier(e.target.value)}
                    />
                  </IonItem>
                </IonList>
                <div className="ion-padding">
                  <IonText color="medium">
                    By clicking Log In, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a>. You may receive SMS Notifications from us and can opt out any time.
                  </IonText>
                </div>
                <IonButton type="submit" expand="block">
                  Log In
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>}
          {(() => {
            const user = firebase.auth().currentUser;
            if (!user) {
              return (
                <div className="ion-margin ion-padding">
                  <IonButton
                    // className="ion-margin-top"
                    routerLink={(params && params.inviteId) ? `/r/${params.inviteId}` : `/r`}
                    // fill="outline"
                    color="light"
                    expand="block"
                  >Sign up as a customer</IonButton>
                  <IonButton
                    className="ion-margin-top"
                    routerLink="/signupBusiness"
                    // fill="outline"
                    color="light"
                    expand="block"
                  >Sign up as a business</IonButton>
                </div>
              )
            }
          })()}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Signin
