import React from 'react'
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonText, IonHeader, IonToolbar, IonButtons } from '@ionic/react'


interface Props {

}

declare var window: any;

const Home: React.FC<Props> = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="container">
          <IonButtons slot="end">
            <IonButton routerLink="/login">Log in</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <IonGrid>
            <IonRow style={{ height: '100%' }} className="ion-align-items-end ion-justify-content-center">
              <IonCol size="12" sizeMd="12">
                <a href="/">
                  <img
                    style={{
                      maxWidth: 150,
                      margin: '10px auto 20px',
                      display: 'block',
                    }}
                    src="/assets/logo.svg"
                    alt="Opel"
                  />
                </a>
                <IonText className="ion-text-center ion-justify-content-center">
                  <h1 className="ion-text-center" style={{ fontSize: '2.3rem' }}>The all new Opel Pay.</h1>
                  <h4>See it in action:</h4>
                  <div className="ion-justify-content-center">
                    <br />
                    <IonButton href="https://opelpay.com/" className="ion-no-margin" size="large">OpelPay.com</IonButton>
                  </div>
                </IonText>

              </IonCol>

            </IonRow>
          </IonGrid>

        </div>

      </IonContent>
    </IonPage>
  )
}

export default Home
