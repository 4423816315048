import React, {useState} from 'react'
import { IonItem, IonLabel, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonList, IonListHeader } from '@ionic/react'
import firebase from 'firebase/app';
import 'firebase/firestore';

interface Props {
  merchant: any;
}

const UploadMenu = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { merchant } = props;

  async function uploadFile(e: any) {
    e.preventDefault();
    let file = e.target.files[0];
    const result = await firebase.storage().ref().child(`menu/${merchant.merchant_id}`).put(file);
    const url = await result.ref.getDownloadURL();
    return firebase.firestore().collection('merchants').doc(merchant.merchant_id).update({
      menu_url: url
    })
  }
  
  return (
    <React.Fragment>
      <IonItem button onClick={() => setIsOpen(true)}>
        <IonLabel>Upload a menu (PDF or Image)</IonLabel>
      </IonItem>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Upload a menu</IonTitle>
            <IonButton fill="clear" slot="end" onClick={() => setIsOpen(false)}>
              Close
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList lines="none">
  <IonListHeader>Upload a file (PDF or Image) {merchant.menu_url && <a style={{paddingLeft: 5}} rel="noopener noreferrer" href={merchant.menu_url} target="_blank">Preview existing file</a>}</IonListHeader>
            <IonItem>
              <input accept="application/pdf,image/*" type="file" onChange={uploadFile} />
            </IonItem>
          </IonList>
          <IonButton expand="block" color="secondary" onClick={() => setIsOpen(false)}>
            Done
          </IonButton>
        </IonContent>
      </IonModal>
    </React.Fragment>
  )
}

export default UploadMenu
