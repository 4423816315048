import React, { useState, useEffect } from 'react';
import { IonIcon, IonButton, IonList, IonBadge, IonItem, IonLabel, IonSelect, IonSelectOption, IonContent, IonHeader, IonToolbar, IonButtons, IonPopover, IonFooter, IonListHeader, IonSearchbar, IonAvatar } from '@ionic/react';
import { FormatData } from './FormatData';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { ellipsisHorizontal } from 'ionicons/icons';
import { useChatStore, ChatStore } from '../store/ChatStore';
import { useAuthStore } from '../store/AuthStore';
import { useHistory } from 'react-router';
import CreateMessage from './merchant/CreateMessage';
import md5 from 'md5';
import CustomerSearchList from './merchant/CustomerSearchList';

interface Props {

}

const ChatList: React.FC<Props> = () => {
  let history = useHistory();
  const { merchant } = useAuthStore((store) => store);
  const { chatId, status } = useChatStore(({ chatId, status }) => ({ chatId, status }));
  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState<any>([]);
  const [newAction, setNewAction] = useState<any>(false);
  const [limitValue, setLimitValue] = useState(30);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    function handleList(query: any) {
      const items: any = [];
      query.forEach((doc: any) => {
        items.push({
          id: doc.id,
          ...doc.data()
        })
      })
      setList(items);
    }

    let sub;
    if (status !== 'all') {
      sub = firebase.firestore().collection('chats')
        .where('merchant_id', '==', merchant.merchant_id)
        .where('status', '==', status)
        .orderBy('updated_at', 'desc')
        .limit(limitValue)
        .onSnapshot(handleList)
    } else {
      sub = firebase.firestore().collection('chats')
        .where('merchant_id', '==', merchant.merchant_id)
        .orderBy('updated_at', 'desc')
        .limit(limitValue)
        .onSnapshot(handleList)
    }
    return sub;
  }, [status, merchant.merchant_id, limitValue]);

  function loadMoreRecords() {
    setLimitValue((prevState: any) => prevState + 30);
  }

  useEffect(() => {
    if (searchValue.length > 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [searchValue])

  function toggleSearchList() {
    if (searchValue.length > 0) {
      setShowSearch(true);
    } else {
      setShowSearch((prevState:any) => !prevState);
    }
  }


  return (
    <React.Fragment>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar
            onIonChange={(e:any) => setSearchValue(e.target.value)}
            value={searchValue}
            slot="start"
            showCancelButton={searchValue.length > 0 ? `always` : `focus`}
            onIonFocus={toggleSearchList}
            onIonBlur={toggleSearchList}
          />
          <IonButtons slot="end">
           <CreateMessage />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showSearch && <CustomerSearchList updateSearchValue={setSearchValue} searchValue={searchValue} />}
        {!showSearch && <IonList className="ion-no-padding" lines="full">
          <IonItem color="light">
            <IonLabel>Conversation Status:</IonLabel>
            <IonSelect value={status} onIonChange={(e: any) => ChatStore.set({ status: e.target.value })} placeholder="Select One" interface="popover">
              <IonSelectOption value="open">Open</IonSelectOption>
              <IonSelectOption value="archived">Archived</IonSelectOption>
              <IonSelectOption value="all">All</IonSelectOption>
            </IonSelect>
          </IonItem>
          {/* <IonListHeader color="light">Hello</IonListHeader> */}
          {list.map((item: any, key: any) => {
            return (
              <IonItem
                button
                key={key}
                color={chatId === item.id ? 'primary' : undefined}
                onClick={() => {
                  const element: any = document.getElementById('primaryMenu');
                  if(element && element.close) {
                    element.close();
                  }
                  history.push(`/chats/${item.id}`);
                }}
              >
                <IonAvatar slot="start">
                  <img alt={item.user_display_name} src={`https://www.gravatar.com/avatar/${md5(item.user_phone)}?d=identicon`} />
                </IonAvatar>
                <IonLabel>
                  <h2><FormatData type="text" value={item.user_display_name} /></h2>
                  <h3><FormatData type="text" value={item.recent_message} /></h3>
                  <p><FormatData type="date" value={item.updated_at} /></p>
                </IonLabel>
                {(item.unread > 0) && <IonBadge color={chatId === item.id ? 'light' : 'primary'} slot="end">{item.unread}</IonBadge>}
              </IonItem>
            )
          })}

          {(list.length % 30 === 0 && list.length !== 0) && <IonItem button onClick={loadMoreRecords}>
            <IonLabel>
              Load more
            </IonLabel>
          </IonItem>}
          {(list.length === 0) && <IonListHeader>
            No Conversations Found
          </IonListHeader>}
        </IonList>}
      </IonContent>
      <IonFooter>
        <IonButton 
          className="ion-no-margin"
          expand="full" 
          size="large"
          color="secondary"
          fill="clear"
          onClick={(e) => {
            e.persist();
            setNewAction(e)
          }}
        >
          MENU
          <IonIcon slot="end" icon={ellipsisHorizontal} />
        </IonButton>

        <IonPopover
          isOpen={!!newAction}
          onDidDismiss={() => setNewAction(false)}
          event={newAction}
        >
          <IonList className="ion-no-padding">
            {/* <IonItem color="secondary" button onClick={() => {
              history.push('/addCustomers')
              setNewAction(false);
            }}>
              <IonLabel>Invite Customers</IonLabel>
            </IonItem> */}
            <IonItem color="secondary" button onClick={() => {
              history.push(`/posts`)
              setNewAction(false);
            }}>
              <IonLabel>Posts</IonLabel>
            </IonItem>
            <IonItem color="secondary" button onClick={() => {
              history.push('/orders')
              setNewAction(false);
            }}>
              <IonLabel>
                Orders
              </IonLabel>
            </IonItem>
            <IonItem color="secondary" button onClick={() => {
              history.push('/transfers')
              setNewAction(false);
            }}>
              <IonLabel>
                Payments
                </IonLabel>
            </IonItem>
            <IonItem color="secondary" button onClick={() => {
              history.push('/settings');
              setNewAction(false);
            }}>
              <IonLabel>Settings</IonLabel>
            </IonItem>
          </IonList>
        </IonPopover>

      </IonFooter>

    </React.Fragment>
  )
}

export default ChatList
