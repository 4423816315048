import React from 'react'
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, isPlatform, IonBackButton, useIonViewWillEnter } from '@ionic/react'
import { menu, gitCompare } from 'ionicons/icons';
import { useChatStore } from '../store/ChatStore';
import isString from 'lodash/isString';

interface Props {
  title?: any;
  secondaryToolbar?: any;
  secondary?: any;
}



const MerchantHeader: React.FC<Props> = (props) => {
  const { secondaryMenuContent } = useChatStore((store) => store);

  function toggleMenu(elementId: any) {  
    const element:any = document.getElementById(elementId);
    element.toggle();
  }

  useIonViewWillEnter(() => {
    if(props.title && window.analytics && isString(props.title)) {
      document.title = props.title + " | Opel, Inc.";
      window.analytics.page(document.title);
    }
  }, [props.title])

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons className="ion-hide-md-up" slot="start">
          {(() => {
            if (isPlatform('mobile') && !isPlatform('tablet')) {
              return (
                <IonBackButton defaultHref="/" />
              )
            }
            return (
              <IonButton slot="start" onClick={() => toggleMenu('primaryMenu')}>
                <IonIcon slot="icon-only" icon={menu} />
              </IonButton>
            )
          })()}
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
        {props.secondary && props.secondary}
        {secondaryMenuContent && <IonButtons className="ion-hide-md-up" slot="end">
          <IonButton slot="start" onClick={() => toggleMenu('secondaryMenu')}>
            <IonIcon slot="icon-only" icon={gitCompare} />
          </IonButton>
        </IonButtons>}
      </IonToolbar>
      {props.secondaryToolbar && <IonToolbar>
        {props.secondaryToolbar}
      </IonToolbar>}
    </IonHeader>
  )
}

export default MerchantHeader
