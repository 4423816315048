import { IonGrid, IonRow, IonCol, IonButton, useIonViewWillEnter, useIonViewWillLeave, IonIcon, IonFooter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import PageWrapper from '../../../components/PageWrapper';
import { RouteComponentProps, useHistory, useParams } from 'react-router';
import { AddTransfer } from './AddTransfer';
import { TransactionsList } from './TransactionsList';
import TabCard from '../../../components/TabCard';
import { FormatData } from '../../../components/FormatData';
import { personAdd } from 'ionicons/icons';
import { Confirmation } from './Confirmation';


declare var navigator:any;

interface Props extends RouteComponentProps<{
  tabId: string;
}> { }

const Index: React.FC<Props> = ({ match }) => {
  const [item, setItem] = useState({
    merchant_name: undefined,
    balance: undefined,
    merchant_id: undefined,
    merchant_phone: undefined,
    tab_id: undefined,
    user_phone: undefined,
    user_id: undefined,
    user_display_name: undefined
  });
  let {screen, tabId} = useParams();
  let history = useHistory();
  const [listener, setListener] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState<any>({});
  const [merchant, setMerchant] = useState<any>(undefined);

  useEffect(() => {
    async function init() {
      const doc = await firebase.firestore().collection('merchants').doc(item.merchant_id).get();
      setMerchant(doc.data());
    }
    if(!merchant && item && item.merchant_id) {
      init();
    }
  }, [item, merchant]);

  function setScreen(item: string) {
    if(item === 'addTransfer') {
      history.push(`/tabs/${tabId}/${item}`, {
        direction: 'none'
      });
    } else {
      history.replace(`/tabs/${tabId}/${item}`, {
        direction: 'none'
      });
    }
  }

  useIonViewWillEnter(() => {
    if (match && match.params && match.params.tabId) {
      const sub = firebase.firestore().collection('tabs').doc(match.params.tabId).onSnapshot(handleData);
      setListener(() => {
        return sub
      });
    }
  }, [match]);

  useIonViewWillLeave(() => {
    if(listener) {
      listener();
    }
  }, [listener]);

  useEffect(() => {
    async function init() {
      const userId = firebase.auth().currentUser.uid;
      const displayName = firebase.auth().currentUser.displayName;
      const inviteList:any = await firebase.firestore().collection('invites').where('sent_by_user_id', '==', userId).where('merchant_id', '==', item.merchant_id).get();
      let invite:any;
      if(inviteList.empty) {
        invite = {
          merchant_id: item.merchant_id,
          merchant_name: item.merchant_name,
          merchant_phone: item.merchant_phone,
          status: 'CREATED',
          is_user_sent: true,
          sent_by_user_id: userId,
          sent_by_user_display_name: displayName,
          created_at: firebase.firestore.FieldValue.serverTimestamp()
        }
        const inviteDoc:any = await firebase.firestore().collection('invites').add(invite);
        invite.id = inviteDoc.id;
      }
      if(!invite) {
        invite = inviteList.docs[0].data();
        invite.id = inviteList.docs[0].id;
      }
      setInvite(invite);
    }

    if(item.merchant_id) {
      init();
    }
  }, [item]);


  function handleData(doc: any) {
    setItem({ ...doc.data(), tab_id: doc.id });
    setLoading(false);
  }

  async function inviteUser() {
    if(navigator.share && invite.id) {
      
      return navigator.share({
        title: `Invite To ${item.merchant_name}`,
        text: `I have a regulars account at ${item.merchant_name}. Here is an invite so you can donate or pay ahead.`,
        url: `https://opel.org/r/${invite.id}`
      }).then(() => {
        console.log('thanks for sharing');
      }).catch(() => {
        console.log('oh no!');
      })
    }
  }

  return (
    <PageWrapper 
      title={item.merchant_name} 
      endComponent={<IonButton fill="clear" onClick={inviteUser} color="secondary">
        <IonIcon slot="icon-only" icon={personAdd} />
      </IonButton>}
      footer={(screen !== 'addTransfer') && <IonFooter>
        {(merchant && merchant.menu_url) && <IonButton target="_blank" href={ merchant.menu_url} className="ion-no-margin" size="large" expand="full" color="primary">Menu (PDF)</IonButton>}
      </IonFooter>}
      isChild
    >
      {(screen !== 'addTransfer') && <div className="card-margin">
        <TabCard
          className="ion-no-margin"
          subtitle={`Balance`}
          title={<React.Fragment><FormatData value={item.balance} type={`dollar`} loading={loading} /></React.Fragment>}
          // headerAction={<IonButton className="ion-float-right" onClick={() => setScreen('addTransfer')}  fill="clear">
          //   <span style={{textDecoration: 'underline'}}>Add / Donate</span>
          // </IonButton>}
        />
      </div>}
      {(screen !== 'addTransfer' && screen !== 'confirmation') && <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton
              color={(screen === 'orders') ? 'secondary' : 'primary'}
              onClick={() => setScreen('orders')}
              expand="full"
              style={{textDecoration: (screen === 'orders') ? 'underline' : 'none'}}
            >Orders</IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              color={(screen === 'transfers') ? 'secondary' : 'primary'}
              onClick={() => setScreen('transfers')}
              expand="full"
              style={{textDecoration: (screen === 'transfers') ? 'underline' : 'none'}}
            >Transfers</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>}
      {(() => {
        switch (screen) {
          case 'orders':
            return <TransactionsList screen={screen} tabId={match.params.tabId} />
          case 'transfers':
            return <TransactionsList screen={screen} tabId={match.params.tabId} />
          case 'addTransfer':
            return <AddTransfer tab={item} updateScreen={setScreen} />
          case 'confirmation':
            return <Confirmation updateScreen={setScreen} />
          default:
            return <TransactionsList screen={'orders'} tabId={match.params.tabId} />
        }
      })()}
    </PageWrapper>
  );
};

export default Index;
