import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { chatboxOutline } from 'ionicons/icons';

interface MenuProps extends RouteComponentProps {
  appPages: any;
}

declare var Beacon: any;

const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const { appPages } = props;

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="full">
          {appPages.map((page: any, index: any) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem routerLink={page.url}>
                  <IonIcon slot="start" icon={page.icon} />
                  <IonLabel>{page.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem button onClick={() => {
            Beacon('open')
          }}>
            <IonIcon slot="start" icon={chatboxOutline} />
            <IonLabel>Support</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default withRouter(Menu);
