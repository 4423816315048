import React, { useEffect, useState } from 'react'
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonInput, IonProgressBar, IonButton, IonCard, IonCardContent, IonToast, IonText, IonGrid, IonCol, IonRow, IonHeader, IonToolbar, IonButtons, IonIcon } from '@ionic/react';
import * as queryString from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useParams } from 'react-router';
import phone from 'phone';
import RegisterUserComponent from '../../components/RegisterUserComponent';
import { arrowForwardCircle } from 'ionicons/icons';

interface Props {
  noRedirectSuccess?: boolean;
  noIonPage?: boolean;
}

const SignupRegular: React.FC<Props> = (props) => {
  const params: any = useParams();
  const { noRedirectSuccess } = props;
  const [error, setError] = useState<any>();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [code, setCode] = useState('');
  const [confirmationScreen, setConfirmationScreen] = useState<any>();
  const [query] = useState(queryString.parse(window.location.search));
  const [phoneNumberEntered, setPhoneNumberEntered] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query && query.phone) {
      setPhoneNumber((prevPhone: any) => prevPhone + query.phone);
    }
    const user = firebase.auth().currentUser;
    if (user && user.displayName) {
      setDisplayName(user.displayName);
    }
    if (user && user.email) {
      setEmail(user.email);
    }
    if (user && user.phoneNumber) {
      setPhoneNumberEntered(true);
    }
  }, [query]);

  async function submitPhone(e: any) {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
      });
      let user = firebase.auth().currentUser;
      if (user) {
        await user.updateEmail(email);
        await user.updateProfile({ displayName: displayName });
      }

      if (!user || !user.phoneNumber) {
        const validatedPhone = phone(phoneNumber, 'USA');
        const confirmation: any = await firebase.auth().signInWithPhoneNumber(validatedPhone[0], window.recaptchaVerifier);
        setConfirmationScreen(confirmation);
      } else {
        window.location.reload()
      }
      setLoading(false);
    } catch (error) {
      const user = firebase.auth().currentUser;
      setError(error);
      setLoading(false);
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        document.getElementById('recaptcha-wrapper').innerHTML = '<div id="recaptcha"></div>';
      }
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }

  }

  async function signPhoneInWithCode(e: any) {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();
      const result: any = await confirmationScreen.confirm(code);

      if (!!result && !noRedirectSuccess) {
        window.location.href = '/';
      } else if (!!result && noRedirectSuccess) {
        window.location.reload();
      }
      setLoading(false);

    } catch (error) {
      const user = firebase.auth().currentUser;
      setError(error);
      setLoading(false);
      if (user && user.isAnonymous) {
        return firebase.auth().currentUser.delete()
      }
    }
  }

  async function signOut() {
    await firebase.auth().signOut();
    window.location.href = '/';
  }

  const ActualLoginDom = (
    <React.Fragment>
      {loading && <IonProgressBar type={'indeterminate'} />}
      <div id="recaptcha-wrapper"><div id="recaptcha"></div></div>
      <div style={{ maxWidth: 400, margin: 'auto' }}>
        <a href="/">
          <img
            style={{
              maxWidth: 100,
              margin: 'auto',
              display: 'block',
            }}
            src="/assets/logo.svg"
            alt="Opel"
          />
        </a>
        <h2 className="ion-padding-horizontal ion-padding-bottom">
          {(() => {
            const user = firebase.auth().currentUser;
            if (!user || (user && user.isAnonymous)) {
              return `Sign up as a customer`;
            }

            return `Edit Info`;
          })()}
        </h2>
        <IonToast
          position="top"
          isOpen={!!error}
          onDidDismiss={() => setError(undefined)}
          message={error ? error.message : ''}
          duration={4000}
        />
        {confirmationScreen && <form onSubmit={signPhoneInWithCode}>
          <IonCard color="secondary">
            <IonCardContent>
              <IonGrid>
                <IonRow className="ion-align-items-center">
                  <IonCol size="auto">
                    <p>Check your phone: {phoneNumber}</p>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonList className="ion-no-padding">
                <IonItem color="secondary">
                  <IonLabel position="floating">
                    Code
                  </IonLabel>
                  <IonInput
                    type="text"
                    name="name"
                    value={code}
                    onIonChange={(e: any) => setCode(e.target.value)}
                  />
                </IonItem>
                <IonItem color="secondary" lines="none">
                  <IonText color="medium" className="ion-text-wrap ion-padding-top">
                    Please wait at least 30 seconds to receive a confirmation code depending upon cell service or <button style={{ background: 'transparent', color: 'inherit', fontSize: '1rem', padding: 0, textDecoration: 'underline' }} onClick={() => window.location.reload()}>refresh</button>.
                    </IonText>
                </IonItem>
                {error && <IonItem color="danger">
                  <IonLabel className="ion-text-wrap">
                    {error.message}
                  </IonLabel>
                </IonItem>}
              </IonList>
              <br />
              <IonButton type="submit" expand="block">
                Done
                </IonButton>
            </IonCardContent>
          </IonCard>
        </form>}
        {!confirmationScreen && <form onSubmit={submitPhone}>
          <IonCard color="secondary">
            <IonCardContent>
              <IonList className="ion-no-padding">
                {phoneNumberEntered && <IonItem color="secondary">
                  <IonLabel position="floating">
                    Full Name:*
                    </IonLabel>
                  <IonInput
                    required
                    type="text"
                    name="name"
                    value={displayName}
                    onIonChange={(e: any) => setDisplayName(e.target.value)}
                  />
                </IonItem>}
                {phoneNumberEntered && <IonItem color="secondary">
                  <IonLabel position="floating">
                    Email:*
                    </IonLabel>
                  <IonInput
                    required
                    type="email"
                    value={email}
                    onIonChange={(e: any) => setEmail(e.target.value)}
                  />
                </IonItem>}
                {!phoneNumberEntered && <IonItem color="secondary">
                  <IonLabel position="floating">
                    Phone:*
                    </IonLabel>
                  <IonInput
                    required
                    type="tel"
                    value={phoneNumber}
                    onIonChange={(e: any) => setPhoneNumber(e.target.value)}
                  />
                </IonItem>}
              </IonList>
              <div className="ion-padding">
                <IonText color="medium">
                  By clicking the button below, you agree to our <a href="/terms.html" target="_blank">Terms and Conditions</a> and our <a href="/privacy.html" target="_blank">Privacy Policy</a>. You may receive SMS Notifications from us and can opt out any time.
                  </IonText>
              </div>
              <IonButton type="submit" expand="block">
                {(() => {
                  const user = firebase.auth().currentUser;
                  if (!user || (user && user.isAnonymous)) {
                    return `Sign Up`;
                  }

                  return `Done`;
                })()}
              </IonButton>
            </IonCardContent>
          </IonCard>
        </form>}
        {(() => {
          const user = firebase.auth().currentUser;
          if (!user) {
            return (
              <IonGrid>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="auto">
                    <IonButton
                      routerLink={(params && params.inviteId) ? `/login/${params.inviteId}` : `/login`}
                      fill="clear"
                      color="dark"
                    >Log in</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          }
        })()}
        {(() => {
          const user = firebase.auth().currentUser;
          if (user && user.email && user.phoneNumber && user.email) {
            return (
              <IonGrid>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="auto">
                    <IonButton
                      onClick={() => window.location.href = '/'}
                      fill="clear"
                      color="dark"
                    >Go Home</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          }
        })()}
        {(firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous) && <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonButton
                onClick={signOut}
                fill="clear"
                color="dark"
              >Sign out</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>}
      </div>
    </React.Fragment>
  )

  if (props.noIonPage) {
    return <React.Fragment>
      {ActualLoginDom}
    </React.Fragment>
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar className="container">

          <IonButton fill="clear" slot="start" routerLink="/">
            <IonIcon slot="icon-only" src="/assets/logo.svg" />
          </IonButton>

          <IonButtons slot="start">
            <IonButton href="/business.html">Are you a business? Learn more <IonIcon slot="end" icon={arrowForwardCircle} /></IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton routerLink="/login">Log in</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <RegisterUserComponent />
      </IonContent>
    </IonPage>
  )
}

export default SignupRegular
