import React, { useState } from 'react'
import { IonHeader, IonContent, IonToolbar, IonTitle, IonButton, IonList, IonItem, IonLabel, IonInput } from '@ionic/react'



interface Props {
  done: any;
  onAction: any;
}

const CustomAmount = (props: Props) => {
  const [name, setName] = useState('');
  const [customAmount, setCustomAmount] = useState({
    formatted: '',
    unformatted: 0
  })

  function handleCustomAmount(eventValue: any) {
    let removedPrefix = eventValue.split('$');
    if(removedPrefix.length === 1) {
      const newValue = `$${eventValue}`;
      removedPrefix = newValue.split('$');
    }
    const newAmount:any = Number(removedPrefix[1]);
    setCustomAmount({
      formatted: `$${removedPrefix[1]}`,
      unformatted: (newAmount * 100)
    })
  }
  
  function onDone(e: any) {
    e.preventDefault();
    e.stopPropagation();
    props.onAction({
      qty: 1,
      id: `${new Date().getUTCMilliseconds()}-custom`,
      name: name,
      price: customAmount.unformatted
    });
    props.done();
  }

  return (
    <React.Fragment>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Custom Amount</IonTitle>
          {props.done && <IonButton 
            expand="full" 
            slot="primary" 
            fill="clear" 
            onClick={props.done}
          >
            Close
          </IonButton>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={onDone}>
        <IonList className="ion-margin">
          <IonItem>
            <IonLabel position="floating">Name:</IonLabel>
            <IonInput
              required
              value={name}
              onIonChange={(e:any) => setName(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel
              position="floating"
            >Amount:</IonLabel>
            <IonInput 
              required
              value={customAmount.formatted} 
              onIonChange={(e: any) => handleCustomAmount(e.target.value)}
            />
          </IonItem>
        </IonList>
        <IonButton type='submit' className="ion-margin" expand="block">Done</IonButton>
        </form>
      </IonContent>
    </React.Fragment>
  )
}

export default CustomAmount
