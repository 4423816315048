import React, {useState, useEffect} from 'react'
import PageWrapper from '../../../components/PageWrapper'
import { IonLabel, IonProgressBar, IonList, IonItem, IonIcon } from '@ionic/react'

import { useLocation, useHistory } from 'react-router'
import querystring from 'querystring';
import trim from 'lodash/trim';

import firebase from 'firebase/app';
import 'firebase/functions';
import { useAuthStore } from '../../../store/AuthStore'
import { squareOutline, checkboxOutline } from 'ionicons/icons';

interface Props {
  user?: any;
}

declare global {
  interface Window {
    Location: any;
  }
}


const MerchantSetup: React.FC<Props> = (props) => {
  const {merchant} = useAuthStore((store) => store);
  let history = useHistory();
  let location = useLocation();
  let squareUrl = `https://${process.env.REACT_APP_SQUARE_DOMAIN}.com/oauth2/authorize?client_id=${process.env.REACT_APP_SQUARE_APPLICATION_ID}`;
  squareUrl += `&scope=MERCHANT_PROFILE_READ,PAYMENTS_WRITE,PAYMENTS_READ,PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS,ORDERS_READ,ORDERS_WRITE,CUSTOMERS_READ,CUSTOMERS_WRITE,ITEMS_READ,ITEMS_WRITE`
  const [loading, setLoading] = useState(false);
  const [squareDetails, setSquareDetails] = useState<any>({
    title: "1. Square Account",
    content: "Payments are processed securely through your existing square account. We also import items so you don't have to enter new ones!",
    error: false,
    complete: false,
    onClick: () => {
      window.location.href = squareUrl;
    }
  });
  const [phoneDetails, setPhoneDetails] = useState<any>({
    title: "2. Local Phone",
    content: "Choose a local phone number to text from so you can interact with your customers via text message 1 on 1 or 1 to many.",
    complete: false,
    error: false,
    onClick: () => {
      history.push('/selectPhone')
    }
  });

  useEffect(() => {
    async function paramsAuth(params: any) {
      try {
        const oauthSquare = firebase.functions().httpsCallable('oauthSquare');
        const result:any = await oauthSquare(params);
        if(result.error) {
          setSquareDetails((prevState:any) => ({...prevState, error: true}));
          return;
        }
        window.location.href = window.location.origin;
      } catch (error) {
        setLoading(false);
        setSquareDetails((prevState:any) => ({...prevState, error: true}));
      }
    }

    const params = querystring.parse(
      trim(location.search, '?')
    );

    if(params && params.code) {
      setLoading(true);
      paramsAuth(params)
    }
  }, [location, history]);

  useEffect(() => {
    async function init() {

      if(!merchant || !merchant.merchant_id) {
        return;
      }

      const merchantDoc:any = await firebase.firestore().collection('merchants').doc(merchant.merchant_id).get();

      if(merchantDoc.exists) {
        setSquareDetails((prevState:any) => ({
          ...prevState,
          complete: true,
        }));
      }

      if(merchantDoc.data().merchant_phone_id) {
        setPhoneDetails((prevState:any) => ({
          ...prevState,
          complete: true,
        }));
      }
    }

    init();

  }, [props.user, merchant])

  return (
    <PageWrapper title="Get Started" hideMenu>
      {loading && <IonProgressBar type="indeterminate" />}
      <IonList style={{maxWidth: 600}}>
        <IonItem button className="ion-margin-bottom" onClick={squareDetails.onClick}>
          <IonIcon icon={squareDetails.complete ? checkboxOutline : squareOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <h2>{squareDetails.title}</h2>
            <h3>{squareDetails.content}</h3>
          </IonLabel>
        </IonItem>
        <IonItem button disabled={!squareDetails.complete || phoneDetails.complete} className="ion-margin-bottom" onClick={phoneDetails.onClick}>
          <IonIcon icon={phoneDetails.complete ? checkboxOutline : squareOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <h2>{phoneDetails.title}</h2>
            <h3>{phoneDetails.content}</h3>
          </IonLabel>
        </IonItem>
      </IonList>
    </PageWrapper>
  )
}

export default MerchantSetup
