import React, { useState } from 'react'
import { IonList, IonItem, IonLabel, IonNote, IonListHeader, useIonViewWillLeave } from '@ionic/react'

import { Modal } from '../../../components/Modal';
import { DisplayData } from '../../../components/DisplayData';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useEffect } from 'react';
import { FormatData } from '../../../components/FormatData';

interface Props {
  tabId: string;
  screen: string;
}

// const transfers = [
//   {
//     amount: '1000',
//     total: '1000',
//     created_at: new Date(),
//     business_name: 'Side Track Coffee',
//     lineItems: [
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//     ]
//   },
//   {
//     amount: '900',
//     total: '1000',
//     created_at: new Date(),
//     business_name: 'Side Track Coffee',
//     lineItems: [
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//       {
//         price: '1000',
//         qty: 1,
//         name: 'Something'
//       },
//     ]
//   },
//   {
//     amount: '600',
//     total: '1000',
//     created_at: new Date(),
//     business_name: 'Side Track Coffee'
//   }
// ];

export const TransactionsList: React.FC<Props> = (props) => {
  const { screen, tabId } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [listener, setListener] = useState(undefined);
  const [data, setData] = useState([]);

  useEffect(() => {
    function handleChange(query: any) {
      const items:any = [];
      query.forEach((doc: any) => {
        items.push({...doc.data(), id: doc.id});
      })
      setData(items);
    }

    const sub = firebase.firestore().collection(screen).where('tab_id', '==', tabId).orderBy('created_at', 'desc').onSnapshot(handleChange);
    setListener(() => {return sub});
    return () => {
      if(sub) {
        sub();
      }
    }
  }, [screen, tabId]);

  useIonViewWillLeave(() => {
    if(listener) {
      listener();
    }
  }, [listener]);

  function itemSelected(id: any) {
    setSelectedItem(id);
    setShowModal(true);
  }

  function closeModal() {
    setSelectedItem(undefined);
    setShowModal(false);
  }

  return (
    <React.Fragment>
      {showModal && <Modal title={`${screen}`} isOpen={showModal} closeModal={closeModal}>
        <DisplayData data={selectedItem} type={screen} />
      </Modal>}
      <IonList lines={(data.length < 1) ? 'none' : 'full'} title={screen} style={{ width: '100%' }}>
        <IonListHeader>
          {screen}
        </IonListHeader>
        {(data.length < 1) && <IonItem>
          <IonLabel>
            No Items
          </IonLabel>  
        </IonItem>}
        {data.map((item, key) => (
          <IonItem button key={key} onClick={() => itemSelected(item)}>
            <IonLabel>
              <h2><FormatData value={(screen === 'orders') ? item.total : item.payment_amount} type={`dollar`} /></h2>
              <p><FormatData value={item.created_at} type={`date`} /></p>
            </IonLabel>
            <IonNote slot="end" color="dark">
              <FormatData value={item.status} type={`status`} />
            </IonNote>
          </IonItem>
        ))}
      </IonList>
    </React.Fragment>
  )
}
