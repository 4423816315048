import React, {useEffect} from 'react'
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonMenuButton } from '@ionic/react';


interface Props {
  title?: any;
  children: any;
  hideMenu?: boolean;
  isChild?: boolean;
  hideHeader?: boolean;
  endComponent?: any;
  className?: string;
  footer?: any;
}

const PageWrapper: React.FC<Props> = (props) => {

  useEffect(() => {
    if(props.title && window.analytics) {
      document.title = props.title;
      window.analytics.page(props.title);
    }
  }, [props.title])

  return (
    <IonPage {...props.className}>
      {!props.hideHeader && <IonHeader>
        <IonToolbar>
          {props.isChild && <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>}
          {(!props.hideMenu && !props.isChild) && <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>}
          <IonTitle>
            {props.title}
          </IonTitle>
          {props.endComponent && <div slot="end">
            {props.endComponent}  
          </div>}
        </IonToolbar>
      </IonHeader>}
      <IonContent>
        {props.children}
      </IonContent>
      {props.footer && props.footer}
    </IonPage>
  )
}

export default PageWrapper;
