import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/AuthStore';
import { IonPage, IonContent } from '@ionic/react';
import RegisterUserComponent from './RegisterUserComponent';




function ProtectedRoute(props: any) {
  let location = useLocation();
  const { user, userType } = useAuthStore((store) => store);

  if ((!user || !user.claims) && location.pathname !== 'completeSignup') {
    return (
      <Route
        render={({ location }) =>
          <Redirect
            to={{
              pathname: "/completeSignup",
              state: { from: location }
            }}
          />
        }
      />
    )
  }

  if (user && userType === 'regular' && (!user.phoneNumber || !user.displayName || !user.email)) {
    // console.log(userType);
    return (
      <IonPage>
        {/* <IonHeader>
          <IonToolbar>
            <IonTitle>Hello</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonContent>
          <RegisterUserComponent />
        </IonContent>
      </IonPage>
    )
  }


  return (
    <Route {...props} />
  );
}


export default ProtectedRoute;